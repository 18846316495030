import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BusinessMasterService {
  private baseUrl = environment.baseURL + "businessMaster/";

  private getHttpOptions() {
    var currentUser = localStorage.getItem("currentUser");
    var token = "";
    if (currentUser) {
      var userArray = JSON.parse(currentUser);
      token = userArray.data.json_token;
    }
    return {
      headers: new HttpHeaders({ token: token ? token : "" }),
    };
  }

  constructor(private http: HttpClient) {}

  addBusinessMaster(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "addBusinessMaster",
      businessMaster,
      this.getHttpOptions()
    );
  }

  getBusniessMasterByUserId(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "getBusniessMasterByUserId",
      businessMaster,
      this.getHttpOptions()
    );
  }

  getGalleryImagesByUserId(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "getGalleryImagesByUserId",
      businessMaster,
      this.getHttpOptions()
    );
  }

  getAllBusinessByUserId(user_id: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "getAllBusinessMasterByUserId",
      user_id,
      this.getHttpOptions()
    );
  }

  getBusinessById(b_id: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "getBusinessById",
      b_id,
      this.getHttpOptions()
    );
  }

  getGalleryImagesById(b_id: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "getGalleryImagesById",
      b_id,
      this.getHttpOptions()
    );
  }

  getInfoByBusinessId(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "getInfoByBusinessId",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateBio(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateBio",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateSocialMedia(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateSocialMedia",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateBusinessInfo(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateBusinessInfo",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateContactInfo(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateContactInfo",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateMakePublic(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateMakePublic",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateAppointmentSetting(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateAppointmentSetting",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateNewsletter(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateNewsletter",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateLatestUpdate(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "updateLatestUpdate",
      businessMaster,
      this.getHttpOptions()
    );
  }

  addChildBusinessMaster(businessMaster: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "addChildBusinessMaster",
      businessMaster,
      this.getHttpOptions()
    );
  }

  updateProfilePicture(image: any, b_id: any): Observable<any> {
    let businessImage = { images: [image], b_id: b_id };
    return this.http.post(
      this.baseUrl + "updateProfilePicture",
      businessImage,
      this.getHttpOptions()
    );
  }
}
