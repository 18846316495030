import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { DepartmentService } from "../services/department.service";

import { DoctorService } from "../services/doctor.service";
import { Doctor } from '../models/doctor';

class ImageSnippet {
	constructor(public src: string, public file: File) { }
}

@Component({
	selector: 'app-doctor-list',
	templateUrl: './doctor-list.component.html',
	styleUrls: [
		'./doctor-list.component.css',
	]
})
export class DoctorListComponent implements OnInit {

	addDoctorForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		department_id: new FormControl("", [Validators.required]),
		bio: new FormControl("", [Validators.required]),
  	});

	updateDoctorForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		department_id: new FormControl("", [Validators.required]),
		bio: new FormControl("", [Validators.required]),
		doctorId: new FormControl("", [Validators.required]),
  	});

	  deleteDoctorForm = new FormGroup({
		doctorId: new FormControl("", [Validators.required]),
		
  	});

  	get f() {
		return this.addDoctorForm.controls;
	}

	b_id: any;
	user_id = 0;
	role_type = 0;
	selectedFile: any = ImageSnippet;

	doctorList: any;
	departmentList: any;
	images : any = [];
	recentImage: any;

	errorMessage = "";
	successMessage = "";
	closeResult = "";

	constructor(
		private doctorService: DoctorService,
		private modalService: NgbModal,
		private departmentService: DepartmentService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.b_id = userArray.data.business_id;

			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
				return;
			}
			this.getDoctorByBusinessId(this.b_id);
		}
	}

	openDoctorAdd(content: any): void {
		this.departmentService.getAllDepartment()
		.subscribe(
			data => {
				this.departmentList = data.data;
			}
		);
	
		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openDoctorEdit(content: any, doctorId: any): void {

		this.departmentService.getAllDepartment()
		.subscribe(
			data => {
				this.departmentList = data.data;
			}
		);
		
		this.doctorService.getDoctorById({
			doctorId,
			} as Doctor).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDoctorForm.controls["name"].setValue(data.data.name);
					this.updateDoctorForm.controls["department_id"].setValue(data.data.department_id);
					this.updateDoctorForm.controls["bio"].setValue(data.data.bio);
					this.updateDoctorForm.controls["doctorId"].setValue(data.data.id);
					this.updateDoctorForm.controls["image"].setValue(data.data.image);
					this.recentImage = data.data.image;
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openDoctorDelete(content: any, doctorId: any): void {
		

		this.departmentService.getAllDepartment()
		.subscribe(
			data => {
				this.departmentList = data.data;
			}
		);		
		
		this.doctorService.getDoctorById({
			doctorId,
			} as Doctor).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDoctorForm.controls["name"].setValue(data.data.name);
					this.updateDoctorForm.controls["department_id"].setValue(data.data.department_id);
					this.updateDoctorForm.controls["bio"].setValue(data.data.bio);
					this.updateDoctorForm.controls["doctorId"].setValue(data.data.id);
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);

		
	}

	addDoctor($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let name = this.addDoctorForm.value.name;
		let image = this.addDoctorForm.value.image;
		let department_id = this.addDoctorForm.value.department_id;
		let bio = this.addDoctorForm.value.bio;
		let b_id = this.b_id;
		

		if (
			!name ||
			!department_id ||
			!image ||
			!bio
		) {
			this.errorMessage = "All fields are required.";
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		} 
		this.doctorService.createDoctor({
			b_id,
			name,
			image,
			bio,
			department_id
		} as Doctor)
		.subscribe(
			(data) => {
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateDoctor($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let name = this.updateDoctorForm.value.name;
		let image = this.updateDoctorForm.value.image;
		let department_id = this.updateDoctorForm.value.department_id;
		let bio = this.updateDoctorForm.value.bio;
		let doctorId = this.updateDoctorForm.value.doctorId;
		let b_id = this.b_id;		

		this.doctorService.updateDoctor({
			b_id,
			name,
			image,
			doctorId,
			bio,
			department_id,
		} as Doctor)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	deleteDoctor($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let doctorId = this.updateDoctorForm.value.doctorId;

		this.doctorService.deleteDoctor({
			doctorId,
		} as Doctor)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	getDoctorByBusinessId(b_id: any): void {
		this.doctorService.getDoctorByBusinessId({ b_id } as Doctor)
			.subscribe(
				data => {
					this.doctorList = data.data;
				}
			);
	}
	
	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;

		if (imageInput.files && imageInput.files[0]) {
			var filesAmount = imageInput.files.length;
			for (let i = 0; i < filesAmount; i++) {
				var reader = new FileReader();

				reader.onload = (event:any) => {
					this.images.push(event.target.result); 	
					this.updateDoctorForm.patchValue({
						image: this.images,
					});
					
				}
				reader.readAsDataURL(imageInput.files[i]);
			}
		}
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (imageInput.files && imageInput.files[0]) {
			var filesAmount = imageInput.files.length;
			for (let i = 0; i < filesAmount; i++) {
				var reader = new FileReader();

				reader.onload = (event:any) => {
					this.images.push(event.target.result); 

					this.addDoctorForm.patchValue({
						image: this.images,		
					})
				}
				reader.readAsDataURL(imageInput.files[i]);
			}
		}
	}

	getDoctorByUserId(user_id: any): void {
		this.doctorService.getDoctorByUserId({ user_id } as Doctor)
		.subscribe(
			data => {
				this.doctorList = data.data;
			}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}
