import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Newsletter } from '../..//models/newsletter';
import { NewsletterService } from '../../services/newsletter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
		'./footer.component.css'
	]
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();

	
	successMessage = '';
	errorMessage = '';
	formErrorMessages = {
		emailError: ''
	};


  constructor(
    private newsletterService: NewsletterService,
  ) {}

  ngOnInit() {
  }

  addNewsletter($event: any): void {
    //($event.submitter as HTMLButtonElement).disabled = true;
    var email = ((document.getElementById("news_email") as HTMLInputElement).value);
    
    if (!email) {
      this.errorMessage = 'All fields are required';
      this.successMessage = '';
      return;
    }
  
    if (!email.includes('@')) {
      this.errorMessage = 'Please enter a valid email containing @';
      this.successMessage = '';
      return;
    }
    this.newsletterService.addNewsletter({ 
      email
      } as Newsletter)
      .subscribe(
        data => {
          this.errorMessage = '';
          if (data.status == 200) {
            this.successMessage = data.message;
            setTimeout(() => {
              window.location.reload()
            }, 4000);
          } else {
            this.errorMessage = data.message;
            //($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        error => {
          this.errorMessage = error;
          //($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }
}
