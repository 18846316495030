import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: [
		'./faq.component.css'
	]
})
export class FaqComponent implements OnInit {

	constructor(private title: Title, private meta: Meta) {}

	ngOnInit()
	{ 
		this.title.setTitle('FAQs | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Find answers to common questions about DonorZilla. Get all the information you need in our FAQs section.'
		}); 
	}

}