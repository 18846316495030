<div class="breadcrumb_section overflow-hidden ptb-150">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-10 col-12 text-center">
                <h2>About Us</h2>
            </div>
        </div>
    </div>
</div>
<section class="km__Who__section ">
    <div class="container">
        <div class="row align-items-center g-0 g-xxl-5 g-xl-5 g-lg-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="km__who__content">
                    <h2 class="mb-10">Who We Are</h2>
                    <p class="mb-10">At DonorZilla, we're dedicated to creating a healthier, more connected world. Our platform makes it easier for individuals to find blood donors and helps healthcare and wellness businesses increase their visibility. Whether you’re a hospital, blood bank, or wellness center, DonorZilla brings the community closer by fostering trust and simplifying access to essential services.</p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="km__who__imgbx img">
                    <img src="https://resources.donorzilla.com/statics/images/abbt.jpg" alt="images not found" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="km__Who__section ">
    <div class="container ">
        <div class="row align-items-center g-0 g-xxl-5 g-xl-5 g-lg-5 flex-column-reverse flex-lg-row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="km__who__imgbx img">
                    <img src="https://resources.donorzilla.com/statics/images/abbt1.jpg" alt="images not found" class="img-fluid">
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="km__who__content">
                    <h2 class="mb-10">Our Mission</h2>
                    <p class="mb-10">At DonorZilla, we're dedicated to creating a healthier, more connected world. Our platform makes it easier for individuals to find blood donors and helps healthcare and wellness businesses increase their visibility. Whether you’re a hospital, blood bank, or wellness center, DonorZilla brings the community closer by fostering trust and simplifying access to essential services.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="km__Who__section ">
    <div class="container">
        <div class="row align-items-center g-0 g-xxl-5 g-xl-5 g-lg-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="km__who__content">
                    <h2 class="mb-10">Our Vision</h2>
                    <p class="mb-10">To become the leading healthcare and wellness platform, driving better access to health services while promoting a culture of giving and support within communities worldwide.</p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="km__who__imgbx img">
                    <img src="https://resources.donorzilla.com/statics/images/doctor.jpg" alt="images not found" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section><br>
<section id="key-servces" class="values">
    <div class="container">
        <h2 class="text-center">Our Values</h2><br>
        <div class="key-inner">
            <div class="row">
                <div class="col-md-3">
                    <div class="key-inner-txt">
                        <h3>Compassion</h3>
                        <p>We prioritize the well-being of individuals and communities by connecting them with crucial health resources.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="key-inner-txt">
                        <h3>Trust</h3>
                        <p>We build a secure and reliable platform that users can count on for transparency and authenticity.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="key-inner-txt">
                        <h3>Innovation</h3>
                        <p>We constantly evolve to meet the needs of our users with cutting-edge solutions for healthcare and wellness services.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="key-inner-txt">
                        <h3>Community</h3>
                        <p>We foster a sense of belonging and collaboration, promoting healthier, stronger communities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>