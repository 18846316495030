import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DeleteAccountComponent } from './delete-account.component';

const routes: Routes = [
  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    data: { 
      showHeader: true,
      showSidebar: true,
      showFooter: true,
      showBusinessHeader: false,
      showBusinessSidebar: false,
      showBusinessFooter: false,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeleteAccountRoutingModule { }
