import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { DonorProfileRoutingModule } from './donor-profile-routing.module';
import { DonorProfileComponent } from './donor-profile.component';

@NgModule({
  imports: [
    CommonModule,
    DonorProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [DonorProfileComponent],
  providers: [AuthGuard]
})
export class DonorProfileModule { }
