import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "../services/user.service";
import { User } from '../models/user';

@Component({
	selector: 'app-edit-blood-request',
	templateUrl: './edit-donor-profile.component.html',
	styleUrls: ['./edit-donor-profile.component.css']
})
export class EditDonorProfileComponent implements OnInit {

	updateDonorProfileForm = new FormGroup({
		first_name: new FormControl("", [Validators.required]),
		last_name: new FormControl("", [Validators.required]),
		date_of_birth: new FormControl("", [Validators.required]),
		blood_group: new FormControl("", [Validators.required]),
		about: new FormControl("", [Validators.required]),
		gender: new FormControl("", [Validators.required]),
		email: new FormControl("", [Validators.required, Validators.email]),
		location: new FormControl("", [Validators.required]),
	});
	
	userId:any;
	errorMessage = "";
	successMessage = "";
		
	constructor(
		private userService: UserService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.userId = userArray.data.id;
		}
		this.getUserById(this.userId)
	}
		getUserById(userId: any): void {
		this.userService.getUserById(userId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDonorProfileForm.controls["first_name"].setValue(data.data.first_name);
					this.updateDonorProfileForm.controls["last_name"].setValue(data.data.last_name);
					this.updateDonorProfileForm.controls["date_of_birth"].setValue(data.data.date_of_birth);
					this.updateDonorProfileForm.controls["about"].setValue(data.data.about);
					this.updateDonorProfileForm.controls["gender"].setValue(data.data.gender);
					this.updateDonorProfileForm.controls["email"].setValue(data.data.email);
					this.updateDonorProfileForm.controls["location"].setValue(data.data.location);
					this.updateDonorProfileForm.controls["blood_group"].setValue(data.data.blood_group);
				}
			}
		);
	}	

	updateprofileDetails($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let first_name = this.updateDonorProfileForm.value.first_name;
		let last_name = this.updateDonorProfileForm.value.last_name;
		let date_of_birth = this.updateDonorProfileForm.value.date_of_birth;
		let about = this.updateDonorProfileForm.value.about;
		let gender = this.updateDonorProfileForm.value.gender;
		let email = this.updateDonorProfileForm.value.email;
		let location = this.updateDonorProfileForm.value.location;
		let blood_group = this.updateDonorProfileForm.value.blood_group;
		let userId = this.userId;

		this.userService.updateUser({
			first_name,
			last_name,
			date_of_birth,
			about,
			gender,
			email,
			location,
			blood_group,
			userId
		} as User)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
}