import { Component, OnInit } from '@angular/core';
import { BloodRequest } from "../models/bloodRequest";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BloodTypeService } from "../services/bloodType.service";
import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: 'app-create-blood-request',
	templateUrl: './create-blood-request.component.html',
	styleUrls: [
		'./create-blood-request.component.css'
	]
})
export class CreateBloodRequestComponent implements OnInit {

	addBloodRequestForm = new FormGroup({
		blood_type: new FormControl("", [Validators.required]),
		patient_name: new FormControl("", [Validators.required]),
		blood_group: new FormControl("", [Validators.required]),
		phone_number: new FormControl("", [Validators.required]),
		required_date: new FormControl("", [Validators.required]),
		units: new FormControl("", [Validators.required]),
		location: new FormControl("", [Validators.required]),
		notes: new FormControl("", [Validators.required]),
		is_critical: new FormControl("", [Validators.required]),
		accept: new FormControl("", [Validators.required]),
		});
	
		get f() {
			return this.addBloodRequestForm.controls;
		}

	user_id:any;
	bloodTypeList: any;
	bloodRequestList: any;

	errorMessage = "";
	successMessage = "";
		
	constructor(
		private bloodTypeService: BloodTypeService,
		private bloodRequestService: BloodRequestService
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
		}
		this.getAllBloodType();
	}
     
     addBloodRequest($event: any): void {
	 
			($event.submitter as HTMLButtonElement).disabled = true;
			let blood_type = this.addBloodRequestForm.value.blood_type;
			let patient_name = this.addBloodRequestForm.value.patient_name;
			let blood_group = this.addBloodRequestForm.value.blood_group;
			let phone_number = this.addBloodRequestForm.value.phone_number;
			let required_date = this.addBloodRequestForm.value.required_date;
			let units = this.addBloodRequestForm.value.units;
			let location = this.addBloodRequestForm.value.location;
			let is_critical = this.addBloodRequestForm.value.is_critical;
			let notes = this.addBloodRequestForm.value.notes;
			let accept = this.addBloodRequestForm.value.accept;
			let userId = this.user_id;
			if (
				!blood_type ||
				!patient_name ||
				!blood_group ||
				!phone_number ||
				!required_date ||
				!units ||
				!location ||
				!is_critical ||
				!accept
				
			) 
			{
				this.errorMessage = "All fields are required.";
				($event.submitter as HTMLButtonElement).disabled = false;
				return;
			}
			this.bloodRequestService.createBloodRequest({
				blood_type,
				patient_name,
				blood_group,
				phone_number,
				required_date,
				units,
				location,
				is_critical,
				notes,
				userId
			} as BloodRequest)
		    .subscribe(
				(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
				},
				(error) => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}
	 
		
	getAllBloodType(): void {
		this.bloodTypeService.getAllBloodType()
		.subscribe(
			data => {
				this.bloodTypeList = data.data;
			}
		);
	}

}
