<footer>
    <div class="footer_top pt-115">
        <div class="container ">
            <div class="row footer_middle">
                <div class="col-xl-5 col-lg-5 col-12 ">
                    <div class="footer_subscrive">
                        <img src="https://resources.donorzilla.com/statics/images/logo-white.png" alt="DonorZilla">
                        <p>Connecting healthcare and wellness for a healthier community.</p>
                    </div>
                    <div class="header_top_social">    
                        <ul class="d-flex">
                            <li><a href="https://www.facebook.com/donorzilla/" target="_blank" title="Facebook"><i class="fa fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/DonorZilla" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
                            <li><a href=" https://www.instagram.com/donorzilla/" target="_blank" title="Instagram"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="https://www.pinterest.com/donorzilla/" target="_blank" title="Pinterest"><i class="fa fa-pinterest-p"></i></a></li>
                            <li><a href="https://www.youtube.com/@donorzilla" target="_blank" title="Youtube"><i class="fa fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/donorzilla/" title="Linkedin" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-12">
                    <div class="quick-links">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="donor">Blood Donors</a></li>
                            <li><a href="business">Business Listings</a></li>
                            <li><a href="about-us">About Us</a></li>
                            <li><a href="faq">FAQ</a></li>
                            <li><a href="contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="quick-links">
                        <h3>Get In Touch</h3>
                        <ul>      
                            <li><a href="mailto:contact@donorzilla.com"><i class="fa fa-envelope"></i> contact@donorzilla.com</a></li>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i>  H-143, Suite - 101,  H Block, Sector 63, Noida 201301</li>
                        </ul>
                        <img src="https://resources.donorzilla.com/statics/images/android.png" alt="DonorZilla App">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                    <div class="col-xl-4 col-lg-4 col-12 ">
                    <div class="footer_subscrive">
                        <p>Copyright &copy; 2024. All rights reserved</p>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-12">
                    <div class="footer_social">
                        <ul class="page_link d-flex pagelnkk">
                            <li><a href="privacy-policy">Privacy Policy</a></li>
                            <li>/</li>
                            <li><a href="terms-condition">Terms & Condition</a></li>
                            <li>/</li>
                            <li><a href="delete-account">Delete Account</a></li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</footer>
<div class="offcanvas offcanvas-start" id="offcanvas-mobile">
    <div class="offcanvas-body">
        <div class="mobile-menu">
            <a href="" class="logo py-3"><img src="https://resources.donorzilla.com/statics/images/logo-website.png" alt="logo" class="img-fluid"></a>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            <ul class="accordion accordion-flush mobile_dropdown" id="accordionFlushExample">
                <li class="accordion-item">
                    <h2><a href="">Home</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="donor">Blood Donors</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="business">Business Listings</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="about-us">About Us</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="contact-us">Contact Us</a></h2>
                </li>
            </ul>
        </div>
    </div>
</div>