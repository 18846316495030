<main>
  <section>
    <div class="rad-body-wrapper">
      <div class="container-fluid">
        <div class="white-bg">
          <div class="row" style="display: flex; justify-content: space-between;">
            <h3>Blood Request Details</h3>
						<button type="submit" style="width: 170px; height: 50px;" class="btn bg-gradient-primary wd-25 submit-btn-gradient" (click)="openModal( closeRequestForm,$event)">Close Request</button>
		   </div>
        </div>
        <div class="white-bg">
          <div class="card-body p-3">
            <div class="edit-detailss">
              <a href="/edit-blood-request/{{ bloodRequestDetails.id }}">
                <i class="fa fa-edit"></i
              ></a>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Patient Name</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.patient_name }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Blood Group</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.blood_group }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Blood Type</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.blood_type }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Required Date</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.required_date }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Document</p>
                  </div>
                  <div class="col-7">
                    <img
                      alt="Pic"
                      [src]="bloodRequestDetails?.document"
                      id="imgPlacement"
                      [hidden]="!bloodRequestDetails?.document"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Critical</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{
                        bloodRequestDetails?.is_critical == "1" ? "Yes" : "No"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Location</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.location }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Notes</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.notes }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Phone Number</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.phone_number }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Status</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{
                        bloodRequestDetails?.status == "1"
                          ? "Active"
                          : "Inactive"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Blood Needed</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.units }} Units
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <p class="text-sm my-auto text-500">Latitude - Longitude</p>
                  </div>
                  <div class="col-7">
                    <p class="text-sm my-auto">
                      {{ bloodRequestDetails?.latitude }} -
                      {{ bloodRequestDetails?.longitude }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="white-bg">
          <h3>Timeline</h3>
          <div class="timeline mt-2 ms-5">
            <div
              class="container right"
              *ngFor="
                let requestTimeline of requestDetailsTimeline;
                let i = index
              "
            >
              <span class="active-stats">{{
                requestTimeline?.status == "1" ? "🔴" : ""
              }}</span>
              <div class="content" style="display: flex; justify-content: space-between; align-items: center;">
                <p class="mb-0">{{ requestTimeline?.message }}</p>
                <h6 class="mb-0">{{ requestTimeline?.created }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="white-bg">
          <h3>Document</h3>
          <div class="row flex flex-col justify-between">
            <div class="business-gallery-1 spclick col-6 flex">
              <input
                class="hidden-file"
                id="file-input"
                type="file"
                accept="image/*"
                (change)="uploadBloodDocument($event)"
              />
            </div>

            <div class="col-6">
              <h5>Your current image</h5>
              <div class="col-7 flex flex-row">
                <div>
                  <img
                    alt="Pic"
                    [src]="bloodRequestDetails?.document"
                    style="width: 50%; height: 60%"
                    id="imgPlacement"
                    [hidden]="!bloodRequestDetails?.document"
                  />
                </div>
                <div>
                  <button
                    class="document-delete-btn"
                    type="button"
                    (click)="deleteBloodDocument($event)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="white-bg">
          <h3>Donors List</h3>
          <div class="row mt-3" *ngIf="donorChat?.length != 0">
            <div
              class="col-md-3 col-sm-4 col-4"
              *ngFor="let donorChat of donorChat; let i = index"
            >
              <div class="card-body card text-center">
                <div class="text-center">
                  <div class="immgcht">
                    <img
                      class="imgchat"
                      alt="Pic"
                      src="{{ donorChat?.profile_picture }}"
                      *ngIf="donorChat?.profile_picture"
                    />
                    <img
                      class="imgchat mb-4px"
                      alt="Pic"
                      src="https://resources.donorzilla.com/statics/images/logo-short.png"
                      *ngIf="!donorChat?.profile_picture"
                    />
                  </div>
                  <p class="mb-0 font-weight-bolder mt-3">
                    {{ donorChat?.first_name }} {{ donorChat?.last_name }}
                  </p>
                  <h6 class="mb-1 font-weight-bolder mt-0">
                    {{ donorChat?.location }}
                  </h6>
                  <span class="chtbtn-1"><a href="#" *ngIf="donorChat.is_accepted == 1 && donorChat.request_owner_id == user_id">Chat</a></span>
                  <div class="flex justify-between">
                    <div
					 *ngIf="donorChat.is_accepted == 0 && donorChat.request_owner_id == user_id"
                      style="
                        display: flex;
                        flex-direction: row;
                        align-content: stretch;
                        justify-content: space-evenly;
                        align-items: center;
                      "
                    >
                      <button
                        class="document-delete-btn"
                        type="button"
                        (click)="
                          acceptDonor($event, donorChat.donor_request_id)
                        "
                      >
                        Accept
                      </button>
                      <button
                        class="document-delete-btn"
                        type="button"
                        (click)="rejectRequest($event)"
                      >
                        Reject
                      </button>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="donorChat?.length == 0">
            <p>No applied by donor!</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

 <ng-template #closeRequestForm let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Delete Blood Request</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form-inner" style="max-width: 100%;">
						<div>
							<h3>Do you want to delete this request ?</h3>
						</div>
						
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient" (click)="closeRequest($event, ratingAndReview)">Confirm</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #ratingAndReview let-modal>
  <div class="modal-header">
    <h2 class="mx-auto fw-bold">Rating and Review</h2>
  </div>

  <div class="modal-body px-4">
    
    <div class="border p-4 rounded mb-3 shadow-sm bg-light">
      <p class="mb-2 fw-semibold fs-5 text-center">Rate Your Experience:</p>
      <div class="d-flex justify-content-center">
        <div class="rating">
          <i class="bi bi-star-fill fs-2 mx-1 cursor-pointer"
            (click)="rate(1)" [ngClass]="{'text-warning': rating >= 1, 'text-secondary': rating < 1}"></i>
          <i class="bi bi-star-fill fs-2 mx-1 cursor-pointer"
            (click)="rate(2)" [ngClass]="{'text-warning': rating >= 2, 'text-secondary': rating < 2}"></i>
          <i class="bi bi-star-fill fs-2 mx-1 cursor-pointer"
            (click)="rate(3)" [ngClass]="{'text-warning': rating >= 3, 'text-secondary': rating < 3}"></i>
          <i class="bi bi-star-fill fs-2 mx-1 cursor-pointer"
            (click)="rate(4)" [ngClass]="{'text-warning': rating >= 4, 'text-secondary': rating < 4}"></i>
          <i class="bi bi-star-fill fs-2 mx-1 cursor-pointer"
            (click)="rate(5)" [ngClass]="{'text-warning': rating >= 5, 'text-secondary': rating < 5}"></i>
        </div>
      </div>
    </div>

    <div class="border p-4 rounded mb-3 shadow-sm bg-light">
      <p class="mb-2 fw-semibold fs-5 text-center">Write a Review:</p>
      <textarea class="form-control" placeholder="Share your thoughts..." [(ngModel)]="review"></textarea>
    </div>

    <div class="border p-4 rounded mb-3 shadow-sm bg-light">
      <p class="mb-2 fw-semibold fs-5 text-center">What Impresses You?</p>
      <div class="row g-2">
        <div class="col-6" *ngFor="let comment of commentOptions">
          <button 
                class="btn w-100 py-2 text-truncate rounded-pill comment-btn"
                [ngClass]="{'active-comment': selectedComments.includes(comment)}"
                (click)="toggleComment(comment)"> {{ comment }}
          </button>

        </div>
      </div>
    </div>
    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>
    <div *ngIf="errorMessage" class="alert alert-danger text-center">
      {{ errorMessage }}
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button class="btn btn-danger custom-btn px-4 py-2 fw-bold me-3" (click)="submitReview($event)">Confirm</button>
      <button class="btn btn-outline-danger custom-btn px-4 py-2 fw-bold" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div> 

  </div>
</ng-template>



