import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileNewComponent } from './profile-new.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'profile_new/:id',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: ProfileNewComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,
      showBusinessHeader: false,
      showBusinessSidebar: false,
      showBusinessFooter: false,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileNewRoutingModule { }
