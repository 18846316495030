import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class DiseaseService {
    private baseUrl = environment.baseURL + "disease/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ 'token': token ? token : '' })
        };
    }

    constructor(private http: HttpClient) {}

    getDisease(params: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "getDisease", params, this.getHttpOptions());
    }
    
    getDiseaseById(diseaseId: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "getDiseaseById", { diseaseId }, this.getHttpOptions());
    }

    addDisease(disease: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "createDisease", disease, this.getHttpOptions());
    }

    updateDisease(disease: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "updateDisease", disease, this.getHttpOptions());
    }

    deleteDisease(disease: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "deleteDisease", disease, this.getHttpOptions());
    }

    getAllDisease(): Observable<any> {
        return this.http.get<any>(this.baseUrl + "getAllDisease", this.getHttpOptions());
    }
}
