<footer class="footer py-4  ">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-12 mb-lg-0 mb-4">
                <div class="copyright text-center text-sm ">
                    Donorzilla &copy;2024, All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</footer>