import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentDetailsComponent } from './appointment-details.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'appointment-details',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: AppointmentDetailsComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,
      showBusinessHeader: true,
      showBusinessSidebar: true,
      showBusinessFooter: true,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentDetailsRoutingModule { }
