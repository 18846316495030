import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: [
		'./privacy-policy.component.css'
	]
})
export class PrivacyPolicyComponent implements OnInit {

	constructor(private title: Title, private meta: Meta) {}

	ngOnInit()
	{ 
		this.title.setTitle('Privacy Policy | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Learn how DonorZilla protects your personal information and ensures data privacy while using our platform to find blood donors, hospitals, doctors, clinics, and path labs. Your privacy is our priority.'
		}); 
	}

}