import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { CommunityListRoutingModule } from './community-list-routing.module';
import { CommunityListComponent } from './community-list.component';

@NgModule({
  imports: [
    CommonModule,
    CommunityListRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [CommunityListComponent],
  providers: [AuthGuard]
})
export class CommunityListModule { }
