import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ArticleListRoutingModule } from './article-list-routing.module';
import { ArticleListComponent } from './article-list.component';

@NgModule({
  imports: [
    CommonModule,
    ArticleListRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ArticleListComponent]
})
export class ArticleListModule { }
