import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-thankyou',
	templateUrl: './thankyou.component.html',
	styleUrls: [
		'./thankyou.component.css'
	]
})
export class ThankyouComponent implements OnInit {
	
	userCounter: any;
	bloodCounter: any;
	appointmentCounter: any;

	userList: any;
	bloodList: any;

	constructor(
		private router: Router
	) {}

	ngOnInit() {
		setTimeout(() => {
			this.router.navigate(['dashboard']);
		}, 5000);
	}
}