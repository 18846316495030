import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { BusinessMaster } from '../models/businessMaster';
import { Notification } from '../models/notification';
import { Slot } from '../models/slot';

import { SlotService } from "../services/slot.service";
import { BusinessMasterService } from "../services/businessMaster.service";
import { NotificationService } from "../services/notification.service";

@Component({
	selector: 'app-appointment-settings',
	templateUrl: './appointment-settings.component.html',
	styleUrls: [
		'./appointment-settings.component.css'
	]
})
export class AppointmentSettingsComponent implements OnInit {

	aptSettingForm = new FormGroup({
		future_days: new FormControl(""),
		slot_duration: new FormControl("")
  	});

  	get f() {
		return this.aptSettingForm.controls;
	}

	businessApt: any;

	is_slot_time = 0;
	b_id: any;
	map_id: any;
	user_id = 0;
	role_type = 0;
	notificationCounter = 0;
	type: any;
	
	sunTd = false;
	sunUTd = true;

	monTd = false;
	monUTd = true;
	
	tueTd = false;
	tueUTd = true;
	
	wedTd = false;
	wedUTd = true;
	
	thuTd = false;
	thuUTd = true;
	
	friTd = false;
	friUTd = true;
	
	satTd = false;
	satUTd = true;

	detailedList: any;
	businessList: any;
	notificationList: any;
	
	errorMessage = "";
	successMessage = "";
	
	constructor(
		private businessMasterService: BusinessMasterService,
		private notificationService: NotificationService,
		private slotService: SlotService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
				return;
			}
			this.b_id = this.route.snapshot.queryParams.b_id;
			if(this.b_id){
				this.getRoleDetailsById(this.b_id);
			} else{
				this.getRoleDetails(this.user_id);
			}
		}
	}

	dayEvent($event: any, day: any): void{
		let day_check = $event.currentTarget.checked;
		switch (day) {
			case 'SUN':
				if(day_check){
					this.sunTd = true;
					this.sunUTd = false;
				} else{
					this.sunTd = false;
					this.sunUTd = true;	
				}
				break;
			case "MON":
				if(day_check){
					this.monTd = true;
					this.monUTd = false;
				} else{
					this.monTd = false;
					this.monUTd = true;	
				}
				break;
			case "TUE":
				if(day_check){
					this.tueTd = true;
					this.tueUTd = false;
				} else{
					this.tueTd = false;
					this.tueUTd = true;	
				}
				break;
			case "WED":
				if(day_check){
					this.wedTd = true;
					this.wedUTd = false;
				} else{
					this.wedTd = false;
					this.wedUTd = true;	
				}
				break;
			case "THU":
				if(day_check){
					this.thuTd = true;
					this.thuUTd = false;
				} else{
					this.thuTd = false;
					this.thuUTd = true;	
				}
				break;
			case "FRI":
				if(day_check){
					this.friTd = true;
					this.friUTd = false;
				} else{
					this.friTd = false;
					this.friUTd = true;	
				}
				break;
			case "SAT":
				if(day_check){
					this.satTd = true;
					this.satUTd = false;
				} else{
					this.satTd = false;
					this.satUTd = true;	
				}
				break;
			default:
				console.log("No such day exists!");
				break;
		}

		this.businessApt = localStorage.getItem('business_apt');
		let arr = {} = JSON.parse(this.businessApt);
		
		if(arr[day]){
			arr[day] = ['9:00-17:00'];
		}
		localStorage.setItem('business_apt', JSON.stringify(arr));		
	}

	timeToChange(event: any, day: any) {
		let fromVal = (document.getElementById(day+'From') as HTMLInputElement).value;

		this.businessApt = localStorage.getItem('business_apt');
		let arr = {} = JSON.parse(this.businessApt);
		
		if(arr[day]){
			arr[day] = [event.target.value + '-' + fromVal];
		}
		localStorage.setItem('business_apt', JSON.stringify(arr));
   	};

	timeFromChange(event: any, day: any) {
		let toVal = (document.getElementById(day+'To') as HTMLInputElement).value;
		
		this.businessApt = localStorage.getItem('business_apt');
		let arr = {} = JSON.parse(this.businessApt);
		
		if(arr[day]){
			arr[day] = [toVal + '-' + event.target.value];
		}
		localStorage.setItem('business_apt', JSON.stringify(arr));
   	};

	checkSlot(business_id: any): void {
		
		let basic_structure = '{"SUN": [],"MON": [],"TUE": [],"WED": [],"THU": [],"FRI": [],"SAT": []}';
		
		localStorage.setItem('business_apt', basic_structure);

		if(business_id.target.value == ''){
			this.is_slot_time = 0;
		} else{
			this.is_slot_time = 1;
		}
	}

	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.map_id = data.data.map_id;
				}
			);
	}

	getRoleDetails(user_id: any): void {
		this.businessMasterService.getBusniessMasterByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.detailedList = data.data;
				this.b_id = data.data.b_id;
				this.map_id = data.data.map_id;
			}
		);
	}
	
	createAptSetting($event: any) {
		let date_time_json = localStorage.getItem('business_apt');
		let future_days = this.aptSettingForm.value.future_days.trim();
		let slot_duration = this.aptSettingForm.value.slot_duration.trim();
		let b_id = this.b_id;

		this.slotService
		.updateSlot({
			date_time_json,
			future_days,
			slot_duration,
			b_id
		} as Slot)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
   	};

	updateAppointmentSetting($event: any): void{
		let is_appointment = $event.currentTarget.checked;
		let b_id = this.b_id;

		this.businessMasterService
		.updateAppointmentSetting({
			is_appointment,
			b_id
		} as BusinessMaster)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
}
