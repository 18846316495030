<section class="static-hero blood-hero" id="div">
    <div class="hero-container">
        <div class="hero-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-duration="2500">
                        <div class="wpo-static-hero-inner">
                            <div class="slide-sub-title">
                                <h2>Expand Your Reach, <br> Grow Your  <strong>Practice</strong></h2>
                            </div>
                            <div class="slide-text">
                                <p>Join the DonorZilla network and get discovered by thousands of patients looking for doctors and clinics like yours.</p>
                            </div>
                            <div class="slide-btn">
                              <a class="theme-btn " href="register" >Register Now</a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-12" >
                        <div class="bnr-right-immg">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/d1.png" class="bdb111" data-aos="fade-left" data-aos-duration="2500">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/d3.png" class="bdb121" data-aos="fade-up" data-aos-duration="2500">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/d2.png" class="bdb131" data-aos="fade-down" data-aos-duration="2500">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="how-began">
    <div class="container">
        <div class="how-text">
            <h2 class="text-center">Why Choose DonorZilla?</h2>
            <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>
        <div class="row">
            <div class="col-md-6" data-aos="fade-right" data-aos-duration="2500">
                <div class="how-img">
                    <img src="https://resources.donorzilla.com/statics/images/doctor-icon/why.png">
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" data-aos-duration="2500"><br><br>
                <div class="row">
                    <div class="col-md-6">
                        <div class="side-text">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/w1.png">
                            <h3>Boost Online Visibility</h3> 
                            <p>Make your clinic searchable for thousands of patients in your locality.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="side-text">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/w2.png">
                            <h3>Get More Patients</h3> 
                            <p>Connect with patients through appointment bookings and inquiries.</p>
                        </div>
                    </div>
                </div><br><br>
                <div class="row">
                    <div class="col-md-6">
                        <div class="side-text">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/w3.png">
                            <h3>Manage Appointments Seamlessly</h3> 
                            <p>Easy tools to manage your patient<br> flow.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="side-text">
                            <img src="https://resources.donorzilla.com/statics/images/doctor-icon/w4.png">
                            <h3>Trusted Platform</h3> 
                            <p> Join a network trusted by healthcare professionals and patients.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><br>
<section id="features-help">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="featur-text">
                    <h2>Features That Help You Thrive</h2>
                    <p>Discover powerful tools and features designed to enhance your clinic's visibility and streamline your practice. With DonorZilla, managing appointments and connecting with patients has never been easier.</p>
                    <div class="abt-text whyus">          
                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/1.png"> Customizable Profile </h3>
                        <p>Showcase your expertise, specialties, and services.</p>

                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/2.png"> Online Appointment Booking  </h3>
                        <p>Simplify patient bookings with our easy-to-use system.</p>

                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/3.png">  Reviews and Ratings </h3>
                        <p> Build credibility with patient feedback</p>

                        <h3> <img src="https://resources.donorzilla.com/statics/images/doctor-icon/4.png"> 24/7 Visibility  </h3>
                        <p> Let patients find you anytime, anywhere.</p>

                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/5.png">  Analytics Dashboard </h3>
                        <p>  Track your growth and patient engagement.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <img src="https://resources.donorzilla.com/statics/images/doctor-icon/f1.png" class="img-1" alt="">
            </div>
        </div>
    </div>
</section><br>
<section id="how-it-works">
    <div class="container">
        <h2 class="text-center"> Steps to Get Started</h2>
        <p class="text-center">Simplifying healthcare for businesses and individuals alike</p>
        <div class="how-worksss-inner">
            <div class="how-it-wrk-space doctor-sproc">
                <img src="https://resources.donorzilla.com/statics/images/bdpi1.png">
                <h3>Sign Up</h3>
                <p>Fill out the quick registration form.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space doctor-sproc">
                <img src="https://resources.donorzilla.com/statics/images/bdpi2.png">
                <h3>Create Your Profile</h3>
                <p>Add details about your clinic, services, and timings.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space doctor-sproc">
                <img src="https://resources.donorzilla.com/statics/images/bdpi3.png">
                <h3> Go Live</h3>
                <p>Get listed on DonorZilla and start attracting patients.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space doctor-sproc">
                <img src="https://resources.donorzilla.com/statics/images/bdpi4.png">
                <h3>Manage Appointments</h3>
                <p>Use our platform to manage bookings and inquiries.</p>
            </div>
        </div>
    </div>
</section>
<section id="features-help">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <img src="https://resources.donorzilla.com/statics/images/doctor-icon/e1.png" class="img-1" alt="">
            </div>
            <div class="col-lg-6">
                <div class="featur-text">
                    <h2>Exclusive Benefits for Your Practice</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation.</p>
                    <div class="abt-text whyus">          
                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/6.png"> Showcase Your Expertise </h3>
                        <p>Stand out in your specialization.</p>

                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/7.png"> Increase Patient Trust  </h3>
                        <p>Verified profiles for higher credibility.</p>


                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/8.png"> Easy Communication  </h3>
                        <p> Get appointment inquiries directly to your dashboard.</p>

                        <h3><img src="https://resources.donorzilla.com/statics/images/doctor-icon/9.png">  Comprehensive Support </h3>
                        <p>  Dedicated team to assist you 24/7.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!----------------------------------------become---------------------->



<!---------------------------------------------faq---------------------------------------------->

<div class="accordion faqq">
<h2 class="text-center">Have Questions? We’ve Got Answers</h2>
<br>
<div class="accordion-item">
<input type="checkbox" id="accordion1">
<label for="accordion1" class="accordion-item-title"><span class="icon"></span>How does DonorZilla help me get more patients??</label>
<div class="accordion-item-desc">DonorZilla is a mobile app bridging the gap between blood donors and patients requiring transfusions. It facilitates seamless connections, allowing donors to respond to urgent blood requests and schedule donations conveniently.</div>
</div>

<div class="accordion-item">
<input type="checkbox" id="accordion2">
<label for="accordion2" class="accordion-item-title"><span class="icon"></span>What information is required to register?</label>
<div class="accordion-item-desc">DonorZilla utilizes a user-friendly interface where individuals in need of blood can create requests, and registered donors can view these requests based on location and blood type compatibility. Donors can then choose to respond to requests and schedule donation appointments.</div>
</div>

<div class="accordion-item">
<input type="checkbox" id="accordion3">
<label for="accordion3" class="accordion-item-title"><span class="icon"></span>Can I update my clinic details later?</label>
<div class="accordion-item-desc">Yes, DonorZilla is accessible to both blood donors and patients in need of blood transfusions. Donors can sign up to offer blood donations, while patients can create requests for blood donations when needed.</div>
</div>

<div class="accordion-item">
<input type="checkbox" id="accordion4">
<label for="accordion4" class="accordion-item-title"><span class="icon"></span>Is there a fee for registration?</label>
<div class="accordion-item-desc">Yes, DonorZilla is free to download and use for both donors and recipients. The recipient and donor do not need to spend any money to use the application</div>
</div>

<div class="accordion-item">
<input type="checkbox" id="accordion5">
<label for="accordion5" class="accordion-item-title"><span class="icon"></span>How can I register as a blood donor on DonorZilla?</label>
<div class="accordion-item-desc">To register as a blood donor, simply download the DonorZilla app from the App Store or Google Play Store, create an account, and fill in your details, including blood type, location, and contact information. once all the details are filled you can accept requests or create your own.</div>
</div>

<div class="accordion-item">
<input type="checkbox" id="accordion6">
<label for="accordion6" class="accordion-item-title"><span class="icon"></span>What information do I need to provide as a patient requesting blood on DonorZilla?</label>
<div class="accordion-item-desc">Patients requesting blood on DonorZilla must provide details such as blood type, quantity required, location for donation, and any other specific requirements. Also, the patient can upload medical documents for the donors to see.</div>
</div>

<div class="accordion-item">
<input type="checkbox" id="accordion7">
<label for="accordion7" class="accordion-item-title"><span class="icon"></span>How does DonorZilla ensure the safety and reliability of blood donations?</label>
<div class="accordion-item-desc">DonorZilla follows strict guidelines and procedures to verify donors' eligibility, including health screenings and blood tests. Additionally, users can rate and review donors, promoting accountability within the community.</div>
</div>
</div>

<!--------------------------------------------------------------->
<!-- start wpo-service-section -->
<section class="wpo-appoinment-section" data-aos="fade-up" data-aos-duration="2000">
    <div class="container">
        <div class="appoinment-wrap">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-12 col-12">
                    <div class="appoinment-text">
                        <h2>Don’t Wait, Get Started Today!</h2>
                        <p>Join thousands of other healthcare professionals on DonorZilla. Sign up now and take the first step toward growing your practice.</p>
                        <div class="grow-btn">
                            <a class="theme-btn" href="register">Register Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-12">
                    <div class="appoinment-right">
                        <img src="https://resources.donorzilla.com/statics/images/doctor-icon/g1.png" />
                    </div>
                </div>
            </div>
            <div class="shape-1">
                <img src="https://resources.donorzilla.com/statics/images/appoinment-shape.png" alt="" />
            </div>
        </div>
    </div>
</section>
<!-- end wpo-service-section -->



<!-- footer section start -->
