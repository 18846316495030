import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Department } from "../models/department";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class DepartmentService {
	
	private getAllDepartmentUrl = environment.baseURL + "department/getAllDepartment";

	private getDepartmentUrl = environment.baseURL + "department/getDepartment";

	private getDepartmentByRoleIdUrl = environment.baseURL + "department/getDepartmentByRoleId";

	private updateDepartmentMapUrl = environment.baseURL + "department/updateDepartmentMap";

	private getDepartmentByBusinessIdUrl = environment.baseURL + "department/getDepartmentByBusinessId";

	private getDepartmentByUserIdUrl = environment.baseURL + "department/getDepartmentByUserId";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	
	getAllDepartment(): Observable<Department> {
		return this.http
		.get<Department>(this.getAllDepartmentUrl, this.httpOptions)
		.pipe(
			map((departmentList) => {
			return departmentList;
			})
		);
	}
	
	updateDepartmentMap(params: any): Observable<Department> {
		return this.http
		.post<Department>(this.updateDepartmentMapUrl, params, this.httpOptions)
		.pipe(
			map((departmentList) => {
			return departmentList;
			})
		);
	}

	getDepartmentByUserId(user_id: any): Observable<Department> {
		return this.http
		.post<Department>(this.getDepartmentByUserIdUrl, user_id, this.httpOptions)
		.pipe(
			map((departmentList) => {
			return departmentList;
			})
		);
	}

	getDepartmentByBusinessId(user_id: any): Observable<Department> {
		return this.http
		.post<Department>(this.getDepartmentByBusinessIdUrl, user_id, this.httpOptions)
		.pipe(
			map((departmentList) => {
			return departmentList;
			})
		);
	}

	// getAmenity(params: any): Observable<Department> {
	// 	return this.http
	// 	.post<Department>(this.getAmenityUrl, params, this.httpOptions)
	// 	.pipe(
	// 		map((departmentList) => {
	// 		return departmentList;
	// 		})
	// 	);
	// }
	
	// getAmenityByRoleId(role_id: any): Observable<Department> {
	// 	return this.http
	// 	.post<Department>(this.getAmenityByRoleIdUrl, role_id, this.httpOptions)
	// 	.pipe(
	// 		map((departmentList) => {
	// 		return departmentList;
	// 		})
	// 	);
	// }
	
	
	
	// getAmenityByBId(role_id: any): Observable<Department> {
	// 	return this.http
	// 	.post<Department>(this.getAmenityByBIdUrl, role_id, this.httpOptions)
	// 	.pipe(
	// 		map((departmentList) => {
	// 		return departmentList;
	// 		})
	// 	);
	// }

}