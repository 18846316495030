// ai-bio.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AiBioService {
  private readonly GEMINI_API_KEY = 'AIzaSyC69L1F5JH_yTVY7JbkbOErFtNV7fmRlAE';
  private readonly GEMINI_API_URL = `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${this.GEMINI_API_KEY}`;

  constructor(private http: HttpClient) {}

  generateBio(name: string, businessType: string, address: string): Observable<string> {  
    const payload = {
      contents: [{ parts: [{ text: `Generate a professional bio for ${name}, located at ${address}, running ${businessType} in simple english and max upto 100 words.` }] }],
    };  
    return this.http.post<any>(this.GEMINI_API_URL, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(
      map(response => {
        return response.candidates?.[0]?.content.parts[0].text.replace(/^"|"$/g, '') || 'No response generated.';
      }),
      catchError(error => {
        console.error('Error fetching AI bio:', error);
        return throwError(() => new Error('Error fetching response from Gemini API.'));
      })
    );
  }
  
}
