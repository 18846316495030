import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { User } from "../models/user";

@Injectable({
    providedIn: "root",
})
export class UserService {
    private baseUrl = environment.baseURL + "user/";

    private getHttpOptions() {
        const currentUser = localStorage.getItem("currentUser");    
        let token = '';
        if (currentUser) {
            const userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ 'token': token ? token : '' })
        };
    }

    constructor(private http: HttpClient) {}

    getAllAdmin(): Observable<User> {
        return this.http.get<User>(this.baseUrl + "getAllAdmin", this.getHttpOptions());
    }

    getAllUser(params: any): Observable<User> {
        return this.http.post<User>(this.baseUrl + "getAllUser", params, this.getHttpOptions());
    }

    getAllUserRole(): Observable<User> {
        return this.http.get<User>(this.baseUrl + "getAllUserRole", this.getHttpOptions());
    }

    getUserById(userId: any): Observable<User> {
        return this.http.post<User>(this.baseUrl + "getUserById", { userId }, this.getHttpOptions());
    }

    addUser(user: User): Observable<User> {
        return this.http.post<User>(this.baseUrl + "createUser", user, this.getHttpOptions());
    }

    updateUser(user: User): Observable<User> {
        return this.http.post<User>(this.baseUrl + "updateUser", user, this.getHttpOptions());
    }

    deleteUser(userId: any): Observable<User> {
        return this.http.post<User>(this.baseUrl + "deleteUser", { userId }, this.getHttpOptions());
    }

    uploadUserImage(userId: any, image: any): Observable<User> {
        return this.http.post<User>(this.baseUrl + "uploadUserImage", { userId, image }, this.getHttpOptions());
    }
}
