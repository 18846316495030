<section>
    <header>
        <nav class="rad-navigation">
            <div class="rad-logo-container">
                <div class="row" style="display: flex; justify-content: space-between; ">
                    <div class="col-md-8 col-lg-10" style="margin-left: 1rem;">
                        <a href="javascript:;" class=""><img src="https://resources.donorzilla.com/statics/images/logo.png"></a>
                    </div>
                    <div class="col-md-4 col-lg-2 col-2">
                        <a href="javascript:;" class="rad-toggle-btn pull-right mobile-nav-icon"><i class="fa fa-bars"></i></a>
                    </div>
                </div>
            </div>
            <div class="rad-top-nav-container">
                <p><strong>Welcome, </strong></p>
                <span class="select_wrap welcome-header-nav" *ngIf="businessList">
                    <select (change)="onChange($event)">
                        <option value="{{business.business_id}}" *ngFor="let business of businessList;let i = index" [selected]="business.business_id == b_id">{{business.name}}</option>
                    </select>
                </span>
                <span class="select_wrap welcome-header-nav donor-span" *ngIf="!businessList">
                    {{name}}
                </span>
                <ul class="links">
                    <li class="rad-dropdown"><a class="rad-menu-item" href="/donor-profile"><i class="fa fa-user" aria-hidden="true"></i></a></li>
                    <li class="rad-dropdown notification-drop">
                        <div class="dropdown">
                            <div class="dropdown-container">
                                <a class="rad-menu-item "><i class="fa fa-bell dropdown-button" aria-hidden="true"><span class="rad-menu-badge">{{notificationCounter}}</span></i></a>
                                <ul class="dropdown-menu dropdown-select">
                                    <li class="list-notification color-white dropdown-header">
                                        <h5 class="head-not">Notifications</h5>
                                        <a href="javascript:;" (click)="updateNotification()" class="head-link">Mark all as read</a>
                                    </li>
                                    <li class="list-notification {{notification.status ? 'color-white' : 'color-grey'}}" *ngFor="let notification of notificationList;let i = index">
                                        <div class="notification-inner">
                                            <div class="notify-text">
                                                <p>{{notification.message}}</p>
                                            </div>
                                            <!-- <div class="notify-time">
                                                <p>2 min ago</p>
                                            </div> -->
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="rad-dropdown"><a class="rad-menu-item" href="logout"><i class="fa fa-sign-out" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </nav>
    </header>
</section>
