import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: [
		'./register.component.css'
	]
})

export class RegisterComponent implements OnInit {
	currentYear: number = new Date().getFullYear();

	registerForm = new FormGroup({
		first_name: new FormControl('', [Validators.required]),
		last_name: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
	});

	otpForm = new FormGroup({
		verifyPhone: new FormControl('', [Validators.required]),
		otp1: new FormControl('', [Validators.required]),
		otp2: new FormControl('', [Validators.required]),
		otp3: new FormControl('', [Validators.required]),
		otp4: new FormControl('', [Validators.required])
	});

	get f() {
		return this.registerForm.controls;
	}
	
	get g() {
		return this.otpForm.controls;
	}

	time: number = 0;
	display: any ;
	interval : any;

	users: User[] = [];
	otpSent = false;
	errorMessage = '';
	resendEnable = false;
	requestPhone = '';
	successMessage = '';
	formErrorMessages = {
		firstNameError: '',
		lastNameError: '',
		phoneError: '',
		verifyPhoneError: '',
		otpError: ''
	};

	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private title: Title,
		private meta: Meta
	) { }

	ngOnInit(): void {
		var currentUser = localStorage.getItem('currentUser');
		if(currentUser){
			this.router.navigate(['dashboard']);
		}

		this.title.setTitle('Register/Signup Now | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Sign up now to access lifesaving resources and healthcare services easily through our app and website.'
		}); 
	}

	timer(minute: any) {
		let seconds: number = minute * 60;
		let textSec: any = "0";
		let statSec: number = 60;
	
		const prefix = minute < 10 ? "0" : "";
	
		const timer = setInterval(() => {
		seconds--;
		if (statSec != 0) statSec--;
		else statSec = 59;
	
		if (statSec < 10) {
			textSec = "0" + statSec;
		} else textSec = statSec;
	
		this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
		if(seconds == 58){
			const sendOtpBtn = document.getElementById('send-otp-btn') as HTMLInputElement | null;
			sendOtpBtn?.click();
		}
		if (seconds == 0) {
			this.resendEnable = true;
			clearInterval(timer);
			(document.getElementById('verifyOtpBtn') as HTMLInputElement).disabled = true;
		}
		}, 1000);
	}

	resendOtp($event: any, phone: any): void {
		this.authenticationService.resendOtp({ phone } as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						successMessage?.click();
						this.resendEnable = false;
						this.timer(1);
						(document.getElementById('verifyOtpBtn') as HTMLInputElement).disabled = false;
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
				}
			);
	}

	register($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let phone = this.registerForm.value.phone;
		let first_name = this.registerForm.value.first_name;
		let last_name = this.registerForm.value.last_name;
		if (!phone || !first_name || !last_name) {
			const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
			let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
			dynamicMessage.value = 'All fields are required';
			errorMessage?.click();
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		if(first_name){
			var pattern = new RegExp("[a-zA-Z][a-zA-Z ]+[a-zA-Z]$");
			var result = pattern.test(first_name);
			if(!result){
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = 'First Name is not valid';
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
				return;
			}	
		}
		if(last_name){
			var pattern = new RegExp("[a-zA-Z][a-zA-Z ]+[a-zA-Z]$");
			var result = pattern.test(last_name);
			if(!result){
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = 'Last Name is not valid';
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
				return;
			}	
		}
		this.authenticationService.register({ 
			first_name,
			last_name,
			phone 
			} as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						successMessage?.click();
						this.otpSent = true;
						this.otpForm.controls["verifyPhone"].setValue(data.data.phone);
						this.requestPhone = data.data.phone;
						this.timer(1);
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	verifyOtp($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let phone = this.otpForm.value.verifyPhone;
		let otp1 = this.otpForm.value.otp1;
		let otp2 = this.otpForm.value.otp2;
		let otp3 = this.otpForm.value.otp3;
		let otp4 = this.otpForm.value.otp4;
		let otp = otp1 + '' + otp2 + '' + otp3 + '' + otp4;

		if (!phone || !otp) {
			const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
			let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
			dynamicMessage.value = 'All fields are required';
			errorMessage?.click();
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.authenticationService.verifyOtp({ phone, otp } as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						localStorage.setItem('currentUser', JSON.stringify(data));
						const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						successMessage?.click();
						setTimeout(() => {
							window.location.href = 'profile?opt=first';
						}, 2000);
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
					}
			);
	}
}
