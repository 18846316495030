<aside>
    <nav class="rad-sidebar">
        <ul>
            <li *ngIf="role_type != 2" [ngClass]="currentRoute == '/dashboard' ? 'active' : ''">
                <a href="dashboard" class="inbox">
                    <i aria-hidden="true" class="fa fa-home"></i>
                    <span class="rad-sidebar-item">Home</span>
                </a> 
            </li>
            <li *ngIf="role_type == 2" [ngClass]="currentRoute == '/donor-dashboard' ? 'active' : ''">
                <a href="donor-dashboard" class="inbox">
                    <i aria-hidden="true" class="fa fa-home"></i>
                    <span class="rad-sidebar-item">Home</span>
                </a>
            </li>
            <li [ngClass]="currentRoute == '/gallery' ? 'active' : ''">
                <a href="gallery">
                    <i class="fa fa-image"></i>
                    <span class="rad-sidebar-item">Gallery</span>
                </a>
            </li>
            <li *ngIf="role_type == 6" [ngClass]="currentRoute == '/appointment-settings' ? 'active' : ''">
                <a href="appointment-settings" class="snooz">
                    <i class="fa fa-calendar"></i>
                    <span class="rad-sidebar-item">Appointments</span>
                </a>
            </li>
            <li *ngIf="role_type !== 2" [ngClass]="currentRoute == '/doctor-list' ? 'active' : ''">
                <a href="doctor-list">
                    <i class="fa fa-user-md"></i>
                    <span class="rad-sidebar-item">Doctor List</span>
                </a>
            </li>
            <li *ngIf="role_type == 6"
            [ngClass]="currentRoute == '/appointment-details' ? 'active' : ''" 
            style="display: flex; align-items: center; justify-content: center; gap: 8px;"
          >
              <a 
                href="appointment-details" 
                class="sidebar-link" 
                style="display: flex; align-items: center;text-decoration: none;"
              >
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  <span class="rad-sidebar-item">My Appointments</span>
              </a>
          </li>
            <li>
                <a href="faq" target="_blank" class="done">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                    <span class="rad-sidebar-item">Help</span>
                </a>
            </li>
        </ul>
        <div class="btn-dashboard" *ngIf="detailedList">
            <a class="contact__btn" href="profile_new/{{detailedList.details?.id}}">Add New Branch</a>
        </div>
    </nav>
</aside>
