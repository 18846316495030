import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { CreateBloodRequestRoutingModule } from './create-blood-request-routing.module';
import { CreateBloodRequestComponent } from './create-blood-request.component';

@NgModule({
  imports: [
    CommonModule,
    CreateBloodRequestRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [CreateBloodRequestComponent],
  providers: [AuthGuard]
})
export class CreateBloodRequestModule { }
