import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: [
		'./faq.component.css'
	]
})
export class FaqComponent implements OnInit {

	constructor(private title: Title, private meta: Meta) {}

	ngOnInit()
	{ 
		this.title.setTitle('FAQs | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Find answers to common questions about DonorZilla. Get all the information you need in our FAQs section.'
		}); 
	}

  activeIndex: number | null = null; 

  toggleAccordion(index: number): void {
    if (this.activeIndex === index) {
        this.activeIndex = null;
    } else {
        this.activeIndex = index;
    }
}


  faqs = [
    { question: "What is DonorZilla?", answer: "DonorZilla is an innovative online platform designed to connect individuals with healthcare services, facilitating blood donor searches and business listings for hospitals, blood banks, and wellness centers." },
    { question: "How can I find a blood donor on DonorZilla?", answer: "To find a blood donor, enter your location and required blood type in the search bar. You’ll see a list of registered donors available in your area." },
    { question: "How do I register as a blood donor?", answer: "To register, visit the Donor Registration page, fill out the required information, and submit your details. You’ll receive confirmation once your profile is approved." },
    { question: "What are the eligibility criteria for blood donors?", answer: "Generally, donors must be at least 18 years old, weigh over 50 kg, and meet certain health guidelines. For specific criteria, please refer to our donor eligibility section." },
    { question: "How do I register my business on DonorZilla?", answer: "Business registration is simple. Visit our Business Listing page, complete the registration form, and provide essential details about your healthcare or wellness business." },
    { question: "What benefits does listing my business on DonorZilla offer?", answer: "Listing your business increases visibility to potential clients, enhances credibility, and connects you with individuals actively seeking healthcare services." },
    { question: "Is there a cost to list my business?", answer: "Currently, DonorZilla offers free business listings for healthcare and wellness services. Check our website for any updates regarding fees." },
    { question: "How can I update my business listing?", answer: "Once registered, log in to your account to edit your business information, including services offered, operating hours, and contact details." },
    { question: "What is the referral system on DonorZilla?", answer: "Our referral system allows users to invite others to register as blood donors or businesses. Successful referrals can earn rewards or recognition through our badge system." },
    { question: "How do I subscribe to the DonorZilla newsletter?", answer: "You can subscribe to our newsletter by entering your email in the subscription box on our homepage. Stay informed about healthcare news, updates, and promotions." },
    { question: "How frequently will I receive newsletters?", answer: "Subscribers will receive newsletters monthly, featuring healthcare tips, donor stories, and updates about DonorZilla services." },
    { question: "How can I contact DonorZilla support?", answer: "For assistance, visit our Contact Us page, where you’ll find our email and a contact form to submit your inquiries." },
    { question: "Is my personal information safe with DonorZilla?", answer: "Absolutely. DonorZilla prioritizes data security, employing encryption and following strict privacy policies to protect your personal information." },
    { question: "What types of businesses can be listed on DonorZilla?", answer: "We welcome all healthcare-related businesses, including hospitals, clinics, blood banks, pathology labs, and wellness centers to register and increase their visibility." },
    { question: "How can DonorZilla help improve community health?", answer: "By connecting donors with those in need and enhancing the visibility of healthcare services, DonorZilla contributes to better health outcomes and fosters a supportive community." }
  ];
}

