import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	private loginUrl = environment.baseURL + 'auth/webLogin';
	private registerUrl = environment.baseURL + 'auth/webRegister';
	private verifyOtpUrl = environment.baseURL + 'auth/webVerifyOtp';
	private resendOtpUrl = environment.baseURL + 'auth/webResendOtp';
	private deleteAccountUrl = environment.baseURL + 'auth/deleteAccount';
	
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(private http: HttpClient) { }

	login(user: User): Observable<User> {
		return this.http.post<User>(this.loginUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	register(user: User): Observable<User> {
		return this.http.post<User>(this.registerUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	verifyOtp(user: User): Observable<User> {
		return this.http.post<User>(this.verifyOtpUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	resendOtp(user: User): Observable<User> {
		return this.http.post<User>(this.resendOtpUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}

	deleteAccount(user: User): Observable<User> {
		return this.http.post<User>(this.deleteAccountUrl, user, this.httpOptions).pipe(map(user => {
			return user;
		})
		);
	}
}
