import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Amenity } from "../models/amenity";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class AmenityService {
    
    private getAmenityUrl = environment.baseURL + "amenity/getAmenity";
    private getAmenityByRoleIdUrl = environment.baseURL + "amenity/getAmenityByRoleId";
    private updateAmenityMapUrl = environment.baseURL + "amenity/updateAmenityMap";
    private getAmenityByBusinessIdUrl = environment.baseURL + "amenity/getAmenityByBusinessId";
    private getAmenityByUserIdUrl = environment.baseURL + "amenity/getAmenityByUserId";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) {}
      
    getAmenity(params: any): Observable<Amenity> {
        return this.http
            .post<Amenity>(this.getAmenityUrl, params, this.getHttpOptions())
            .pipe(map((amenityList) => amenityList));
    }
    
    getAmenityByRoleId(role_id: any): Observable<Amenity> {
        return this.http
            .post<Amenity>(this.getAmenityByRoleIdUrl, role_id, this.getHttpOptions())
            .pipe(map((amenityList) => amenityList));
    }
    
    updateAmenityMap(params: any): Observable<Amenity> {
        return this.http
            .post<Amenity>(this.updateAmenityMapUrl, params, this.getHttpOptions())
            .pipe(map((amenityList) => amenityList));
    }
    
    getAmenityByBusinessId(role_id: any): Observable<Amenity> {
        return this.http
            .post<Amenity>(this.getAmenityByBusinessIdUrl, role_id, this.getHttpOptions())
            .pipe(map((amenityList) => amenityList));
    }

    getAmenityByUserId(role_id: any): Observable<Amenity> {
        return this.http
            .post<Amenity>(this.getAmenityByUserIdUrl, role_id, this.getHttpOptions())
            .pipe(map((amenityList) => amenityList));
    }
}
