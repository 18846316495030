import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BusinessMasterService } from "../services/businessMaster.service";
import { BusinessMaster } from '../models/businessMaster';

@Component({
	selector: 'app-public-profile',
	templateUrl: './public-profile.component.html',
	styleUrls: [
		'./public-profile.component.css'
	]
})
export class PublicProfileComponent implements OnInit {

	pId: any;
	type: any;
	detailedList: any;
	imageList: any;

	constructor(
		private route: ActivatedRoute,
		private businessMasterService: BusinessMasterService,
	) { }

	ngOnInit(): void {
		this.pId = this.route.snapshot.paramMap.get('id');
		this.getRoleDetailsById(this.pId);
		this.getGalleryImagesById(this.pId);
	}

	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.type = data.data.type;
				}
			);
	}

	getGalleryImagesById(b_id: any): void {
		this.businessMasterService.getGalleryImagesById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.imageList = data.data;
				}
			);
	}

}