<footer>
    <div class="footer_top pt-115">
        <div class="container ">
            <div class="news-latter">
                <div class="row">
                    <div class="col-md-5">
                        <h2>Subscribe to our Newsletter</h2>
                    </div>
                    <div class="col-md-7">
                        <section class="bg-img ">
                            <h6 class="font-alt">Stay up to date</h6>
                            <form class="form-subscribe">
                                <div class="input-group">
                                    <input type="email" class="form-control input-lg" id="news_email" placeholder="Your email address">
                                    <span class="input-group-btn">
                                        <button class="btn btn-success btn-lg" type="button" (click)="addNewsletter($event)">Subscribe</button>
                                    </span>
                                </div>
                            </form>
                            <p>by subscribing you will agree to privacy and policy</p>
                            <br>
                            <div *ngIf="errorMessage">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div *ngIf="successMessage">
                                <div class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div class="row footer_middle">
                <div class="col-xl-4 col-lg-4 col-12 ">
                    <div class="footer_subscrive">
                        <img src="https://resources.donorzilla.com/statics/images/logo-white.png" alt="DonorZilla">
                        <p>Connecting healthcare and wellness for a healthier community.</p>
                    </div>
                    <div class="header_top_social">                       
                        <ul class="d-flex">
                            <li><a href="https://www.facebook.com/donorzilla/" target="_blank" title="Facebook"><i class="fa fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/DonorZilla" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
                            <li><a href=" https://www.instagram.com/donorzilla/" target="_blank" title="Instagram"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="https://www.pinterest.com/donorzilla/" target="_blank" title="Pinterest"><i class="fa fa-pinterest-p"></i></a></li>
                            <li><a href="https://www.youtube.com/@donorzilla" target="_blank" title="Youtube"><i class="fa fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/donorzilla/" title="Linkedin" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-12">
                    <div class="quick-links">
                        <h3>Business</h3>
                        <ul>
                            <li><a href="doctor">Doctor</a></li>
                            <li><a href="javascript:;">Hospital</a></li>
                            <li><a href="javascript:;">Blood Bank</a></li>
                            <li><a href="javascript:;">Path Lab</a></li>
                            <li><a href="javascript:;">IVF</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-12">
                    <div class="quick-links">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="donor">Blood Donors</a></li>
                            <li><a href="business">Business Listings</a></li>
                            <li><a href="about-us">About Us</a></li>
                            <li><a href="faq">FAQ</a></li>
                            <li><a href="contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-12">
                   <div class="quick-links">
                        <h3>Get In Touch</h3>
                        <ul>
                            
                            <li><a href="mailto:contact@donorzilla.com"><i class="fa fa-solid fa-envelope"></i> contact&#64;donorzilla.com</a></li>
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> H-143, Suite - 101,  H Block, Sector 63, Noida 201301</li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                   <div class="col-xl-4 col-lg-4 col-12 ">
                    <div class="footer_subscrive">
                      <p>Copyright &copy; {{currentYear}}. All rights reserved</p>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-12">
                    <div class="footer_social">
                        <ul class="page_link d-flex pagelnkk">
                            <li><a href="privacy-policy">Privacy Policy</a></li>
                            <li><a href="javascript:;">/</a></li>
                            <li><a href="terms-condition">Terms & Condition</a></li>
                            <li><a href="javascript:;">/</a></li>
                            <li><a href="delete-account">Delete Account</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="offcanvas offcanvas-start" id="offcanvas-mobile">
    <div class="offcanvas-body">
        <div class="mobile-menu">
            <a href="" class="logo py-3"><img src="https://resources.donorzilla.com/statics/images/logo-website.png" alt="logo" class="img-fluid"></a>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            <ul class="accordion accordion-flush mobile_dropdown" id="accordionFlushExample">
                <li class="accordion-item">
                    <h2><a href="">Home</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="donor">Blood Donors</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="business">Business Listings</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="about-us">About Us</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="contact-us">Contact Us</a></h2>
                </li>
                <li class="accordion-item">
                    <h2 ><a href="login" style="color: #ff0000;">Login</a></h2>
                </li>
            </ul>
        </div>
    </div>
</div>
