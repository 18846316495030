import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-doctor',
	templateUrl: './doctor.component.html',
	styleUrls: [
		'./doctor.component.css'
	]
})
export class DoctorComponent implements OnInit {

	constructor(private title: Title, private meta: Meta) {}

	ngOnInit()
	{ 
		this.title.setTitle('List Your Hospitals or Clinics | Reach More Patients with DoctorZilla'); 

		this.meta.updateTag(
		{
			name:'keywords',
			content:'list your hospitals, list your clinics'
		}); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Expand your reach by listing your hospital or clinic on DoctorZilla. Connect with patients and boost your visibility in the healthcare community. Register now to grow your practice!'
		}); 
	}
}