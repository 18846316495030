import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ArticleCategory } from "../models/articleCategory";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class ArticleCategoryService {
    
    private getAllArticleCategoryUrl = environment.baseURL + "articleCategory/getAllArticleCategory";
    private getArticleCategoryWithCountUrl = environment.baseURL + "articleCategory/getArticleCategoryWithCount";
    private getAllArticleCategoryWithCountUrl = environment.baseURL + "articleCategory/getAllArticleCategoryWithCount";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) {}
    
    getAllArticleCategory(): Observable<ArticleCategory> {
        return this.http
            .get<ArticleCategory>(this.getAllArticleCategoryUrl, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }

    getArticleCategoryWithCount(): Observable<ArticleCategory> {
        return this.http
            .get<ArticleCategory>(this.getArticleCategoryWithCountUrl, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }

    getAllArticleCategoryWithCount(): Observable<ArticleCategory> {
        return this.http
            .get<ArticleCategory>(this.getAllArticleCategoryWithCountUrl, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }
}
