<section class="hm1_hero_slider slider-container">
    <div class="hm1_hero hm1 hm_bg" id="slide1">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hm1_content">
                        <div class="text-box">
                            <h2>Transforming Healthcare with Smarter Solutions</h2>
                            <p>Connecting patients, healthcare providers, and donors seamlessly</p>
                            <div class="text-box-butn">
                                <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" class="btn">Find Blood Donors</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="key-servces">
    <div class="container">
        <h2 class="text-center">Our Core Services</h2>
        <p class="text-center">Simplifying healthcare for businesses and individuals alike</p>
        <div class="key-inner">
            <div class="row">
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc1.png" alt="Find Blood Donors">
                        <h3>Find Blood Donors</h3>
                        <p>Easily connect with verified blood donors in your area.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc7.png" alt="Business Listings">
                        <h3>Find a Doctor</h3>
                        <p>Connecting you with trusted doctors for better care, every time.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc3.png" alt="Book Appointments">
                        <h3>Book Appointments</h3>
                        <p>Seamlessly book appointments with hospitals and clinics through our platform.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc4.png" alt="Donate to Wellness Programs">
                        <h3>Donate to Wellness Programs</h3>
                        <p>Support wellness and health programs through easy donations.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc5.png" alt="NGO Fundraising">
                        <h3>NGO Fundraising</h3>
                        <p>Raise funds for health-related causes and<br> NGOs.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc6.png" alt="Healthcare Reviews">
                        <h3>Healthcare Reviews</h3>
                        <p>Rate and review hospitals and healthcare services for transparency and trust.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="how-it-works">
    <div class="container">
        <h2 class="text-center"> How DonorZilla Works</h2>
        <p class="text-center">Simplifying healthcare for businesses and individuals alike</p>
        <div class="how-worksss-inner">
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w1.png">
                <h3>Sign Up</h3>
                <p>Create your account for free.</p>
            </div>
            <div class="how-img-1 hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p2.png">
            </div>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w3.png" alt="Find Donors">
                <h3>Find Donors</h3>
                <p>Search for available donors in real-time.</p>
            </div>
            <div class="how-img-2  hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p1.png">
            </div>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w6.png">
                <h3>Find a Doctor</h3>
                <p>Search for available donors in real-time.</p>
            </div>
            <div class="how-img-3  hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p2.png">
            </div>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w4.png">
                <h3>Book Appointments</h3>
                <p>Instantly schedule appointments with healthcare providers.</p>
            </div>
            <div class="how-img-4  hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p1.png">
            </div>
            <div class="how-it-wrk-space wrkspc">
                <img src="https://resources.donorzilla.com/statics/images/w5.png">
                <h3>Donate to Causes</h3>
                <p>Easily contribute to wellness programs or NGOs</p>
            </div>
        </div>
    </div>
</section>  
<!-- <section id="how-it-works">
    <div class="container">
        <h2 class="text-center"> How DonorZilla Works</h2>
        <p class="text-center">Simplifying healthcare for businesses and individuals alike</p>
        <div class="how-worksss-inner">
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w1.png" alt="Sign Up">
                <h3>Sign Up</h3>
                <p>Create your account for free.</p>
            </div>
            <div class="how-img-1 hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p2.png">
            </div>
            <div class="how-img-2  hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p1.png">
            </div>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w3.png" alt="Find Donors">
                <h3>Find Donors</h3>
                <p>Search for available donors in real-time.</p>
            </div>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w6.png">
                <h3>Find a Doctor</h3>
                <p>Search for available donors in real-time.</p>
            </div>
            <div class="how-img-2  hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p1.png">
            </div>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w4.png" alt="Book Appointments">
                <h3>Book Appointments</h3>
                <p>Instantly schedule appointments with healthcare providers.</p>
            </div>
            <div class="how-img-4  hw-im">
                <img src="https://resources.donorzilla.com/statics/images/p1.png">
            </div>
            <div class="how-it-wrk-space wrk-last-emt">
                <img src="https://resources.donorzilla.com/statics/images/w5.png" alt="Donate to Causes">
                <h3>Donate to Causes</h3>
                <p>Easily contribute to wellness programs or NGOs</p>
            </div>
        </div>
    </div>
</section> -->
<section id="testimonials-new">
    <div class="container">
        <h2>We have helped 1500+ families nationwide in health</h2>
        <div class="test-section">
            <div class="row">
                <div class="col-lg-5">
                    <img src="https://resources.donorzilla.com/statics/images/testi.png" alt="">
                </div>
                <div class="col-lg-7">
                    <div id="slider">
                        <button href="javascript:;" class="control_next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                        <button href="javascript:;" class="control_prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                        <ul>
                            <li>
                                <div class="text-slid">
                                    <p><img src="https://resources.donorzilla.com/statics/images/bl1.png"> A calmness fills my soul, like the peaceful mornings of spring. The care here has truly transformed my life. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor aliqua.</p>
                                    <h5>Johnathan</h5>
                                    <h6>Diabetes Patient</h6>
                                </div>
                            </li>
                            <li>
                                <div class="text-slid">
                                    <p><img src="https://resources.donorzilla.com/statics/images/bl1.png">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod aliqua.</p>
                                    <h5>Johnathan</h5>
                                    <h6>Diabetes Patient</h6>
                                </div>
                            </li>
                            <li>
                                <div class="text-slid">
                                    <p><img src="https://resources.donorzilla.com/statics/images/bl1.png">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod aliqua.</p>
                                    <h5>Johnathan</h5>
                                    <h6>Diabetes Patient</h6>
                                </div>
                            </li>
                            <li>
                                <div class="text-slid">
                                    <p><img src="https://resources.donorzilla.com/statics/images/bl1.png">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod aliqua.</p>
                                    <h5>Johnathan</h5>
                                    <h6>Diabetes Patient</h6>
                                </div>
                            </li>
                        </ul>  
                    </div>
                </div> 
            </div>    
        </div>
    </div>
</section>
  
<!-- <section id="business-benefit">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="business-innr">
                    <h2>Why List Your Business on DonorZilla?</h2>
                    <p>Boost your visibility and connect with more clients by listing your hospital, blood bank, or wellness center on DonorZilla. Join a trusted network dedicated to healthcare and wellness growth.</p>
                    <div class="text-box-butnn">
                        <a href="register" class="btn">Register Your Business</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="right-business-text">
                    <ul>
                        <li><img src="https://resources.donorzilla.com/statics/images/Increased-visibility.png" alt="Increased visibility"><br> Increased visibility in the healthcare industry</li>
                        <li><img src="https://resources.donorzilla.com/statics/images/seamless-appointment-booking.png" alt="Seamless appointment"><br> Seamless appointment bookings</li>
                        <li><img src="https://resources.donorzilla.com/statics/images/attract-patients.png" alt="Attract more patients"><br> Attract more patients and clients</li>
                        <li><img src="https://resources.donorzilla.com/statics/images/reviews-and-ratings.png" alt="Engage with reviews"><br> Engage with reviews and ratings to build trust</li>
                        <li class="right-business-text-last-emt"><img src="https://resources.donorzilla.com/statics/images/donor-network.png" alt="Network for emergencies"><br> Access to a large donor network for emergencies</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section id="badges">
    <div class="container">
        <div class="row  flex-column-reverse flex-lg-row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m5.png" alt="Life Saver">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m4.png" alt="Health Hero">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m3.png" alt="Community Champion">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m2.png" alt="Wellness Warrior">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m1.png" alt="DonorZilla Ambassador">
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            <div class="col-md-6"><br>
                <h2> Earn Badges, Gain Benefits!</h2>
                <p>Boost your reputation by earning badges through referrals and activities. Boost your reputation by earning badges through referrals and activities. Boost your reputation by earning badges through referrals and activities.</p>
                <div class="text-box-butnn btn badge-btnn">
                    <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" class="btn">Find Donors Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="call-action">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-12 col-12">
                <div class="appoinment-text">
                    <br><br>
                    <h2>Join DonorZilla Today!</h2>
                    <p>Sign up to transform your healthcare experience.</p>
                    <div class="text-box-butnn">
                        <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" class="btn">Find Donors Now</a>
                    </div>  
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-12"></div>
        </div>
    </div>
</section>
<!-- <div class="newsletter">
    <div class="container">
        <h2 class="text-center"> Stay Informed</h2>
        <p class="text-center">Stay updated with the latest healthcare trends, insights, and <br> business opportunities from DonorZilla.</p><br>
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-7">
                <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="addNewsletter($event)">
                    <input type="email" formControlName="email" placeholder="Enter your email...">
                    <button type="submit">Subscribe Now</button>
                </form>
                <br>
                <div *ngIf="errorMessage">
                    <div class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                </div>
                <div *ngIf="successMessage">
                    <div class="alert alert-success" role="alert">
                        {{successMessage}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="take-care">
    <div class="container">
        <div class="take-care-inner">
            <h2>Ready to take care of your<br> health and future?</h2>
            <div class="text-box-butnn">
                <a href="register" class="btn">Start Now</a>
            </div>
        </div>
    </div>
</div>  
