import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: [
		'./header.component.css'
	]
})
export class HeaderComponent implements OnInit {

  	name = '';
	role_type = '';
	email = '';
	currentRoute = '';

  	constructor(private router: Router) {
		this.currentRoute = this.router.url;
		let currentUser: any = localStorage.getItem('currentUser');
		let userObject = JSON.parse(currentUser);
		if (currentUser) {
			this.name = userObject.data.name;
			this.role_type = userObject.data.role_type;
			this.email = userObject.data.email;
		}
  	}
  	ngOnInit() {
			
	}
}