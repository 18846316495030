<section class="static-hero blood-hero home-hero" id="div">
    <div class="hero-container">
        <div class="hero-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-12">
                        <div class="wpo-static-hero-inner">
                            <div class="slide-sub-title">
                                <h2>Transforming Healthcare with Smarter Solutions</h2>
                            </div>
                            <div class="slide-text">
                                <p>Connecting patients, healthcare providers, and donors seamlessly</p>
                            </div>
                            <div class="slide-btn">
                                <a class="theme-btn " href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" >Find Blood Donor</a> 
                                <a class="theme-btn "  href="register">Register Your Healthcare Business</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="key-servces">
    <div class="container">
        <h2 class="text-center">Our Services</h2>
        <p class="text-center">Expert solutions in healthcare, wellness, and business visibility to drive growth and trust.</p>
        <div class="key-inner">
            <div class="row">
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc1.png">
                        <h3>Find Blood Donors</h3>
                        <p>Easily connect with verified blood donors in your area.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc2.png">
                        <h3>Business Listings</h3>
                        <p>Get your healthcare business listed and visible to a larger audience.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc3.png">
                        <h3>Book Appointments</h3>
                        <p>Seamlessly book appointments with hospitals and clinics through our platform.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc4.png">
                        <h3>Donate to Wellness Programs</h3>
                        <p>Support wellness and health programs through easy donations.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc5.png">
                        <h3>NGO Fundraising</h3>
                        <p>Raise funds for health-related causes and<br> NGOs.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-inner-txt">
                        <img src="https://resources.donorzilla.com/statics/images/oc6.png">
                        <h3>Healthcare Reviews</h3>
                        <p>Rate and review hospitals and healthcare services for transparency and trust.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="how-it-works">
    <div class="container">
        <h2 class="text-center"> How DonorZilla Works</h2>
        <p class="text-center">Easily list, connect, and grow your healthcare or wellness business with our simple, user-friendly platform.</p>
        <div class="how-worksss-inner">
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w1.png">
                <h3>Sign Up</h3>
                <p>Create your account for free.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w2.png">
                <h3>List Your Business</h3>
                <p>Register your healthcare facility in a few clicks.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w3.png">
                <h3>Find Donors</h3>
                <p>Search for available donors in real-time.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w4.png">
                <h3>Book Appointments</h3>
                <p>Instantly schedule appointments with healthcare providers.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/w5.png">
                <h3>Donate to Causes</h3>
                <p>Easily contribute to wellness programs or NGOs</p>
            </div>
        </div>
    </div>
</section>
<section id="business-benefit">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="business-innr">
                    <h2>Why List Your Business on DonorZilla?</h2>
                    <p>Boost your visibility and connect with more clients by listing your hospital, blood bank, or wellness center on DonorZilla. Join a trusted network dedicated to healthcare and wellness growth.</p>
                    <div class="text-box-butnn">
                        <a href="register" class="btn">Register Your Business</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="right-business-text">
                    <ul>
                        <li><img src="https://resources.donorzilla.com/statics/images/Increased-visibility.png"><br> Increased visibility in the healthcare industry</li>
                        <li><img src="https://resources.donorzilla.com/statics/images/seamless-appointment-booking.png"><br> Seamless appointment bookings</li>
                        <li><img src="https://resources.donorzilla.com/statics/images/attract-patients.png"><br> Attract more patients and clients</li>
                        <li><img src="https://resources.donorzilla.com/statics/images/reviews-and-ratings.png"><br> Engage with reviews and ratings to build trust</li>
                        <li style="margin-left: 30%;"><img src="https://resources.donorzilla.com/statics/images/donor-network.png"><br> Access to a large donor network for emergencies</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="badges">
    <div class="container">
        <div class="row  flex-column-reverse flex-lg-row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m1.png">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m2.png">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m3.png">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m4.png">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="badge-inner">
                            <img src="https://resources.donorzilla.com/statics/images/m5.png">
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            <div class="col-md-6"><br>
                <h2> Earn Badges, Gain Benefits!</h2>
                <p>Boost your reputation by earning badges through referrals and activities. Boost your reputation by earning badges through referrals and activities. Boost your reputation by earning badges through referrals and activities.</p>
                <div class="text-box-butnn badge-btnn">
                    <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" class="btn">Find Donors Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="call-action">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-12 col-12">
                <div class="appoinment-text">
                    <br><br>
                    <h2>Join DonorZilla Today!</h2>
                    <p>Sign up to transform your healthcare experience.</p>
                    <div class="text-box-butnn">
                        <a href="register" class="btn">Register Your Business</a>
                        <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" class="btn">Find Donors Now</a>
                    </div>  
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-12"></div>
        </div>
    </div>
</section>
<div class="newsletter">
    <div class="container">
        <h2 class="text-center"> Stay Informed</h2>
        <p class="text-center">Stay updated with the latest healthcare trends, insights, and <br> business opportunities from DonorZilla.</p><br>
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-7">
                <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="addNewsletter($event)">
                    <input type="email" formControlName="email" placeholder="Enter your email...">
                    <button type="submit">Subscribe Now</button>
                </form>
                <br>
                <div *ngIf="errorMessage">
                    <div class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                </div>
                <div *ngIf="successMessage">
                    <div class="alert alert-success" role="alert">
                        {{successMessage}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>