import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { DonorRequestDetailsRoutingModule } from './donor-request-details-routing.module';
import { DonorRequestDetailsComponent } from './donor-request-details.component';

@NgModule({
  imports: [
    CommonModule,
    DonorRequestDetailsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [DonorRequestDetailsComponent],
  providers: [AuthGuard]
})
export class DonorRequestDetailsModule { }
