<body class="g-sidenav-show bg-gray-200">
  	<div class="main-content position-relative max-height-vh-100 h-100 main-content-width">
    	<div class="container-fluid px-2 px-md-4" style="margin-top: 60px;" *ngIf="detailedList">
      		<div class="card card-body mx-3 mx-md-4 mt-n6">
				<form [formGroup]="addBusinessForm" (ngSubmit)="addBusinessImages($event)" class="form" encType="multipart/form-data">
					<div class="row">
						<div class="col-md-5">
							<label for="images" class="drop-container" id="dropcontainer">
								<span class="drop-title">Click here to upload files</span>
								<input #imageInput class="form-control form-control-solid" type="file" accept="image/*" (change)="processFile(imageInput)" multiple="" />
							</label>
						</div>
						<div class="col-md-7">
							<img *ngFor='let url of images'  [src]="url" height="150" width="200px" style="margin: 3px;">
						</div>
					</div>
					<div class="submit-image">
						<button class="btn bg-gradient-primary w-100" type="submit">Submit</button>
					</div>
				</form>
				<div class="row">
					<div class="col-md-12">
						<h3>Recent Uploaded Images</h3>
						<img *ngFor='let image of imageList'  [src]="image.image" height="150" width="200px" style="margin: 3px;">
					</div>
				</div>
      		</div>
    	</div>
  	</div>
