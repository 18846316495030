<div class="container-fluid">
    <div class="row">
      <!-- Sidebar -->
      <nav class="col-md-3 col-lg-2 d-md-block bg-light sidebar p-3">
        <button class="btn btn-primary w-100 d-md-none mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu">
          Toggle Menu
        </button>
        <div class="collapse d-md-block" id="sidebarMenu">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link text-black" (click)="setActiveTab('My Profile'); ; getUserById(userId)" [class.active]="activeTab === 'My Profile'">My Profile</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-black" href="#" (click)="setActiveTab('Settings')" [class.active]="activeTab === 'Settings'">Settings</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-black" target="_blank" href="terms-condition" (click)="setActiveTab('Terms & Conditions')" [class.active]="activeTab === 'Terms & Conditions'">Terms & Conditions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-black" target="_blank" href="privacy-policy" (click)="setActiveTab('Privacy Policy')" [class.active]="activeTab === 'Privacy Policy'">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-black" target="_blank" href="#" (click)="setActiveTab('Invite & Earn')" [class.active]="activeTab === 'Invite & Earn'">Invite & Earn</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-black" target="_blank" href="delete-account" (click)="setActiveTab('Delete Account')" [class.active]="activeTab === 'Delete Account'">Delete Account</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-black" href="logout" (click)="setActiveTab('Log Out')" [class.active]="activeTab === 'g'">Log Out</a>
            </li>
          </ul>
        </div>
      </nav>
  
      <!-- Content Area -->
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div *ngIf="activeTab === 'a'" class="content p-3 bg-light">Content for A</div>
        <div *ngIf="activeTab === 'b'" class="content p-3 bg-light">Content for B</div>
        <div *ngIf="activeTab === 'c'" class="content p-3 bg-light">Content for C</div>
        <div *ngIf="activeTab === 'd'" class="content p-3 bg-light">Content for D</div>
        <div *ngIf="activeTab === 'e'" class="content p-3 bg-light">Content for E</div>
      </main>
    </div>
  </div>

<section>
    <main>
        <section>
          <div class="rad-body-wrapper">
            <div class="container-fluid">
              <div class="white-bg">
                <div class="row" style="display: flex; justify-content: space-between;">
                  <h3>My Profile</h3>
                  <a type="button" style="width: 150px; height: 40px;" href="/edit-donor-profile/{{userDetails.id}}" class="btn bg-gradient-primary wd-25 submit-btn-gradient" >Edit Profile</a>
                </div>
              </div>
              <div class="white-bg">
                <div class="card-body p-3">
                  <div class="row">
                    <div class="dash-profile">
                      <div class="avatar-upload">
                        <div class="avatar-edit">
                          <input
                            class="form-control form-control-solid"
                            id="imageUpload"
                            type="file"
                            accept="image/*"
                            (change)="uploadPicture($event)"
                          />
                          <label for="imageUpload">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="31"
                              height="30"
                              viewBox="0 0 31 30"
                              fill="none"
                            >
                              <circle cx="15.5249" cy="15" r="15" fill="#FF0000" />
                              <path
                                d="M10.2925 17.2687V14.1757H21.4553V17.2687H10.2925ZM14.3255 9.76758H17.4463V21.628H14.3255V9.76758Z"
                                fill="white"
                              />
                            </svg>
                          </label>
                        </div>
                        <div class="avatar-preview">
                          <div id="imagePreview">
                            <img
                              src="{{ userDetails?.profile_picture }}"
                              *ngIf="userDetails?.profile_picture"
                              alt="profile_image"
                              class="w-100 border-radius-lg shadow-sm"
                            />
                            <img
                              src="https://resources.donorzilla.com/statics/images/logo-short.png"
                              *ngIf="!userDetails?.profile_picture"
                              alt="profile_image"
                              class="w-100 border-radius-lg shadow-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">First Name</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.first_name }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Last Name</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.last_name }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Username</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.user_name }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Email ID</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.email }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Document</p>
                        </div>
                        <div class="col-7">
                          <img
                            alt="Pic"
                            [src]="userDetails?.document"
                            id="imgPlacement"
                            [hidden]="!userDetails?.document"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Phone</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.phone }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Date Of Birth</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.date_of_birth }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Blood Group</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.blood_group }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Status</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{
                              userDetails?.status == "1"
                                ? "Active"
                                : "Inactive"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-5">
                          <p class="text-sm my-auto text-500">Gender</p>
                        </div>
                        <div class="col-7">
                          <p class="text-sm my-auto">
                            {{ userDetails?.gender }} Units
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <p class="text-sm my-auto text-500">Gender</p>
                          </div>
                          <div class="col-7">
                            <p class="text-sm my-auto">
                              {{ userDetails?.gender }} Units
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">   
                          <div class="col-5">
                            <p class="text-sm my-auto text-500">Location</p>
                          </div>
                          <div class="col-7">
                            <p class="text-sm my-auto">
                              {{ userDetails?.location }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <p class="text-sm my-auto text-500">About</p>
                          </div>
                          <div class="col-7">
                            <p class="text-sm my-auto">
                              {{ userDetails?.about }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <p class="text-sm my-auto text-500">Address</p>
                          </div>
                          <div class="col-7">
                            <p class="text-sm my-auto">
                              {{ userDetails?.address }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <p class="text-sm my-auto text-500">Longitude</p>
                          </div>
                          <div class="col-7">
                            <p class="text-sm my-auto">
                              {{ userDetails?.longitude }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-5">
                            <p class="text-sm my-auto text-500">Latitude</p>
                          </div>
                          <div class="col-7">
                            <p class="text-sm my-auto">
                              {{ userDetails?.latitude }}
                            </p>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
</section>  
  