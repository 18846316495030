import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-blog-list',
	templateUrl: './blog-list.component.html',
	styleUrls: [
		'./blog-list.component.css'
	]
})
export class BlogListComponent implements OnInit {

	constructor(
		private title: Title,
		private meta: Meta,
		
	) { }

	ngOnInit(): void {
		this.title.setTitle('Blogs | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:"DonorZilla all Blogs & blog posts"
		});
	}
}
