import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentSettingsComponent } from './appointment-settings.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'appointment-settings',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: AppointmentSettingsComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,
      showBusinessHeader: true,
      showBusinessSidebar: true,
      showBusinessFooter: true,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentSettingsRoutingModule { }
