import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';

import { BusinessMaster } from '../models/businessMaster';

import { BusinessMasterService } from "../services/businessMaster.service";
import { RoleService } from "../services/role.service";

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [
		'./profile.component.css'
	]
})
export class ProfileComponent implements OnInit {
	
	validPattern = "^[a-zA-Z0-9]{30}$"; // alphanumeric exact 10 letters

	profileForm = new FormGroup({
		role_type: new FormControl('', [Validators.required]),
		name: new FormControl('', [Validators.required, Validators.pattern(this.validPattern)]),
		myaddress: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
		e_phone: new FormControl('', [Validators.required]),
		c_name: new FormControl('', [Validators.required]),
		c_email: new FormControl('', [Validators.required]),
		c_phone: new FormControl('', [Validators.required]),
		about: new FormControl('', [Validators.required]),
	});

	get f() {
		return this.profileForm.controls;
	}
	
	roleList: any;
	user_id = 0;
	role_type = 0;
	opt: any;

	title: any;
	
	constructor(
		private businessMasterService: BusinessMasterService,
		private roleService: RoleService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.opt = this.route.snapshot.queryParams.opt;
		if(this.opt == 'first'){
			this.router.navigate(['profile']);
		}
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(this.role_type){
				setTimeout(() => {
					this.router.navigate(['dashboard']);
				}, 100);
			}
			this.getRoleType();
		}
	}

	getRoleType(): void {
		this.roleList = [];

		this.roleService.getRole().subscribe(
		(data) => {
			this.roleList = data.data;
		});
	}

	onChangeSelect($event: any){
		let text = $event.target.options[$event.target.options.selectedIndex].text;
		this.title = text;
	}

	profile($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let role_type = this.profileForm.value.role_type.trim();
		let name = this.profileForm.value.name.trim();
		let email = this.profileForm.value.email.trim();
		let phone = this.profileForm.value.phone.trim();
		let e_phone = this.profileForm.value.e_phone.trim();
		let c_name = this.profileForm.value.c_name.trim();
		let c_email = this.profileForm.value.c_email.trim();
		let c_phone = this.profileForm.value.c_phone.trim();
		let about = this.profileForm.value.about.trim();
		let address = $('#address').val();
		let latitude = $('#latitude').val();
		let longitude = $('#longitude').val();
		let city = $('#city').val();
		let state = $('#state').val();
		let country = $('#country').val();

		let user_id = 0;
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			user_id = userArray.data.id;
		}

		this.businessMasterService.addBusinessMaster({ 
			name,
			address,
			email,
			phone,
			e_phone,
			c_name,
			c_email,
			c_phone,
			about,
			latitude,
			longitude,
			user_id,
			role_type,
			city,
			state,
			country
			} as BusinessMaster)
			.subscribe(
				data => {
					if (data.status == 200) {
						const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						successMessage?.click();
						
						let retrieved1String = localStorage.getItem("currentUser");
						if (retrieved1String) {
							let parsedObject = JSON.parse(retrieved1String);
							parsedObject.data.role_type = role_type;
							const modifiedndstrigifiedForStorage = JSON.stringify(parsedObject);
							localStorage.setItem("currentUser", modifiedndstrigifiedForStorage);
						}
						setTimeout(() => {
							this.router.navigate(['thankyou']);
						}, 2000);
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}
}