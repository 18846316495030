import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BloodRequest } from "../models/bloodRequest";
import { NgbModal, ModalDismissReasons, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: 'app-donor-request-details',
	templateUrl: './donor-request-details.component.html',
	styleUrls: [
		'./donor-request-details.component.css'
	]
})
export class DonorRequestDetailsComponent implements OnInit {
   
	private modelref?: NgbModalRef;
	user_id: any;
	bloodRequestId: any;
	donor_request_id: any;
	bloodRequestDetails: any;
	requestDetailsTimeline: any;
	donorChat: any;
	bloodDocument: any;
	image: any;

	errorMessage = "";
	successMessage = "";
	closeResult = "";
	request_id: any;
	donor_id: any;
	is_accepted= 0
	review: string = '';
	rating: number = 0;
	selectedComments: string[] = []; 

	rate(stars: number) {
  		this.rating = stars;
	}

	commentOptions: string[] = ["Donor Behaviour", "Response Time", "Clean History", "Truthful"];

	toggleComment(comment: string): void {
		const index = this.selectedComments.indexOf(comment);
		if (index === -1) {
		  this.selectedComments.push(comment); 
		} else {
		  this.selectedComments.splice(index, 1);
		}
	  }
		
	constructor(
		private bloodRequestService: BloodRequestService,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.donor_id = userArray.data.id;
		    this.bloodRequestId = this.route.snapshot.paramMap.get('id');
		    this.getBloodRequestById(this.bloodRequestId);
		    this.getBloodRequestTimeline(this.bloodRequestId);
			this.getBloodRequestDonorsById(this.bloodRequestId);
			
		}				
	}

	getBloodRequestById(bloodRequestId: any): void {
		
		this.bloodRequestService.getBloodRequestById(bloodRequestId).subscribe(
			(data) => {	
				if (data.status == 200) {
					this.bloodRequestDetails = data.data;
				} else {
					this.bloodRequestDetails = [];
				}
			},
			(error) => {
			}
		);
	}

	getBloodRequestTimeline(request_id: any): void {
		let userId = this.user_id;
		this.bloodRequestService.getBloodRequestTimeline({ request_id, userId } as BloodRequest)
			.subscribe(
				data => {
					this.requestDetailsTimeline = data.data;
				}
			);
	}
	getBloodRequestDonorsById(request_id: any): void {
		
		this.bloodRequestService.getBloodRequestDonorsById({ request_id} as BloodRequest)
			.subscribe(
				data => {
					this.donorChat = data.data;
				}
			);
	}
	
	uploadBloodDocument(imageInput: any): void {
		const element = imageInput.currentTarget as HTMLInputElement;
    	let fileList: FileList | null = element.files;
    	if (fileList && fileList[0]) {
		var reader = new FileReader();
		reader.onload = (event: any) => {
			this.image = event.target.result;
			let request_id = this.bloodRequestId;
			this.bloodRequestService
			.uploadBloodDocument(this.image, request_id)
			.subscribe(
				(data) => {
              if (data.status == 200) {
                const successMessage = document.getElementById(
                  "btnSuccess"
                ) as HTMLInputElement | null;
                let dynamicMessage: any = document.getElementById(
                  "dynamic_success_msg"
                ) as HTMLInputElement | null;
                dynamicMessage.value = data.message;
                successMessage?.click();
                reader.onload = (event: any) => {
                  this.image = event.target.result;
                  setTimeout(() => {
                    window.location.reload();
                  }, 4000);
                  if (this.image instanceof File) {
                    reader.readAsDataURL(this.image);
                  } 
                };
              } else {
                const errorMessage = document.getElementById(
                  "btnError"
                ) as HTMLInputElement | null;
                let dynamicMessage: any = document.getElementById(
                  "dynamic_message"
                ) as HTMLInputElement | null;
                dynamicMessage.value = data.message;
                errorMessage?.click();
              }
            },
            (error) => {
              this.errorMessage = error;
            }
          );
      };
      reader.readAsDataURL(fileList[0]);
    }		
	}

	deleteBloodDocument($event: any): void {
		let request_id = this.bloodRequestId;
	
		this.bloodRequestService.deleteBloodDocument({request_id})
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	acceptDonor($event: any, donor_request_id: any): void {
		let userId = this.user_id;
		let request_id = this.bloodRequestId;

		this.bloodRequestService.acceptDonorRequest({donor_request_id, userId, request_id} as BloodRequest)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	rejectRequest($event: any): void {
		let donor_id = this.user_id;
		let request_id = this.bloodRequestId;
		let userId = this.user_id;
	
		this.bloodRequestService.rejectBloodRequest({donor_id, userId, request_id} as BloodRequest)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	openModal(content: any, $event: any): void {

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);

		
	}

    closeRequest($event: any, ratingAndReview: any): void {
		let request_id = this.bloodRequestId;
		let userId = this.user_id;
		let status = 2;

		this.modelref?.close();
	
		this.bloodRequestService.updateBloodRequest({userId, status, request_id} as BloodRequest)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					this.modelref = this.modalService.open(ratingAndReview, { backdrop: 'static', keyboard: false });

				  }, 4000); 
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	submitReview($event: any) {
		let userId = this.user_id;
		let request_id = this.bloodRequestId;
		let donor_id = this.user_id;
		let rating = this.rating;
		let review = this.review;
		const comments = this.selectedComments;
	

	
		this.bloodRequestService.reviewBloodRequest({userId, request_id, donor_id, rating, review, comments} as BloodRequest)
		// review
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}
