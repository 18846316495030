<main>
	<section>
	  	<div class="rad-body-wrapper">
			<div class="container-fluid">
				<div class="white-bg">
					<div class="row mt-4">
						<h3>All Blood Requests</h3>
					  </div>
				</div>
				<div class="white-bg">
					<div class="row mt-lg-4 mt-2">
						
						<div class="col-lg-4 col-md-6 mb-4" *ngFor="let bloodRequest of bloodRequestList;let i = index">
						  <div class="card">
							<div class="card-body p-3">
							  <div class="d-flex mt-n2">
								<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
								  <img alt="Pic" [src]="bloodRequest.profile_picture" id="imgPlacement" [hidden]="!bloodRequest.profile_picture" width="50" height="50" />
								</div>
								<div class="ms-3 my-auto widht-def">
								  <h5 class="mb-0">{{bloodRequest.patient_name}}</h5>
								</div>
							  </div>
							  <p class="text-sm mt-3">{{bloodRequest.location}}, {{bloodRequest.distance}}</p>
							  <hr class="horizontal dark">
							  <div class="row">
								<div class="col-5">
								  <h6 class="text-sm mb-0">{{bloodRequest.units}} Units</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Blood Needed</p>
								</div>
								<div class="col-3">
									<h6 class="text-sm mb-0">{{bloodRequest.blood_group}}</h6>
									<p class="text-secondary text-sm font-weight-normal mb-0">Blood Group</p>
								  </div>
								<div class="col-4 text-end">
								  <h6 class="text-sm mb-0">{{bloodRequest.date_filter}}</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Required date</p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
  				</div>
			</div>
	  	</div>
	</section>
</main>