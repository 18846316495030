<body class="g-sidenav-show bg-gray-200">
  	<div class="main-content position-relative max-height-vh-100 h-100 main-content-width">
    	<div class="container-fluid px-2 px-md-4" style="margin-top: 60px;" *ngIf="detailedList">
      		<div class="card card-body mx-3 mx-md-4 mt-n6">
				<section class="py-3">
					<div class="row mb-4 mb-md-0">
						<div class="col-md-8 me-auto my-auto text-left"></div>
						<div class="col-lg-4 col-md-12 my-auto text-end">
							<button (click)="openDoctorAdd(addDoctorContent)" type="button" class="btn bg-gradient-primary mb-0 mt-0 mt-md-n9 mt-lg-0" fdprocessedid="1lv6t">
								<i class="material-icons text-white position-relative text-md pe-2">add</i>Add New
							</button>
						</div>
					</div>
					<div class="row mt-lg-4 mt-2" *ngIf="doctorList">
						<div class="col-lg-4 col-md-6 mb-4" *ngFor="let doctor of doctorList;let i = index" >
							<div class="card">
								<div class="card-body p-3">
									<div class="d-flex mt-n2">
										<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
											<img src="{{doctor.image}}" alt="slack_logo">
										</div>
										<div class="ms-3 my-auto">
											<h6 class="mb-0">{{doctor.name}}</h6>
										</div>
										<div class="ms-auto">
											<div class="dropdown">
												<button (click)="openDoctorEdit(updateDoctorContent, doctor.id)" class="btn btn-link text-secondary ps-0 pe-2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" fdprocessedid="itthn"><i class="fa fa-edit text-lg" aria-hidden="true"></i></button>
											</div>
										</div>
									</div>
									<p class="text-sm mt-3"> {{doctor.bio}} </p>
								</div>
							</div>
						</div>
					</div>
				</section>
      		</div>
    	</div>
  	</div>
	<ng-template #addDoctorContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Add Doctor Information</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="addDoctorForm" (ngSubmit)="addDoctor($event)" enctype='multipart/form-data'>
							<div class="login-form-group">
								<label for="email">Name <span class="required-star">*</span></label>
								<input type="text" placeholder="Name" formControlName="name" autocomplete="off" [ngClass]="{'inputError': f.name.touched && f.name.invalid && f.name.errors && f.name.errors.required }" [ngClass]="{'inputError': formErrorMessages.nameError }">
							</div>
							<div class="login-form-group">
								<label for="e_phone">Image <span class="required-star">*</span></label>
								<input type="file" #imageInput (change)="processFile(imageInput)" accept="image/*">
							</div>
							<div class="login-form-group">
								<label for="address">Department Id <span class="required-star">*</span></label>
								<input type="text" placeholder="Department Id" formControlName="department_id" autocomplete="off" [ngClass]="{'inputError': f.department_id.touched && f.department_id.invalid && f.department_id.errors && f.department_id.errors.required }" [ngClass]="{'inputError': formErrorMessages.department_idError }">
							</div>
							<div class="login-form-group">
								<label for="address">Other Details <span class="required-star">*</span></label>
								<input type="text" placeholder="Other Details" formControlName="bio" autocomplete="off" [ngClass]="{'inputError': f.bio.touched && f.bio.invalid && f.bio.errors && f.bio.errors.required }" [ngClass]="{'inputError': formErrorMessages.bioError }">
							</div>
							<div *ngIf="successMessage">
								<div class="alert alert-success" role="alert">
									{{successMessage}}
								</div>
							</div>
							<div *ngIf="errorMessage">
								<div class="alert alert-danger" role="alert">
									{{errorMessage}}
								</div>
							</div>
							<button type="submit" class="rounded-button login-cta">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="rounded-button login-cta">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #updateDoctorContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Update Doctor Information</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="updateDoctorForm" (ngSubmit)="updateDoctor($event)" enctype='multipart/form-data'>
							<div class="login-form-group">
								<label for="email">Name <span class="required-star">*</span></label>
								<input type="text" placeholder="Name" formControlName="name" autocomplete="off" [ngClass]="{'inputError': f.name.touched && f.name.invalid && f.name.errors && f.name.errors.required }" [ngClass]="{'inputError': formErrorMessages.nameError }">
							</div>
							<div class="login-form-group">
								<label for="e_phone">Image <span class="required-star">*</span></label>
								<input type="file" #imageInput (change)="processFileEdit(imageInput)" accept="image/*">
							</div>
							<div class="login-form-group">
								<label for="address">Department Id <span class="required-star">*</span></label>
								<input type="text" placeholder="Department Id" formControlName="department_id" autocomplete="off" [ngClass]="{'inputError': f.department_id.touched && f.department_id.invalid && f.department_id.errors && f.department_id.errors.required }" [ngClass]="{'inputError': formErrorMessages.department_idError }">
							</div>
							<div class="login-form-group">
								<label for="address">Other Details <span class="required-star">*</span></label>
								<input type="text" placeholder="Other Details" formControlName="bio" autocomplete="off" [ngClass]="{'inputError': f.bio.touched && f.bio.invalid && f.bio.errors && f.bio.errors.required }" [ngClass]="{'inputError': formErrorMessages.bioError }">
							</div>
							<div *ngIf="successMessage">
								<div class="alert alert-success" role="alert">
									{{successMessage}}
								</div>
							</div>
							<div *ngIf="errorMessage">
								<div class="alert alert-danger" role="alert">
									{{errorMessage}}
								</div>
							</div>
							<button type="submit" class="rounded-button login-cta">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="rounded-button login-cta">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>