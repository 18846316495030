<body class="g-sidenav-show bg-gray-200">
  	<div class="main-content position-relative max-height-vh-100 h-100 main-content-width">
		<nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true" *ngIf="detailedList">
			<div class="container-fluid py-1 px-3 navb">
			  	<div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
					<div class="ms-md-auto pe-md-3 d-flex align-items-center">
					  	<div class="help">
							<div class="form-check form-switch ps-0 switch-chk">
								<input class="form-check-input ms-auto switch-chk-input" [checked] = "detailedList.details.is_appointment" type="checkbox" id="flexSwitchCheckDefault3" (change)="updateAppointmentSetting($event)">
								<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3" *ngIf="!detailedList.details.is_appointment">Enable</label>
								<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3" *ngIf="detailedList.details.is_appointment">Disable</label>
							</div>
					  	</div>
					</div>
			  	</div>
			</div>
	  	</nav>
    	<div class="container-fluid px-2 px-md-4" *ngIf="detailedList">
      		<div class="card card-body mx-3 mx-md-4 mt-n6" style="margin-top: 20px !important;">
          		<div class="row" *ngIf="detailedList.details.is_appointment == 1">
             		<div class="col-6">
              			<div class="card card-plain h-100">
							<div class="card-body p-3 profile-para">
								<div class="login-container">
									<div class="login-form">
										<div class="login-form-inner" style="max-width: 100%;">
											<form [formGroup]="aptSettingForm" (ngSubmit)="createAptSetting($event)" class="appointment-form">
												<div class="login-form-group">
													<label for="email">Invitees can schedule...</label>
													<input type="number" placeholder="Days" formControlName="future_days" autocomplete="off"> <span>into the future</span>
												</div>
												<div class="login-form-group">
													<label for="email">Slot Time Duration (In Minutes)</label>
													<select formControlName="slot_duration" (change)="checkSlot($event)">
														<option value="">--select slot time--</option>
														<option value="15">15</option>
														<option value="30">30</option>
														<option value="60">60</option>
													</select>
												</div>
												<div class="login-form-group" *ngIf="is_slot_time">
													<label for="email">Working Hours</label>
													<table>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'SUN')">
																<label class="" for="flexSwitchCheckDefault3">  SUN</label>
															</td>
															<td *ngIf="sunUTd">Unavailable</td>
															<td *ngIf="sunTd">
																<input (change)="timeToChange($event, 'SUN')" id="SUNTo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'SUN')" type="time" id="SUNFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr><br><br>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'MON')">
																<label class="" for="flexSwitchCheckDefault3">  MON</label>
															</td>
															<td *ngIf="monUTd">Unavailable</td>
															<td *ngIf="monTd">
																<input (change)="timeToChange($event, 'MON')" id="MONTo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'MON')" type="time" id="MONFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr><br><br>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'TUE')">
																<label class="" for="flexSwitchCheckDefault3">  TUE</label>
															</td>
															<td *ngIf="tueUTd">Unavailable</td>
															<td *ngIf="tueTd">
																<input (change)="timeToChange($event, 'TUE')" id="TUETo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'TUE')" type="time" id="TUEFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr><br><br>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'WED')">
																<label class="" for="flexSwitchCheckDefault3">  WED</label>
															</td>
															<td *ngIf="wedUTd">Unavailable</td>
															<td *ngIf="wedTd">
																<input (change)="timeToChange($event, 'WED')" id="WEDTo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'WED')" type="time" id="WEDFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr><br><br>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'THU')">
																<label class="" for="flexSwitchCheckDefault3">  THU</label>
															</td>
															<td *ngIf="thuUTd">Unavailable</td>
															<td *ngIf="thuTd">
																<input (change)="timeToChange($event, 'THU')" id="THUTo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'THU')" type="time" id="THUFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr><br><br>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'FRI')">
																<label class="" for="flexSwitchCheckDefault3">  FRI</label>
															</td>
															<td *ngIf="friUTd">Unavailable</td>
															<td *ngIf="friTd">
																<input (change)="timeToChange($event, 'FRI')" id="FRITo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'FRI')" type="time" id="FRIFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr><br><br>
														<tr>
															<td>
																<input type="checkbox" (change)="dayEvent($event, 'SAT')">
																<label class="" for="flexSwitchCheckDefault3">  SAT</label>
															</td>
															<td *ngIf="satUTd">Unavailable</td>
															<td *ngIf="satTd">
																<input (change)="timeToChange($event, 'SAT')" id="SATTo" type="time" value="09:00">
																<span> - </span>
																<input (change)="timeFromChange($event, 'SAT')" type="time" id="SATFrom" value="17:00">
																<i class="fa fa-times"></i>
															</td>
															<td>
																<i class="fa fa-plus"></i>
															</td>
														</tr>
													</table>
													<button type="submit" class="rounded-button login-cta">Submit</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
              			</div>
            		</div>
          		</div>
      		</div>
    	</div>
		<div class="container-fluid px-2 px-md-4" *ngIf="detailedList">
			<img *ngIf="detailedList.details.is_appointment == 0" src="https://resources.donorzilla.com/statics/images/loading.png" alt="profile_image" style="width: 80% !important;margin-left: 10%;height: 500px; object-fit: contain;" class="w-100 border-radius-lg shadow-sm">
	  	</div>
  	</div>