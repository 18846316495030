import { Component, OnInit, ViewEncapsulation  } from '@angular/core';

import { ArticleService } from "../services/article.service";
import { ArticleCategoryService } from "../services/articleCategory.service";

import { Article } from "../models/article";

@Component({
	selector: 'app-article-list',
	templateUrl: './article-list.component.html',
	styleUrls: [
		'./article-list.component.css'
	],
	encapsulation: ViewEncapsulation.None,
})
export class ArticleListComponent implements OnInit {
	
	allArticleList: any;
	articleList: any;
	articleCategoryList: any;
	articleCategory: any;
	totalcounter: any;
	start: number = 0;
	limit: number = 10;
	page: number = 0;

	constructor(
		private articleService: ArticleService,
		private articleCategoryService: ArticleCategoryService,
		
	) { }

	ngOnInit(): void {
		this.getAllArticle();
	}

	paginationChange(page: any): void{
		this.page = page;
		this.getArticlePagination();
	}

	getAllArticle(): void {
		this.articleService.getAllArticle()
			.subscribe(
				data => {
					this.allArticleList = data.data;
					this.totalcounter = Math.floor(this.allArticleList.length / 10 ) + 1;
				}
			);
		
		this.getArticlePagination();

		this.articleCategoryService.getAllArticleCategory()
		.subscribe(
			data => {
				this.articleCategoryList = data.data;
			}
		);
		this.articleCategoryService.getArticleCategoryWithCount()
		.subscribe(
			data => {
				this.articleCategory = data.data;
			}
		);
	}

	getArticlePagination(): void{
		this.start = (this.page * this.limit);
		this.articleService.getAllArticlePaginate(this.start, this.limit)
			.subscribe(
				data => {
					this.articleList = data.data;
				}
			);
	}

	filterArticle(): void {
		let title = $('#s').val();
		let category_id = $('#c').val();
		
		this.articleService.getFilterArticle({ title, category_id } as Article)
			.subscribe(
				data => {
					if (data.status == 200) {
						this.articleList = data.data;
					} else{
						this.articleList = '';
					}
				}
			);
	}

	filterArticleCategory(category_id: any): void {
		let title = '';
		this.articleService.getFilterArticle({ title, category_id } as Article)
			.subscribe(
				data => {
					if (data.status == 200) {
						this.articleList = data.data;
					} else{
						this.articleList = '';
					}
				}
			);
	}
}