import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { BusinessMasterService } from "../services/businessMaster.service";
import { BusinessMaster } from "../models/businessMaster";

import { AmenityService } from "../services/amenity.service";
import { Amenity } from "../models/amenity";

import { DepartmentService } from "../services/department.service";
import { Department } from "../models/department";

@Component({
  selector: "app-public-profile",
  templateUrl: "./public-profile.component.html",
  styleUrls: ["./public-profile.component.css"],
})
export class PublicProfileComponent implements OnInit {
  pId: any;
  type: any;
  detailedList: any;
  imageList: any;
  b_id: any;
  user_id: any;
  role_type: any;
  business_id: any;
  amenityMapList: any;
  departmentMapList: any;

  constructor(
    private route: ActivatedRoute,
    private businessMasterService: BusinessMasterService,
    private amenityService: AmenityService,
    private departmentService: DepartmentService,

  ) {}

  ngOnInit(): void {
    var currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      console.log(currentUser);

      var userArray = JSON.parse(currentUser);
      this.user_id = userArray.data.id;
      this.b_id = userArray.data.business_id;
      this.role_type = userArray.data.role_type;
      if (!this.role_type) {
        return;
      }
	  this.getRoleDetailsById(this.b_id);
	  this.getGalleryImagesById(this.b_id);
    this.getAmenityByBusinessId(this.b_id);
    this.getDepartmentByBusinessId(this.b_id);
    }
  }

  getRoleDetailsById(b_id: any): void {
    this.businessMasterService
      .getBusinessById({ b_id } as BusinessMaster)
      .subscribe((data) => {
        this.detailedList = data.data;
        this.type = data.data.type;
      });
  }

  getGalleryImagesById(b_id: any): void {
    this.businessMasterService
      .getGalleryImagesById({ b_id } as BusinessMaster)
      .subscribe((data) => {
        this.imageList = data.data;
      });
  }

  getAmenityByBusinessId(b_id: any): void {
      this.amenityService
        .getAmenityByBusinessId({ b_id } as Amenity)
        .subscribe((data) => {
          this.amenityMapList = data.data;
        });
    }

    getDepartmentByBusinessId(b_id: any): void {
        this.departmentService
          .getDepartmentByBusinessId({ b_id } as Department)
          .subscribe((data) => {
            this.departmentMapList = data.data;
          });
      }
}
