import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DonorRequestDetailsComponent } from './donor-request-details.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'donor-request-details/:id',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: DonorRequestDetailsComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,

      showBusinessHeader: true,
      showBusinessSidebar: true,
      showBusinessFooter: true,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DonorRequestDetailsRoutingModule { }
