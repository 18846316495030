<main>
	<section>
	  	<div class="rad-body-wrapper">
			<div class="container-fluid">
				<div class="white-bg">
					<div class="row mt-4">
						<h3>Find & Book Appointment</h3>
						  <div class="col-md-2 col-sm-6 col-6" *ngFor="let item of menuItems">
							<div class="card">
								<div class="card-body text-center icon-23">
									<i [class] = "item.icon" aria-hidden="true"></i>
									<h5><a [href]="item.path">{{item.name}}</a></h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="white-bg">
					<div class="row mt-lg-4 mt-2">
						<h3 class="text-justify">Recent Requests <span class="btn-dashboard newds"><a class="contact__btn" href="#">View All</a></span></h3>
						<div class="input-group input-group-outline" style="margin-bottom: 30px;">
							<input type="text" class="form-control" placeholder="Search...">
							<button type="button" class="gallerybtn">Search</button>
						</div>
						<div class="col-lg-4 col-md-6 mb-4" *ngFor="let recentRequest of recentRequestsList;let i = index">
						  <div class="card">
							<div class="card-body p-3">
							    <div class="d-flex mt-n2">
									<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
										<img alt="Pic" [src]="recentRequest.profile_picture" id="imgPlacement" [hidden]="!recentRequest.profile_picture" width="50" height="50" />
									</div>
									<div class="ms-3 my-auto widht-def">
										<h5 class="mb-0"><a href="donor-request-details/{{recentRequest.id}}"> {{recentRequest.patient_name}}</a></h5>
									</div>
									<div class="ms-auto">
										<div class="btn-dashboard newds"><a (click)="openDonateNowModal(addDonateNowModal, recentRequest.id)">Donate Now</a></div>
									</div>
								</div>
							  	<p class="text-sm mt-3">{{recentRequest.location}}, {{recentRequest.distance}}</p>
							  	<hr class="horizontal dark">
								
							  	<div class="row">
									<div class="col-4">
								    <h6 class="text-sm mb-0">{{recentRequest.units}} Units</h6>
								    <p class="text-secondary text-sm font-weight-normal mb-0">Blood Needed</p>
								</div>

								<div class="col-4">
									<h6 class="text-sm mb-0">{{recentRequest.blood_group}}</h6>
									<p class="text-secondary text-sm font-weight-normal mb-0">Blood Group</p>
								</div>

								<div class="col-4 text-end">
								  <h6 class="text-sm mb-0">{{recentRequest.date_filter}}</h6>
								  <p class="text-secondary text-sm font-weight-normal mb-0">Required date</p>
								</div>
							</div>
						</div>
					</div>
				</div>			
			</div>
  				</div>
				  <div class="white-bg">
					<div class="row mt-lg-4 mt-2">
						<h3>My Requests</h3>
						<div class="col-lg-4 col-md-6 mb-4" *ngFor="let myRequest of myRequestsList;let i = index">
							<div class="card">
								<div class="card-body p-3">
								  <div class="d-flex mt-n2">
									<div class="avatar avatar-xl bg-gradient-dark border-radius-xl p-2 mt-n4">
									  <img alt="Pic" [src]="myRequest.profile_picture" id="imgPlacement" [hidden]="!myRequest.profile_picture" width="50" height="50" />
									</div>
									<div class="ms-3 my-auto">
									  <h5 class="mb-0 "><a class="black" href="donor-request-details/{{myRequest.id}}"> {{myRequest.patient_name}}</a></h5>
									</div>
								  </div>
								  <p class="text-sm mt-3">{{myRequest.location}}, {{myRequest.distance}}</p>
								  <hr class="horizontal dark">
								  <div class="row">
									<div class="col-5">
									  <h6 class="text-sm mb-0">{{myRequest.units}} Units</h6>
									  <p class="text-secondary text-sm font-weight-normal mb-0">Blood Needed</p>
									</div>
									<div class="col-3">
										<h6 class="text-sm mb-0">{{myRequest.blood_group}}</h6>
										<p class="text-secondary text-sm font-weight-normal mb-0">Blood Group</p>
									  </div>
									<div class="col-4 text-end">
									  <h6 class="text-sm mb-0">{{myRequest.date_filter}}</h6>
									  <p class="text-secondary text-sm font-weight-normal mb-0">Required date</p>
									</div>
								  </div>
								</div>
							  </div>
						</div>
					  </div>
  				</div>
			</div>
	  	</div>
	</section>
</main>

<ng-template #addDonateNowModal let-modal>
	<div class="mdl-middle">
	  <form (ngSubmit)="onSubmit($event)">
		<h5>Do you have any of the following disease<i class="fa fa-times" aria-hidden="true"></i></h5>
		<div class="field">
			<div class="check-text-inner" *ngFor="let disease of diseases; let i = index">
			  <div class="disease-row">
				<p class="disease-question">{{ disease.question }}</p>
				<input
					type="checkbox"
					class="disease-checkbox"
					[value]="disease.id"
					(change)="onCheckboxChange($event, disease.question)"
					/>
			  </div>
			</div>
		  </div>
		<button
		  type="submit"
		  class="btn bg-gradient-primary wd-25 submit-btn-gradient"
		>
		  Submit
		</button>
	  </form>
	</div>
</ng-template>
  
