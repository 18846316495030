import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  center: google.maps.LatLngLiteral = { lat: 28.63576000, lng: 77.22445000 };
  
  showHeader = false;
  showSidebar = false;
  showFooter = false;

  showBusinessHeader = false;
  showBusinessSidebar = false;
  showBusinessFooter = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(!this.isEmpty(this.activatedRoute.firstChild?.snapshot.data)){
          this.showHeader = this.activatedRoute.firstChild?.snapshot.data.showHeader !== false;
          this.showSidebar = this.activatedRoute.firstChild?.snapshot.data.showSidebar !== false;
          this.showFooter = this.activatedRoute.firstChild?.snapshot.data.showFooter !== false;
  
          this.showBusinessHeader = this.activatedRoute.firstChild?.snapshot.data.showBusinessHeader !== false;
          this.showBusinessSidebar = this.activatedRoute.firstChild?.snapshot.data.showBusinessSidebar !== false;
          this.showBusinessFooter = this.activatedRoute.firstChild?.snapshot.data.showBusinessFooter !== false;
        } else{
          this.showHeader = true;
          this.showSidebar = true;
          this.showFooter = true;

          this.showBusinessHeader = false;
          this.showBusinessSidebar = false;
          this.showBusinessFooter = false;
        }
      }
    });
  }

  isEmpty(object: any) {  
    return Object.keys(object).length === 0
  }
}
