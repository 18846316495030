<main>
	<section>
	  	<div class="rad-body-wrapper">
			<div class="container-fluid">
				<div class="white-bg">
						<h3>Edit Blood Request</h3>
				</div>
				<div class="white-bg">
					<form [formGroup]="updateBloodRequestForm" (ngSubmit)="updateBloodRequestDetails($event)" enctype='multipart/form-data'>
					<div class="row">
						<div class="col-6">
							<div class="requestblood-input mb-3">
								<label for="Select Blood Type" class="ms-0">Select Blood Type</label>
								<select class="form-control mt-2" formControlName="blood_type">
									<option value="" selected="selected">Select Blood Type</option>
									<option value="{{bloodType.name}}" *ngFor="let bloodType of bloodTypeList;let i = index">{{bloodType.name}}</option>
								</select>
							</div>
						</div>
						<div class="col-6">
							<div class="requestblood-input mb-3">
							  <label>Patient Name</label>
							  <input class="form-control mt-2" type="text" formControlName="patient_name" autocomplete="off">
							</div>
						</div>
						<div class="col-6">
							<div class="requestblood-input mb-3">
								<label for="Select Blood Group" class="ms-0">Select Blood Group</label>
								<select class="form-control mt-2" formControlName="blood_group">
									<option value="" selected="selected">Select Blood Group</option>
									<option>A+</option>
									<option>A-</option>
									<option>B+</option>
									<option>B-</option>
									<option>AB+</option>
									<option>AB-</option>
									<option>O+</option>
									<option>O-</option>
								  </select>
							</div>
						</div>
						<div class="col-6">
							<div class="requestblood-input mb-3">
							  <label>Phone Number</label>
							  <input class="form-control mt-2" type="tel" formControlName="phone_number">
							</div>
						</div>
						<div class="col-6">
						  <div class="requestblood-input mb-3">
							<label>Require Date</label>
							<input class="form-control mt-2 datetimepicker flatpickr-input active" type="date" formControlName="required_date">
						  </div>
						</div>
						<div class="col-6">
							<div class="requestblood-input mb-3">
								<label for="Select Unit" class="ms-0">Select Unit</label>
								<select class="form-control mt-2" formControlName="units">
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
									<option>6</option>
									<option>7</option>
									<option>8</option>
								  </select>
							</div>
						</div>
						<div class="col-6">
							<div class="requestblood-input mb-3">
							  <label>Please Select Location</label>
							  <input class="form-control mt-2" type="text" formControlName="location">
							</div>
						</div>
						<div class="col-6">
							<div class="requestblood-input mb-3">
							  <label>Additional Note to Donors</label>
							  <input class="form-control mt-2" type="text" formControlName="notes">
							</div>
						</div>
						<div class="col-6">
							<div class="form-check form-switch mb-3">
								<input class="form-check-input" type="checkbox" checked="" formControlName="is_critical">
								<label class="form-check-label" for="flexSwitchCheckDefault">Critical</label>
							  </div>
						</div>
						<div class="col-6">
							<div *ngIf="successMessage">
								<div class="alert alert-success" role="alert">
									{{successMessage}}
								</div>
							</div>
							<div *ngIf="errorMessage">
								<div class="alert alert-danger" role="alert">
									{{errorMessage}}
								</div>
							</div>
							<div class="requestblood-input mb-3 text-end">
							  <button type="submit" name="button" class="contact__btn">Update Request</button>
							</div>
						</div>
					</div>
				</form>
			    </div>
	  	</div>
		</div>
	</section>
</main>