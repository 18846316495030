<section>
    <header>
        <nav class="rad-navigation">
            <div class="rad-logo-container">
                <div class="row">
                    <div class="col-md-10">
                        <a href="javascript:;" class="rad-logo"><img src="https://resources.donorzilla.com/statics/images/logo.png"></a>
                    </div>
                    <div class="col-md-2">
                        <a href="javascript:;" class="rad-toggle-btn pull-right mobile-nav-icon"><i class="fa fa-bars"></i></a>
                    </div>
                </div>
            </div>
            <div class="rad-top-nav-container">
                <p><strong>Welcome, </strong></p>
                <span class="select_wrap welcome-header-nav">
                    <select *ngIf="businessList" (change)="onChange($event)">
                        <option value="{{business.id}}" *ngFor="let business of businessList;let i = index" [selected]="business.id == this.b_id">{{business.name}}</option>
                    </select>
                </span>
                <ul class="links">
                    <li class="rad-dropdown"><a class="rad-menu-item" href="javascript:;"><i class="fa fa-bell" aria-hidden="true"><span class="rad-menu-badge">{{notificationCounter}}</span></i></a></li>
                    <li class="rad-dropdown"><a class="rad-menu-item" href="logout"><i class="fa fa-sign-out" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </nav>
    </header>
</section>