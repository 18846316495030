import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class NewsletterService {
    private baseUrl = environment.baseURL + "newsletter/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ 'token': token ? token : '' })
        };
    }

    constructor(private http: HttpClient) {}

    addNewsletter(newsletter: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "createNewsletter", newsletter, this.getHttpOptions());
    }
}
