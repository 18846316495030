import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators, EmailValidator } from '@angular/forms';
import { Router } from '@angular/router';

import { Contact } from '../models/contact';
import { ContactService } from '../services/contact.service';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: [
		'./contact-us.component.css'
	]
})
export class ContactUsComponent implements OnInit {

	contactForm = new FormGroup({
		first_name: new FormControl('', [Validators.required]),
		last_name: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		subject: new FormControl('', [Validators.required]),
		message: new FormControl('', [Validators.required])
	});
	
	get f() {
		return this.contactForm.controls;
	}
	
	successMessage = '';
	errorMessage = '';
	formErrorMessages = {
		firstNameError: '',
		lastNameError: '',
		emailError: '',
		subjectError: '',
		messageError: ''
	};

	constructor(
		private title: Title,
		private meta: Meta,
		private contactService: ContactService,
		private router: Router
	) {}

	ngOnInit()
	{ 
		this.title.setTitle('Contact us | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:"Get in touch with DonorZilla for support, inquiries, or assistance in finding blood donors, hospitals, doctors, clinics, and path labs. We're here to help you with all your healthcare needs."
		}); 
	}

	addContact($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let first_name = this.contactForm.value.first_name;
		let last_name = this.contactForm.value.last_name;
		let email = this.contactForm.value.email;
		let subject = this.contactForm.value.subject;
		let message = this.contactForm.value.message;

		if (!first_name || !last_name || !email || !subject || !message) {
			this.errorMessage = 'All fields are required';
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}

		if (!email.includes('@')) {
			this.errorMessage = 'Please enter a valid email containing @';
			this.successMessage = '';
			return;
		}
		this.contactService.addContact({ 
			first_name,
			last_name,
			email,
			subject,
			message 
			} as Contact)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							this.router.navigate(['']);
						}, 4000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

}
