<div class="main-content position-relative">
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
          <div class="container-fluid py-1 px-3 navb">
            <nav aria-label="breadcrumb">
                <h2 class="welcome-text">Welcome to Donorzilla</h2>
            </nav>
            <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                  <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                    <span class="select_wrap">
                        <select *ngIf="businessList" (change)="onChange($event)">
                            <option value="{{business.id}}" *ngFor="let business of businessList;let i = index" [selected]="business.id == this.b_id">{{business.name}}</option>
                        </select>
                    </span>
                     <div class="help">
                        <ul class="notification-drop">
                            <li class="item">
                                <i class="fa fa-bell notification-bell" aria-hidden="true"></i>
                                <span class="btn__badge">{{notificationCounter}}</span>
                                <span class="pulse-button"></span>
                                <div class="notificandrop">
                                    <ul>
                                        <div class="notify-head">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h3 class="head-not">Notifications</h3>
                                                </div>
                                                <div class="col-md-6">
                                                    <a href="javascript:;" (click)="updateNotification()" class="head-link">Mark all as read</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="notify-scroll">
                                            <li class="list-notification {{notification.status ? 'color-white' : 'color-grey'}}" *ngFor="let notification of notificationList;let i = index">
                                                <div class="notification-inner">
                                                    <div class="notify-text">
                                                        <p>{{notification.message}}</p>
                                                    </div>
                                                    <!-- <div class="notify-time">
                                                        <p>2 min ago</p>
                                                    </div> -->
                                                </div>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="help">
                          <a href=""><i class="fa fa-question-circle" aria-hidden="true"></i></a>
                        </div>
                    <div class="help">
                        <a href="logout"><i class="fa fa-sign-out" aria-hidden="true" title="Logout"> </i></a>
                    </div>
                  </div>
            </div>
          </div>
    </nav>
</div>