import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BloodType } from "../models/bloodType";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class BloodTypeService {
    
    private getBloodTypeUrl = environment.baseURL + "bloodType/getBloodType";
    private getAllBloodTypeUrl = environment.baseURL + "bloodType/getAllBloodType";
    private getBloodTypeByIdUrl = environment.baseURL + "bloodType/getBloodTypeById";
    private addBloodTypeUrl = environment.baseURL + "bloodType/createBloodType";
    private updateBloodTypeUrl = environment.baseURL + "bloodType/updateBloodType";
    private deleteBloodTypeUrl = environment.baseURL + "bloodType/deleteBloodType";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) {}
    
    getBloodType(params: any): Observable<BloodType> {
        return this.http
            .post<BloodType>(this.getBloodTypeUrl, params, this.getHttpOptions())
            .pipe(map((bloodTypeList) => bloodTypeList));
    }
    
    getAllBloodType(): Observable<BloodType> {
        let params = {};
        return this.http
            .post<BloodType>(this.getAllBloodTypeUrl, params, this.getHttpOptions())
            .pipe(map((bloodTypeList) => bloodTypeList));
    }
    
    getBloodTypeById(bloodTypeId: any): Observable<BloodType> {
        let bloodTypeIds = { bloodTypeId: bloodTypeId };
        return this.http
            .post<BloodType>(this.getBloodTypeByIdUrl, bloodTypeIds, this.getHttpOptions())
            .pipe(map((bloodTypeList) => bloodTypeList));
    }

    addBloodType(bloodType: BloodType): Observable<BloodType> {
        return this.http
            .post<BloodType>(this.addBloodTypeUrl, bloodType, this.getHttpOptions())
            .pipe(map((bloodType) => bloodType));
    }

    updateBloodType(bloodType: BloodType): Observable<BloodType> {
        return this.http
            .post<BloodType>(this.updateBloodTypeUrl, bloodType, this.getHttpOptions())
            .pipe(map((bloodType) => bloodType));
    }

    deleteBloodType(bloodType: BloodType): Observable<BloodType> {
        return this.http
            .post<BloodType>(this.deleteBloodTypeUrl, bloodType, this.getHttpOptions())
            .pipe(map((bloodType) => bloodType));
    }
}
