<main>
	<section>
	  <div class="rad-body-wrapper">
		<div class="container-fluid">
		  <div class="white-bg">
			<h3>Edit Profile</h3>
		  </div>
		  <div class="white-bg p-4">
			<form [formGroup]="updateDonorProfileForm" (ngSubmit)="updateprofileDetails($event)" enctype='multipart/form-data'>
			  <div class="row g-3">
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>First Name</label>
					<input class="form-control mt-2" type="text" formControlName="first_name" autocomplete="off">
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label for="blood_group">Select Blood Group</label>
					<select class="form-control mt-2" formControlName="blood_group">
					  <option value="" selected>Select Blood Group</option>
					  <option>A+</option>
					  <option>A-</option>
					  <option>B+</option>
					  <option>B-</option>
					  <option>AB+</option>
					  <option>AB-</option>
					  <option>O+</option>
					  <option>O-</option>
					</select>
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>Last Name</label>
					<input class="form-control mt-2" type="text" formControlName="last_name">
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>Date Of Birth</label>
					<input class="form-control mt-2" type="date" formControlName="date_of_birth">
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>Location</label>
					<input class="form-control mt-2" type="text" formControlName="location">
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>Gender</label>
					<select class="form-control mt-2" formControlName="gender">
					  <option value="">Select Gender</option>
					  <option value="Male">Male</option>
					  <option value="Female">Female</option>
					  <option value="Other">Other</option>
					</select>
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>Email</label>
					<input class="form-control mt-2" type="email" formControlName="email">
				  </div>
				</div>
				<div class="col-md-6 col-12">
				  <div class="requestblood-input">
					<label>About</label>
					<textarea class="form-control mt-2" formControlName="about" rows="3"></textarea>
				  </div>
				</div>
				<div class="col-12">
				  <div *ngIf="successMessage" class="alert alert-success" role="alert">
					{{successMessage}}
				  </div>
				  <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
					{{errorMessage}}
				  </div>
				</div>
				<div class="col-12 text-end">
				  <button type="submit" class="btn btn-primary">Update</button>
				</div>
			  </div>
			</form>
		  </div>
		</div>
	  </div>
	</section>
  </main>
  