<body class="bg-white">
  	<div class="logo-profile">
  		<img src="https://resources.donorzilla.com/statics/images/logo.png">
	</div>
 	<div class=content>
  		<div class="check-img">
  			<img src="https://resources.donorzilla.com/statics/images/check-mark.png">
		</div>
  		<div class="wrapper-1">
    		<div class="wrapper-2">
				<h1>THANK YOU !</h1>
				<p>Your Data Has Been Saved Successfully</p>
      			<button class="go-home">go home</button>
    		</div>
		</div>
		<footer class="footer-bottom">
			<div>Donorzilla &copy;2024, All Rights Reserved.</div>
		</footer>
	</div>
