import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { AppointmentSettingsRoutingModule } from './appointment-settings-routing.module';
import { AppointmentSettingsComponent } from './appointment-settings.component';

@NgModule({
  imports: [
    CommonModule,
    AppointmentSettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [AppointmentSettingsComponent],
  providers: [AuthGuard]
})
export class AppointmentSettingsModule { }
