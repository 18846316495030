<section class="static-hero blood-hero" id="div">
    <div class="hero-container">
        <div class="hero-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-duration="2500">
                        <div class="wpo-static-hero-inner">
                            <div class="slide-sub-title">
                                <h2>Save Lives by<br> Donating Blood  <strong>Today</strong></h2>
                            </div>
                            <div class="slide-text">
                                <p>Join DonorZilla to donate or request blood in just a few clicks.</p>
                            </div>
                         
                            <div class="slide-btn">
                                <a class="theme-btn " href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank" >Become A Blood Donor</a> </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-12" >
                        <div class="bnr-right-immg">
                            <img src="https://resources.donorzilla.com/statics/images/bdb1.png" class="bdb11" data-aos="fade-left" data-aos-duration="2500">
                            <img src="https://resources.donorzilla.com/statics/images/bdb3.png" class="bdb12" data-aos="fade-up" data-aos-duration="2500">
                            <img src="https://resources.donorzilla.com/statics/images/bdb2.png" class="bdb13" data-aos="fade-down" data-aos-duration="2500">
                          </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
    <div class="line-shape-1">
        <img src="business-images/slider/line-1.png" alt="" />
    </div>
</section>
<div class="callback-section primary-bg-callback">
    <div class="container">
        <div class="callback-inner">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <figure class="callback-image">
                        <img src="https://resources.donorzilla.com/statics/images/app-bd.png" alt="">
                    </figure>
                </div>
                <div class="col-md-6">
                    <div class="callback-content">
                        <div class="sub-title">CHOOSE YOUR DEVICE PLATFORM</div>
                        <h2 class="section-title">Download the DonorZilla App</h2>
                        <p>Register as a donor, find blood, and create or manage requests easily on the go.</p>
                        <div class="about-btn-list">
                            <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank">
                                <img src="https://resources.donorzilla.com/statics/images/android.png" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay"></div>
        </div>
    </div>
</div>
<section id="screen-shot">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="wiper">
                    <button class="wiper-button wiper-button__right">
                        <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                    </button>
                    <div class="wiper-wrapper">
                        <ul class="wiper-track">
                            <li class="wiper-item">
                                <img class="wiper__image" src="https://resources.donorzilla.com/statics/images/ap1.png" alt="background-image"/>
                            </li>
                            <li class="wiper-item active-swipe">
                                <img class="wiper__image" src="https://resources.donorzilla.com/statics/images/ap2.png" alt="background-image" />
                            </li>
                            <li class="wiper-item">
                                <img class="wiper__image" src="https://resources.donorzilla.com/statics/images/ap3.png" alt="background-image"/>
                            </li>
                            <li class="wiper-item">
                                <img class="wiper__image" src="https://resources.donorzilla.com/statics/images/ap4.png" alt="background-image" />
                            </li>
                            <li class="wiper-item">
                                <img class="wiper__image" src="https://resources.donorzilla.com/statics/images/ap5.png" alt="background-image" />
                            </li>
                        </ul>
                    </div>
                    <button class="wiper-button wiper-button__left">
                        <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="col-md-5">
                <div class="feature-listt">
                    <h2>Why Use DonorZilla App?</h2>
                    <div class="right-business-text">  
                        <ul>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i>  Easy registration as a donor.</li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i> Quick search for nearby blood donors.</li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i>  Create or manage blood requests for loved ones.</li>
                            <li><i class="fa fa-check-square-o" aria-hidden="true"></i>Get instant notifications when a donor is found.</li>
                        </ul>     
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="become-donor">   
    <div class="container">
        <h2 class="text-center ">Become A  Blood Donor</h2>
        <p class="text-center ">Help save lives by becoming a registered donor. We notify you when someone nearby needs blood.</p>
        <div class="naccs">
            <section class="work-porcess-area section">
                <div class="process-info">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="single-process first text-center">
                                <img src="https://resources.donorzilla.com/statics/images/bdi1.png">
                                <h4>Sign Up</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process secend text-center">
                            <img src="https://resources.donorzilla.com/statics/images/bdi2.png">
                                <h4>Set Your Availability</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process thard text-center">
                            <img src="https://resources.donorzilla.com/statics/images/bdi3.png">
                                <h4>Get Notified</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process last text-center">
                                <img src="https://resources.donorzilla.com/statics/images/bdi4.png">
                                <h4> Donate</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<div class="donate-btn-center">
    <div class="container">
        <a href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank">  <img src="https://resources.donorzilla.com/statics/images/android.png" alt=""></a>
    </div>
</div>
<br>
<br>
<section id="how-it-works">
    <div class="container">
        <h2 class="text-center"> How DonorZilla Works</h2>
        <p class="text-center">Simplifying healthcare for businesses and individuals alike</p>
        <div class="how-worksss-inner">
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/bdpi1.png">
                <h3>Sign Up</h3>
                <p>Create your profile.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/bdpi2.png">
                <h3>Set Your Availability</h3>
                <p>Let us know when and where you can donate.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/bdpi3.png">
                <h3>Get Matched</h3>
                <p>We notify you when there’s a need in your area.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/bdpi4.png">
                <h3>Donate Blood</h3>
                <p>Head to the hospital and donate.</p>
            </div>
            <svg data-v-690441d2="" class="workflow__arrow active" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path data-v-690441d2="" d="M9.02928 3.03516L14.3326 8.33846M14.3326 8.33846L9.02928 13.6418M14.3326 8.33846L2.33325 8.33846" stroke="#191B1B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="how-it-wrk-space">
                <img src="https://resources.donorzilla.com/statics/images/bdpi5.png">
                <h3>Save Lives</h3>
                <p>Help patients recover faster.</p>
            </div>
        </div>
    </div>
</section>
<div class="accordion faqq">
    <h2 class="text-center">FAQs</h2><br>
    <div class="accordion-item">
        <input type="checkbox" id="accordion1">
        <label for="accordion1" class="accordion-item-title"><span class="icon"></span>What is DonorZilla?</label>
        <div class="accordion-item-desc">DonorZilla is a mobile app bridging the gap between blood donors and patients requiring transfusions. It facilitates seamless connections, allowing donors to respond to urgent blood requests and schedule donations conveniently.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion2">
        <label for="accordion2" class="accordion-item-title"><span class="icon"></span>How does DonorZilla work?</label>
        <div class="accordion-item-desc">DonorZilla utilizes a user-friendly interface where individuals in need of blood can create requests, and registered donors can view these requests based on location and blood type compatibility. Donors can then choose to respond to requests and schedule donation appointments.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion3">
        <label for="accordion3" class="accordion-item-title"><span class="icon"></span>Is DonorZilla available for both donors and recipients?</label>
        <div class="accordion-item-desc">Yes, DonorZilla is accessible to both blood donors and patients in need of blood transfusions. Donors can sign up to offer blood donations, while patients can create requests for blood donations when needed.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion4">
        <label for="accordion4" class="accordion-item-title"><span class="icon"></span>Is DonorZilla free to use?</label>
        <div class="accordion-item-desc">Yes, DonorZilla is free to download and use for both donors and recipients. The recipient and donor do not need to spend any money to use the application</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion5">
        <label for="accordion5" class="accordion-item-title"><span class="icon"></span>How can I register as a blood donor on DonorZilla?</label>
        <div class="accordion-item-desc">To register as a blood donor, simply download the DonorZilla app from the App Store or Google Play Store, create an account, and fill in your details, including blood type, location, and contact information. once all the details are filled you can accept requests or create your own.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion6">
        <label for="accordion6" class="accordion-item-title"><span class="icon"></span>What information do I need to provide as a patient requesting blood on DonorZilla?</label>
        <div class="accordion-item-desc">Patients requesting blood on DonorZilla must provide details such as blood type, quantity required, location for donation, and any other specific requirements. Also, the patient can upload medical documents for the donors to see.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion7">
        <label for="accordion7" class="accordion-item-title"><span class="icon"></span>How does DonorZilla ensure the safety and reliability of blood donations?</label>
        <div class="accordion-item-desc">DonorZilla follows strict guidelines and procedures to verify donors' eligibility, including health screenings and blood tests. Additionally, users can rate and review donors, promoting accountability within the community.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion8">
        <label for="accordion8" class="accordion-item-title"><span class="icon"></span>Can I schedule blood donation appointments through DonorZilla?</label>
        <div class="accordion-item-desc">Yes, donors can schedule blood donation appointments directly through the app based on their availability and the needs of patients. these appointments can be set directly by the patient attendee and the donor using the in app chat in donorZilla</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion9">
        <label for="accordion9" class="accordion-item-title"><span class="icon"></span>Is my personal information secure on DonorZilla?</label>
        <div class="accordion-item-desc">DonorZilla takes user privacy and data security seriously, employing encryption and other measures to safeguard personal information.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion10">
        <label for="accordion10" class="accordion-item-title"><span class="icon"></span>What happens after I donate blood through DonorZilla?</label>
        <div class="accordion-item-desc">After donating blood, donors receive confirmation and appreciation through the app. The donated blood is then allocated to patients in need based on compatibility and urgency.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion11">
        <label for="accordion11" class="accordion-item-title"><span class="icon"></span>How can I spread awareness about DonorZilla in my community?</label>
        <div class="accordion-item-desc">You can spread awareness about DonorZilla by sharing the app with friends, and family, and on social media platforms. Additionally, organizing blood donation drives or partnering with local organizations can help promote the app's usage.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion12">
        <label for="accordion12" class="accordion-item-title"><span class="icon"></span>Is DonorZilla available in multiple languages?</label>
        <div class="accordion-item-desc">At the moment, DonorZilla is available in English, but efforts are underway to expand language support to reach a broader audience.</div>
    </div>
</div>
<section class="wpo-appoinment-section" data-aos="fade-up" data-aos-duration="2000">
    <div class="container">
        <div class="appoinment-wrap">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-12 col-12">
                    <div class="appoinment-text">
                        <h2>Ready to Make a Difference?</h2>
                        <p>Sign up to transform your healthcare experience.</p>
                        <div class="grow-btn">
                            <a class="theme-btn" href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" target="_blank">Become A Blood Donor</a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-12">
                    <div class="appoinment-right">
                        <img src="https://resources.donorzilla.com/statics/images/bd-bt.jpg" />
                    </div>
                </div>
            </div>
            <div class="shape-1">
                <img src="https://resources.donorzilla.com/statics/images/appoinment-shape.png" alt="" />
            </div>
        </div>
    </div>
</section>