import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
	constructor(
		private router: Router,
	) { }
	canActivate() {
		const currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			if(userArray.data.role_type != "1"){
				return true;
			} else{
				this.router.navigate(['']);
			}
		}
		return false;
	}
}
