import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DonorProfileComponent } from './donor-profile.component';

import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {
    path: 'donor-profile',
    canActivate: [AuthGuard],
    component: DonorProfileComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,
      showBusinessHeader: false,
      showBusinessSidebar: false,
      showBusinessFooter: false,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DonorProfileRoutingModule { }
