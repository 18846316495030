import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Appointment } from "../models/appointment";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class AppointmentService {
    
    private getAppointmentUrl = environment.baseURL + "appointment/getAppointment";
    private getAppointmentByIdUrl = environment.baseURL + "appointment/getAppointmentById";
    private getAppointmentByUserIdUrl = environment.baseURL + "appointment/getAppointmentByUserId";
    private getAppointmentDetailsByDoctorIdUrl = environment.baseURL + "appointment/getAppointmentDetails";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) {}
      
    getAppointment(params: any): Observable<Appointment> {
        return this.http
            .post<Appointment>(this.getAppointmentUrl, params, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }
    
    getAppointmentById(appointmentId: any): Observable<Appointment> {
        let appointmentIds = { appointmentId: appointmentId };
        return this.http
            .post<Appointment>(this.getAppointmentByIdUrl, appointmentIds, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }

    getAppointmentsByUserId(userId: any): Observable<Appointment> {
        let userIds = { userId: userId };
        return this.http
            .post<Appointment>(this.getAppointmentByUserIdUrl, userIds, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }

    getAppointmentDetailsByDoctorId(business_id: any): Observable<Appointment> {
        let params = { business_id: business_id };
        return this.http
            .post<Appointment>(this.getAppointmentDetailsByDoctorIdUrl, params, this.getHttpOptions())
            .pipe(map((appointmentList) => appointmentList));
    }
}
