import { Component } from "@angular/core";
import { CalendarOptions, EventClickArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { AppointmentService } from "../services/appointment.service";
import { Tooltip } from "bootstrap";

@Component({
  selector: "app-appointment-details",
  templateUrl: "./appointment-details.component.html",
  styleUrls: ["./appointment-details.component.css"],
})
export class AppointmentDetailsComponent {
  constructor(private appointmentService: AppointmentService) {}

  business_id: any;

  ngOnInit() {
    var currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      var userArray = JSON.parse(currentUser);
      this.business_id = userArray.data.business_id;
    }
    this.loadAppointments();
  }

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: "dayGridMonth",
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    editable: false,
    selectable: false,
    events: [],
    eventTimeFormat: {
      hour: "numeric",
      minute: "2-digit",
      meridiem: "short", 
    },
    eventClick: this.handleEventClick.bind(this),
    eventDidMount: (info) => {
      const tooltipContent = `
        <div><strong>Name:</strong> ${info.event.title}</div>
        <div><strong>Time:</strong> ${info.event.extendedProps.time}</div>
      `;

      new Tooltip(info.el, {
        title: tooltipContent,
        placement: "top",
        trigger: "hover",
        html: true,
      });
    },
    eventClassNames: ['custom-event'],  
  };

  handleEventClick(event: EventClickArg) {
    event.jsEvent.preventDefault();
  }

  loadAppointments() {
    this.appointmentService.getAppointmentDetailsByDoctorId(this.business_id).subscribe(
      (response: any) => {
        if (response.status === 200) {
          const events = response.data.map((appointment: any) => {
            //  Ensure slot_time is properly parsed and formatted with full AM/PM
            const appointmentTime = new Intl.DateTimeFormat('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
            }).format(new Date(appointment.slot_time));

            return {
              title: appointment.username,
              date: appointment.slot_time, 
              extendedProps: {
                time: appointmentTime, 
              },
            };
          });

          this.calendarOptions = { ...this.calendarOptions, events };
        } else {
          console.error("Error fetching appointments:", response.message);
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
}
