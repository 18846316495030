import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class ContactService {
    private baseUrl = environment.baseURL + "contact/";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ 'token': token ? token : '' })
        };
    }

    constructor(private http: HttpClient) {}

    addContact(contact: any): Observable<any> {
        return this.http.post(this.baseUrl + "createContact", contact, this.getHttpOptions());
    }
}
