import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BlogListComponent } from './blog-list/blog-list.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    //redirectTo: '/login',
    pathMatch: 'full'
  },
  {
      path: 'blogs',
      component: BlogListComponent,
      data: { 
        showHeader: true,
        showSidebar: true,
        showFooter: true,
        showBusinessHeader: false,
        showBusinessSidebar: false,
        showBusinessFooter: false,
       }
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
