<div class="container-fluid mt-2">
	<div class="row align-items-start flex-column" style="margin-left: 241px;">
		<div class="col-lg-4 col-sm-8">
			<div class="nav-wrapper position-relative end-0">
				<ul class="nav nav-pills nav-fill p-1" role="tablist">
					<li class="nav-item" role="presentation">
						<a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="../../examples/pages/account/settings.html" role="tab" aria-selected="false" tabindex="-1">Calendar View</a>
					</li>
					<li class="nav-item">
						<a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="../../examples/pages/account/billing.html" role="tab" aria-selected="false">List View</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid my-3">
	<div class="row mt-4" style="margin-left: 241px;">	
		<div class="col-lg-12 col-12 mt-md-0 mt-4">
			<div class="card h-100 mb-4">
				<div class="card-header pb-0 px-3">
					<div class="row">
						<div class="col-md-6">
							<h2 class="fc-toolbar-title">Your Schedule</h2>
						</div>
					</div>
				</div>
				<div class="card-body pt-4 p-3">
					<h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Today's</h6>
					<ul class="list-group">
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_more</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Netflix</h6>
									<span class="text-xs">27 March 2020, at 12:30 PM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">11:00 AM</div>
						</li>
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_less</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Apple</h6>
									<span class="text-xs">27 March 2020, at 04:30 AM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">05:00 PM</div>
						</li>
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_less</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Apple</h6>
									<span class="text-xs">27 March 2020, at 04:30 AM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">05:00 PM</div>
						</li>
					</ul>
					<h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">22 Oct 2024</h6>
					<ul class="list-group">
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_more</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Netflix</h6>
									<span class="text-xs">27 March 2020, at 12:30 PM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">11:00 AM</div>
						</li>
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_less</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Apple</h6>
									<span class="text-xs">27 March 2020, at 04:30 AM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">05:00 PM</div>
						</li>
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_more</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Netflix</h6>
									<span class="text-xs">27 March 2020, at 12:30 PM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">11:00 AM</div>
						</li>
						<li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
							<div class="d-flex align-items-center">
								<button class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"><i class="material-symbols-rounded text-lg">expand_less</i></button>
								<div class="d-flex flex-column">
									<h6 class="mb-1 text-dark text-sm">Apple</h6>
									<span class="text-xs">27 March 2020, at 04:30 AM</span>
								</div>
							</div>
							<div class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">05:00 PM</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid py-2">
	<div class="row" style="margin-left: 241px;">
		<div class="col-xl-12">
			<div class="card card-calendar">
				<div class="card-body p-3">
					<div class="calendar" data-bs-toggle="calendar" id="calendar"></div>
				</div>
			</div>
		</div>
	</div>
</div>