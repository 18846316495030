import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Newsletter } from '../models/newsletter';
import { NewsletterService } from '../services/newsletter.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: [
		'./home.component.css'
	]
})
export class HomeComponent implements OnInit {
	
	newsletterForm = new FormGroup({
		email: new FormControl('', [Validators.required])
	});
	
	get f() {
		return this.newsletterForm.controls;
	}
	
	successMessage = '';
	errorMessage = '';
	formErrorMessages = {
		emailError: ''
	};

	constructor(
		private title: Title,
		private meta: Meta,
		private newsletterService: NewsletterService,
		private router: Router
	) {}

	ngOnInit()
	{ 
		this.title.setTitle('Find Hospitals, Doctors, and Blood Donors Near You | Book an Appointment| DonorZilla'); 

		this.meta.updateTag(
		{
			name:'keywords',
			content:'find a hospital, find a doctor, find a blood donors'
		}); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Looking to find hospitals, doctors, or blood donors near you? Discover trusted healthcare services and blood donors with DonorZilla. Easily connect with medical professionals and save lives today!'
		}); 
	}

	addNewsletter($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let email = this.newsletterForm.value.email;
		
		if (!email) {
			this.errorMessage = 'All fields are required';
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.newsletterService.addNewsletter({ 
			email
			} as Newsletter)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						this.successMessage = data.message;
						setTimeout(() => {
							window.location.reload()
						}, 4000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}
}
