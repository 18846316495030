import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Slot } from "../models/slot";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class SlotService {
	
	private updateSlotUrl = environment.baseURL + "slotMaster/updateSlot";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	updateSlot(slot: Slot): Observable<Slot> {
		return this.http
		.post<Slot>(this.updateSlotUrl, slot, this.httpOptions)
		.pipe(
			map((slot) => {
			return slot;
			})
		);
	}
}
