import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Notification } from "../models/notification";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class NotificationService {
	
	private getNotificationByUserIdUrl = environment.baseURL + "notification/getNotificationByUserId";

	private getNotificationCounterByUserIdUrl = environment.baseURL + "notification/getNotificationCounterByUserId";

	private updateNotificationByUserIdUrl = environment.baseURL + "notification/updateNotificationByUserId";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}

	getNotificationByUserId(notification: Notification): Observable<Notification> {
		return this.http
		.post<Notification>(this.getNotificationByUserIdUrl, notification, this.httpOptions)
		.pipe(
			map((notification) => {
			return notification;
			})
		);
	}

	getNotificationCounterByUserId(notification: Notification): Observable<Notification> {
		return this.http
		.post<Notification>(this.getNotificationCounterByUserIdUrl, notification, this.httpOptions)
		.pipe(
			map((notification) => {
			return notification;
			})
		);
	}

	updateNotificationByUserId(notification: Notification): Observable<Notification> {
		return this.http
		.post<Notification>(this.updateNotificationByUserIdUrl, notification, this.httpOptions)
		.pipe(
			map((notification) => {
			return notification;
			})
		);
	}

}
