<section id="donor-profile">
  <div class="container">
    <div class="prof-top-img">
      <img src="https://resources.donorzilla.com/statics/images/prof.png" />
      <div class="profile-imaghe">
        <img class="profile-img-header" src="{{detailedList.details?.profile_picture}}" *ngIf="detailedList.details?.profile_picture" alt="profile_image">
        <img class="profile-img-header" src="https://resources.donorzilla.com/statics/images/logo-short.png" *ngIf="!detailedList.details?.profile_picture" alt="profile_image">
      </div>
    </div>

    <div class="row">
      <div class="col-md-9">
        <div class="left-side-inner">
          <h2>{{ detailedList.details?.name }}</h2>
          <span>{{ detailedList?.type }}</span>
          <!-- 
                    <span>4/5 <img src="https://resources.donorzilla.com/statics/images/star.png" /></span> -->

          <br />
          <p class="text-prof">{{ detailedList.details?.about }}</p>

          <div class="prof-inr-btn">
            <a href="tel:{{ detailedList.details?.phone }}" class="first-call-us"
              >Call Us</a
            >
            <a
              href="https://maps.google.com/?q={{
                detailedList.details?.latitude
              }},{{ detailedList.details?.longitude }}&z=8"
              target="_blank"
              class="second-direction"
              >Get Direction</a
            >
          </div>

          <br />

          <div class="tabs-container">
            <div class="tabs tb-tab">
              <div class="tab-links">
                <button class="tab-link active" data-tab="tab-1">
                  Details
                </button>
                <button class="tab-link" data-tab="tab-2">Services</button>
                <button class="tab-link" data-tab="tab-3">Gallery</button>
              </div>

              <div class="tab-content active" id="tab-1">
                <div class="tab-inner-tab">
                  <div class="row">
                    <div class="col-md-4">
                      <h2>Email</h2>
                      <p>{{ detailedList.details?.email }}</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Phone</h2>
                      <p>{{ detailedList.details?.phone }}</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Emergency Phone</h2>
                      <p>{{ detailedList.details?.e_phone }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <h2>Contact Person Name</h2>
                      <p>{{ detailedList.details?.c_name }}</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Contact Person Email</h2>
                      <p>{{ detailedList.details?.c_email }}</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Contact Person Phone</h2>
                      <p>{{ detailedList.details?.c_phone }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-content" id="tab-2">
                <div class="tab-inner-tab">
                  <div class="row">
                    <div class="col-md-12">
                      <h2>Amenity</h2>
                      <div class="public-profile-services">
                        <div
                          class="col-lg-2 col-md-4 col-sm-3 col-4 d-flex flex-column align-items-center text-center"
                          *ngFor="
                            let amenityMap of amenityMapList;
                            let i = index
                          "
                        >
                          <img
                            [src]="amenityMap.image"
                            class="img-fluid"
                            style="width: 40px; height: 40px"
                          />
                          <span
                            class="mt-1"
                           
                            >{{ amenityMap.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                  
                  </div>
                  <br>
                  <br>
                  <br>
                  <div class="row">
                    <div class="col-md-12">
                      <h2>Department</h2>
                      <div class="public-profile-services"> 
                        <div
                          class="col-lg-2 col-md-4 col-sm-4 col-6 d-flex flex-column align-items-center text-center"
                          *ngFor="let departmentMap of departmentMapList; let i = index"
                        >
                          <img [src]="departmentMap.image" class="img-fluid" style="width: 40px; height: 40px;" />
                          <span class="mt-1">{{ departmentMap.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-content" id="tab-3">
                <div class="tab-inner-tab">
                  <div class="row">
                    <div class="col-md-4" *ngFor="let image of imageList">
                      <img [src]="image.image" style="width: 100px; height: 100px;"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="right-side-text">
          <h2>
            Why List Your Business<br />
            on <strong>DonorZilla</strong>
          </h2>

          <div class="icon-with-text">
            <img
              src="https://resources.donorzilla.com/statics/images/pro-1.png"
            />
            <p>Increased visibility in the healthcare industry</p>

            <img
              src="https://resources.donorzilla.com/statics/images/pro-2.png"
            />
            <p>Increased visibility in the healthcare industry</p>

            <img
              src="https://resources.donorzilla.com/statics/images/pro-3.png"
            />
            <p>Increased visibility in the healthcare industry</p>
          </div>

          <div class="register-btnn">
            <a href="register/">Register Now</a>
          </div>

          <div class="links-imp">
            <h2>Important Links</h2>

            <ul>
              <li><a href="{{detailedList.details.facebook_link}}">Facebook Link</a></li>
              <li><a href="{{detailedList.details.website_link}}">Website Link</a></li>
              <li><a href="{{detailedList.details.instagram_link}}">Offers Link</a></li>
              <li><a href="{{detailedList.details.twitter_link}}">Mail ID</a></li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
