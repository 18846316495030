<main>
	<section>
		<div class="rad-body-wrapper bg-chnage">
			<div class="container-fluid">
				<div class="white-bgg">
					<div class="uploadimg">
						<form [formGroup]="addBusinessForm" (ngSubmit)="addBusinessImages($event)" class="form" encType="multipart/form-data">
							<div class="row">
								<div class="col-lg-5 col-md-12 col-xs-12">
									<div class="business-gallery-1 spclick">
										<input #imageInput type="file" id="file-input" class="hidden-file" accept="image/*" (change)="processFile(imageInput)" multiple="">
									</div>
									<button type="submit" value="Submit" class="gallerybtn">Upload</button>
								</div>
								<div class="col-lg-7 col-md-12 col-xs-12">
									<div class="business-gallery-2">
										<div class="row">
											<div class="col-lg-4 col-md-12 col-xs-12" *ngFor='let url of images'>
												<div class="business-gallery-rightside">
													<img [src]="url" class="imgprev">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="row">
						<div class="col-lg-12 col-md-12 col-xs-12">
							<div class="business-gallery-3">
								<h2 class="head-recent">Recent Uploaded Images</h2>
								<div class="row">
									<div class="col-lg-2 col-md-12 col-xs-12" *ngFor='let image of imageList'>
										<div class="business-gallery-bottom-side">
											<img [src]="image.image" class="imgprev">
											<button type="button" (click)="openGalleryDelete(deleteGalleryContent, image.id)" class="doctor-edit-btn"><i class="fa fa-trash"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>

<ng-template #deleteGalleryContent let-modal>
	<div class="modal-header" id="modal-basic-title">
		<h2 class="mx-5">Delete Image</h2>
		<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
			<i class="ki-duotone ki-cross fs-1">
				<span class="path1"></span>
				<span class="path2"></span>
			</i>
		</div>
	</div>
	<div class="modal-body scroll-y mx-xl-15">
		<div class="login-container">
			<div class="login-form">
				<div class="" style="max-width: 100%;">
					<form [formGroup]="deleteGalleryForm" (ngSubmit)="deleteGallery($event)">

						<div>
							<h3>Do you want to delete this image ?</h3>
						</div>
						
						<div *ngIf="successMessage">
							<div class="alert alert-success" role="alert">
								{{successMessage}}
							</div>
						</div>
						<div *ngIf="errorMessage">
							<div class="alert alert-danger" role="alert">
								{{errorMessage}}
							</div>
						</div>
						<button type="submit" class="btn bg-gradient-primary wd-25 submit-btn-gradient">Delete</button>
						<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25 cancel-btn-modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
