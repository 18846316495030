<body class="g-sidenav-show bg-gray-200">
  	<div class="main-content position-relative max-height-vh-100 h-100 main-content-width">
    	<div class="container-fluid px-2 px-md-4" *ngIf="detailedList">
      		<div class="page-header min-height-300 border-radius-xl mt-4" >
				<iframe [src]="transform()" width="100%" height="300" style="border:2px solid#fff;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        	</div>
      		<div class="card card-body mx-3 mx-md-4 mt-n6">
        		<div class="row gx-4 mb-2">
          			<div class="col-auto">
						<div class="avatar avatar-xl position-relative">
							<img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
						</div>
          			</div>
          			<div class="col-auto my-auto">
            			<div class="h-100" *ngIf="detailedList">
              				<h5 class="mb-1">{{detailedList.details.name}}</h5>
              				<p class="mb-0 font-weight-normal text-sm">{{type}}</p>
               				<p class="mb-0 font-weight-normal text-sm">
                 				<strong>Join Date:</strong> {{detailedList.details.created | date :'MMM, y'}}
              				</p>
            			</div>
          			</div>
          			<div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
						<a style="color: #ff0000;" target="_blank" href="public_profile/{{map_id}}">View Public Profile</a>
          			</div>
        		</div>
          		<div class="row" *ngIf="detailedList">
             		<div class="col-12 col-xl-4">
              			<div class="card card-plain h-100">
							<div class="card-header pb-0 p-3">
								<h6 class="mb-0">Business Information:
									<span class="edit1"> <i class="fa fa-edit" (click)="openBusinessAdd(addBusinessContent)"></i></span>
								</h6>
							</div>
							<div class="card-body p-3 profile-para">
								<p><strong>Phone Number:</strong> {{detailedList.details.phone}}</p>
								<p><strong>Email:</strong> {{detailedList.details.email}}</p>
								<p><strong>Emergency Phone Number:</strong> {{detailedList.details.e_phone}}</p>
								<p><strong>Address:</strong> {{detailedList.details.address}}</p>
                			</div>
              			</div>
            		</div>
					<div class="col-12 col-xl-4">
						<div class="card card-plain h-100">
							<div class="card-header pb-0 p-3">
								<h6 class="mb-0">Contact Person Details:
									<span class="edit1"> <i class="fa fa-edit" (click)="openContactAdd(addContactContent)"></i></span>
								</h6>
							</div>
							<div class="card-body p-3 profile-para">
								<p><strong>Name:</strong> {{detailedList.details.c_name}}</p>
								<p><strong>Email:</strong> {{detailedList.details.c_email}}</p>
								<p><strong>Phone Number:</strong> {{detailedList.details.c_phone}}</p>
							</div>
						</div>
					</div>
             		<div class="col-12 col-xl-4">
              			<div class="card card-plain h-100">
							<div class="card-header pb-0 p-3">
								<h6 class="mb-0">Application</h6>
							</div>
							<div class="card-body p-3">
								<ul class="list-group">
									<li class="list-group-item border-0 px-0">
										<div class="form-check form-switch ps-0">
											<input class="form-check-input ms-auto" [checked] = "detailedList.details.make_public" type="checkbox" id="flexSwitchCheckDefault3" (change)="makeYourself($event)">
											<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3">Make yourself public </label>
										</div>
									</li>
									<li class="list-group-item border-0 px-0">
										<div class="form-check form-switch ps-0">
											<input class="form-check-input ms-auto" [checked] = "detailedList.details.newsletter" type="checkbox" id="flexSwitchCheckDefault4" (change)="newsletter($event)">
											<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault4">Recieve a newslater</label>
										</div>
									</li>
									<li class="list-group-item border-0 px-0 pb-0">
										<div class="form-check form-switch ps-0">
											<input class="form-check-input ms-auto" [checked] = "detailedList.details.latest_update" type="checkbox" id="flexSwitchCheckDefault5" (change)="latestUpdate($event)">
											<label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault5">Latest update on donorzilla</label>
										</div>
									</li>
								</ul>
							</div>
              			</div>
            		</div>
					<div class="col-12 col-xl-12">
						<div class="card card-plain h-100">
							<div class="card-header pb-0 p-3">
								<div class="row">
									<div class="col-md-8 d-flex align-items-center">
										<h6 class="mb-0">Your Bio:
											<span class="edit1"> <i class="fa fa-edit" (click)="openBioAdd(addBioContent)"></i></span>
										</h6>
									</div>
									<div class="col-md-4 text-end">
										<a href="javascript:;">
											<i class="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Profile"></i>
										</a>
									</div>
								</div>
							</div>
                			<div class="card-body p-3">
								<p class="text-sm">{{detailedList.details.about}}</p>
                			</div>
              			</div>
            		</div>
          		</div>
      		</div>
			<!-- <div class="card card-body mx-3 mx-md-4 mt-n6" style="margin-top: 20px !important;">
          		<div class="row">
					<div class="col-12 col-xl-12">
						<div class="card card-plain h-100">
							<div class="card-header pb-0 p-3">
								<div class="row">
									<div class="col-md-12 d-flex align-items-center">
										<h6 class="mb-0" style="width: 100%;">Your Services & Amenities:
											<span class="edit1" style="float: right;"> <a type="button" class="btn bg-gradient-primary w-100" (click)="openAmenitiesAdd(addAmenitiesContent)"> <i class="fa fa-cog"></i> Manage Amenities</a></span>
										</h6>
									</div>
								</div>
							</div>
                			<div class="card-body p-3">
								<div class="row">
									<div class="col-md-2 align-items-center" *ngFor="let amenityMap of amenityMapList;let i = index">
										<img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" class="w-60 border-radius-lg shadow-sm"/>
										<p innerHTML="{{amenityMap.name}}"></p>
									</div>		
								</div>
                			</div>
              			</div>
            		</div>
          		</div>
      		</div>
			<div class="card card-body mx-3 mx-md-4 mt-n6" style="margin-top: 20px !important;">
				<div class="row">
				  <div class="col-12 col-xl-12">
					  <div class="card card-plain h-100">
						  <div class="card-header pb-0 p-3">
							  <div class="row">
								  <div class="col-md-12 d-flex align-items-center">
									  <h6 class="mb-0" style="width: 100%;">Your Department:
										  <span class="edit1" style="float: right;"> <a type="button" class="btn bg-gradient-primary w-100" (click)="openDepartmentAdd(addDepartmentContent)"> <i class="fa fa-cog"></i> Manage Department</a></span>
									  </h6>
								  </div>
							  </div>
						  </div>
						  <div class="card-body p-3">
							  <div class="row">
								  <div class="col-md-2 align-items-center" *ngFor="let departmentMap of departmentMapList;let i = index">
									  <img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" class="w-60 border-radius-lg shadow-sm"/>
									  <p innerHTML="{{departmentMap.name}}"></p>
								  </div>		
							  </div>
						  </div>
						</div>
				  </div>
				</div>
			</div> -->
    	</div>
  	</div>
	<ng-template #addBusinessContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Update Business Information</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="addBusinessForm" (ngSubmit)="addBusiness($event)">
							<div class="login-form-group">
								<label for="email">Email <span class="required-star">*</span></label>
								<input type="text" placeholder="Email" formControlName="email" autocomplete="off" [ngClass]="{'inputError': f.email.touched && f.email.invalid && f.email.errors && f.email.errors.required }" [ngClass]="{'inputError': formErrorMessages.emailError }">
							</div>
							<div class="login-form-group">
								<label for="e_phone">Emergency Phone Number <span class="required-star">*</span></label>
								<input type="text" placeholder="Emergency Phone Number" formControlName="e_phone" autocomplete="off" [ngClass]="{'inputError': f.e_phone.touched && f.e_phone.invalid && f.e_phone.errors && f.e_phone.errors.required }" [ngClass]="{'inputError': formErrorMessages.e_phoneError }">
							</div>
							<div class="login-form-group">
								<label for="address">Address <span class="required-star">*</span></label>
								<input type="text" placeholder="Address" formControlName="address" autocomplete="off" [ngClass]="{'inputError': f.address.touched && f.address.invalid && f.address.errors && f.address.errors.required }" [ngClass]="{'inputError': formErrorMessages.addressError }">
							</div>
							<div *ngIf="successMessage">
								<div class="alert alert-success" role="alert">
									{{successMessage}}
								</div>
							</div>
							<div *ngIf="errorMessage">
								<div class="alert alert-danger" role="alert">
									{{errorMessage}}
								</div>
							</div>
							<button type="submit" class="rounded-button login-cta">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="rounded-button login-cta">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #addContactContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Update Contact Details</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="addContactForm" (ngSubmit)="addContact($event)">
							<div class="login-form-group">
								<label for="c_name">Name <span class="required-star">*</span></label>
								<input type="text" placeholder="Name" formControlName="c_name" autocomplete="off" [ngClass]="{'inputError': g.c_name.touched && g.c_name.invalid && g.c_name.errors && g.c_name.errors.required }" [ngClass]="{'inputError': formErrorMessages.c_nameError }">
							</div>
							<div class="login-form-group">
								<label for="c_phone">Phone Number <span class="required-star">*</span></label>
								<input type="text" placeholder="Phone Number" formControlName="c_phone" autocomplete="off" [ngClass]="{'inputError': g.c_phone.touched && g.c_phone.invalid && g.c_phone.errors && g.c_phone.errors.required }" [ngClass]="{'inputError': formErrorMessages.c_phoneError }">
							</div>
							<div class="login-form-group">
								<label for="c_email">Email <span class="required-star">*</span></label>
								<input type="text" placeholder="Email" formControlName="c_email" autocomplete="off" [ngClass]="{'inputError': g.c_email.touched && g.c_email.invalid && g.c_email.errors && g.c_email.errors.required }" [ngClass]="{'inputError': formErrorMessages.c_emailError }">
							</div>
							<div *ngIf="successMessage">
								<div class="alert alert-success" role="alert">
									{{successMessage}}
								</div>
							</div>
							<div *ngIf="errorMessage">
								<div class="alert alert-danger" role="alert">
									{{errorMessage}}
								</div>
							</div>
							<button type="submit" class="btn bg-gradient-primary wd-25" style="margin-right: 10px;">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #addBioContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Update Bio</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="addBioForm" (ngSubmit)="addBio($event)">
							<div class="login-form-group">
								<label for="about">About <span class="required-star">*</span></label>
								<textarea class="form-input" formControlName="about" autocomplete="off" [ngClass]="{'inputError': h.about.touched && h.about.invalid && h.about.errors && h.about.errors.required }" [ngClass]="{'inputError': formErrorMessages.aboutError }"></textarea>
							</div>
							<button type="submit" class="btn bg-gradient-primary wd-25" style="margin-right: 10px;">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #addAmenitiesContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Amenities Master List</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form" *ngIf="amenityList">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="addAmenityMapForm" (ngSubmit)="addAmenityMap($event)">
							<div class="row">
								<div class="col-md-2 align-items-center" *ngFor="let amenity of amenityList;let i = index">
									<input type="checkbox" value="{{amenity.id}}" (change)="onCheckboxChange($event)"><img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" class="w-100 border-radius-lg shadow-sm"/><p innerHTML="{{amenity.name}}"></p>
								</div>		
							</div><br><br>
							<button type="submit" class="btn bg-gradient-primary wd-25" style="margin-right: 10px;">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #addDepartmentContent let-modal>
		<div class="modal-header" id="modal-basic-title">
			<h2 class="fw-bold">Department Master List</h2>
			<div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modal.dismiss('Cross click')">
				<i class="ki-duotone ki-cross fs-1">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</div>
		</div>
		<div class="modal-body scroll-y mx-5 mx-xl-15 my-3">
			<div class="login-container">
				<div class="login-form" *ngIf="departmentList">
					<div class="login-form-inner" style="max-width: 100%;">
						<form [formGroup]="addDepartmentMapForm" (ngSubmit)="addDepartmentMap($event)">
							<div class="row">
								<div class="col-md-2 align-items-center" *ngFor="let department of departmentList;let i = index">
									<input type="checkbox" value="{{department.id}}" (change)="onDepartmentChange($event)"><img src="https://resources.donorzilla.com/statics/images/bruce-mars.jpg" class="w-100 border-radius-lg shadow-sm"/><p innerHTML="{{department.name}}"></p>
								</div>		
							</div><br><br>
							<button type="submit" class="btn bg-gradient-primary wd-25" style="margin-right: 10px;">Update</button>
							<button type="button" (click)="modal.dismiss('Cross click')" class="btn bg-gradient-primary wd-25">Cancel</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-template>