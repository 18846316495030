import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { AppointmentDetailsRoutingModule } from './appointment-details-routing.module';
import { AppointmentDetailsComponent } from './appointment-details.component';

import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  imports: [
    CommonModule,
    AppointmentDetailsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule
  ],
  declarations: [AppointmentDetailsComponent],
  providers: [AuthGuard]
})
export class AppointmentDetailsModule { }
