import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BloodRequest } from "../models/bloodRequest";
import { DiseaseService } from "../services/disease.service";
import { ActivatedRoute } from '@angular/router';
import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: 'app-donor-dashboard',
	templateUrl: './donor-dashboard.component.html',
	styleUrls: ['./donor-dashboard.component.css']
})
export class DonorDashboardComponent implements OnInit {
	[x: string]: any;

	user_id: any;
	request_id: any;
	bloodRequestId: any;
	recentRequestsList: any;
	myRequestsList: any;
	requesterBloodRequestId: any;
	disease: any[] = [];
	diseases: any[] = [];
	errorMessage: any;
	successMessage: any
	closeResult = "";
	selectedDiseases: any[] = [];

  

	menuItems = [
		{ path: '#', name: 'Find Donors', icon: 'fa fa-search' },
		{ path: '/create-blood-request', name: 'Request Blood', icon: 'fa fa-tint' },
		{ path: '#', name: 'Blood Bank', icon: 'fa fa-building-o' },
		{ path: '#', name: 'Hospitals', icon: 'fa fa-hospital-o' },
		{ path: '#', name: 'Path Labs', icon: 'fa fa-eyedropper' },
		{ path: '#', name: 'Doctors', icon: 'fa fa-user-md' }
	  ];
		
	constructor(
		private bloodRequestService: BloodRequestService,
		private diseaseService: DiseaseService,
		private modalService: NgbModal,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.getHomeBloodRequest(this.user_id);
			this.getAllBloodRequest(this.user_id);
		}
	}
	
	onCheckboxChange(event: Event, diseaseId: number) {
		const isChecked = (event.target as HTMLInputElement).checked;
	
		if (isChecked) {
			if (!this.selectedDiseases.includes(diseaseId)) {
				this.selectedDiseases.push(diseaseId);
			}
		} else {
			this.selectedDiseases = this.selectedDiseases.filter(id => id !== diseaseId);
		}
	}

	getHomeBloodRequest(userId: any): void {
		this.bloodRequestService.getHomeBloodRequest({ userId } as BloodRequest)
			.subscribe(
				data => {
					this.recentRequestsList = data.data;
				}
			);
	}

	getAllBloodRequest(userId: any): void {
		this.bloodRequestService.getAllBloodRequest({userId} as BloodRequest)
		.subscribe (
			data => {
				this.myRequestsList = data.data;
			}
		)
	}

	openDonateNowModal(content: any, request_id: any) {
		this.bloodRequestId = request_id;
		this.diseaseService.getAllDisease()
		.subscribe(
			(data: { status: number; data: any; }) => {
				if (data.status == 200) {
				  this.diseases = data.data;
				}
			  },
			  (error: any) => {}
			);
		
			this.modalService
			  .open(content, {
				ariaLabelledBy: "modal-basic-title",
				size: "lg",
				backdrop: true,
				keyboard: false,
			  })
			  .result.then(
				(result: any) => {
				  this.closeResult = `Closed with: ${result}`;
				},
				(reason: any) => {
				  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			  );
	}
	
	onSubmit($event: any) {
		let donor_id = this.user_id;
		let request_id = this.bloodRequestId;
		let user_id = this.user_id;		

		this.bloodRequestService.acceptBloodRequest(request_id, donor_id, user_id, this.selectedDiseases)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
}
