import { Component, OnInit } from '@angular/core';

import { BloodRequest } from "../models/bloodRequest";

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: 'app-all-blood-requests',
	templateUrl: './all-blood-requests.component.html',
	styleUrls: [
		'./all-blood-requests.component.css'
	]
})
export class AllBloodRequestsComponent implements OnInit {

	user_id = 0;
	bloodRequestList: any;
		
	constructor(
		private bloodRequestService: BloodRequestService,
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.getBloodRequestById(this.user_id);
		}
	}

	getBloodRequestById(userId: any): void {
		this.bloodRequestService.getBloodRequestById(userId)
			.subscribe(
				data => {
					this.bloodRequestList = data.data;
				}
			);
	}
	
	
}
