import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Doctor } from "../models/doctor";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class DoctorService {
	
	private getDoctorUrl = environment.baseURL + "doctor/getDoctor";

	private getDoctorByIdUrl = environment.baseURL + "doctor/getDoctorById";
	
	private createDoctorUrl = environment.baseURL + "doctor/createDoctor";

	private updateDoctorUrl = environment.baseURL + "doctor/updateDoctor";

	private getDoctorByBusinessIdUrl = environment.baseURL + "doctor/getDoctorByBusinessId";

	private getDoctorByUserIdUrl = environment.baseURL + "doctor/getDoctorByUserId";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getDoctor(params: any): Observable<Doctor> {
		return this.http
		.post<Doctor>(this.getDoctorUrl, params, this.httpOptions)
		.pipe(
			map((doctorList) => {
			return doctorList;
			})
		);
	}
	
	getDoctorById(params: any): Observable<Doctor> {
		return this.http
		.post<Doctor>(this.getDoctorByIdUrl, params, this.httpOptions)
		.pipe(
			map((doctorList) => {
			return doctorList;
			})
		);
	}
	
	createDoctor(params: any): Observable<Doctor> {
		return this.http
		.post<Doctor>(this.createDoctorUrl, params, this.httpOptions)
		.pipe(
			map((doctorList) => {
			return doctorList;
			})
		);
	}
	
	updateDoctor(params: any): Observable<Doctor> {
		return this.http
		.post<Doctor>(this.updateDoctorUrl, params, this.httpOptions)
		.pipe(
			map((doctorList) => {
			return doctorList;
			})
		);
	}
	
	getDoctorByBusinessId(b_id: any): Observable<Doctor> {
		return this.http
		.post<Doctor>(this.getDoctorByBusinessIdUrl, b_id, this.httpOptions)
		.pipe(
			map((doctorList) => {
			return doctorList;
			})
		);
	}

	getDoctorByUserId(user_id: any): Observable<Doctor> {
		return this.http
		.post<Doctor>(this.getDoctorByUserIdUrl, user_id, this.httpOptions)
		.pipe(
			map((doctorList) => {
			return doctorList;
			})
		);
	}
}