<div class="breadcrumb_section overflow-hidden ptb-150">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12 text-center">
                <h2>FAQs</h2>
            </div>
        </div>
    </div>
</div>

<section id="industry-serv" data-aos="fade-up" data-aos-duration="2000">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10 col-sm-12">
                <div class="accordion" id="faqAccordion">
                    <div *ngFor="let faq of faqs; let i = index" class="accordion-item">
                        <!-- Accordion Header -->
                        <h2 class="accordion-header">
                            <button class="accordion-button d-flex align-items-center"
                                type="button"
                                (click)="toggleAccordion(i)">
                                <span class="question-text">{{ faq.question }}</span>
                                <span class="icon">
                                    <i *ngIf="activeIndex !== i" class="fa fa-plus"></i>
                                    <i *ngIf="activeIndex === i" class="fa fa-minus"></i>
                                </span>
                            </button>
                        </h2>

                        <!-- Accordion Content -->
                        <div class="accordion-collapse collapse" [ngClass]="{'show': activeIndex === i}">
                            <div class="accordion-body">
                                <p>{{ faq.answer }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
