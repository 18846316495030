import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommunityListComponent } from './community-list.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'community-list',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: CommunityListComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,
      showBusinessHeader: true,
      showBusinessSidebar: true,
      showBusinessFooter: true,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityListRoutingModule { }
