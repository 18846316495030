<app-header *ngIf="showHeader" fxLayout="column"></app-header>
<app-business-header *ngIf="showBusinessHeader" fxLayout="column"></app-business-header>

<div fxLayout="row" fxFlex="100">
  <div class="content" fxLayout="column" fxFlex>
    <app-sidebar *ngIf="showSidebar" fxLayout="column"></app-sidebar>
    <app-business-sidebar *ngIf="showBusinessSidebar" fxLayout="column"></app-business-sidebar>
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer *ngIf="showFooter" fxLayout="column"></app-footer>
<app-business-footer *ngIf="showBusinessFooter" fxLayout="column"></app-business-footer>