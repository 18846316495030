import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class BusinessImageService {

	private uploadImageUrl = environment.baseURL + 'businessImage/uploadBusinessImage';

	constructor(private http: HttpClient) { }

	uploadImage(image: any, b_id: any): Observable<any> {
		let businessImage = { 'images': image, 'b_id': b_id };
		return this.http.post(this.uploadImageUrl, businessImage);
	}
}