import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Newsletter } from "../models/newsletter";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class NewsletterService {
	
	private addNewsletterUrl = environment.baseURL + "newsletter/createNewsletter";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	addNewsletter(newsletter: Newsletter): Observable<Newsletter> {
		return this.http
		.post<Newsletter>(this.addNewsletterUrl, newsletter, this.httpOptions)
		.pipe(
			map((newsletter) => {
			return newsletter;
			})
		);
	}

}
