<div class="breadcrumb_section brd-crum  ptb-50">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <div class="site-search">
                    <div class="product_search">
                        <form class="search_form_2" role="search">
                            <div class="select_mate" data-mate-select="active" >
                                <select onchange="" onclick="return false;" id="c">
                                    <option value="">All Categories </option>
                                    <option *ngFor="let articleCategory of articleCategoryList;let i = index" value="{{articleCategory.id}}">{{articleCategory.name}}</option>
                                </select>
                                <p class="selection_option"  onclick="open_select(this)" ></p>
                                <span onclick="open_select(this)" class="icon_select_mate" >
                                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
                                        <path d="M0-.75h24v24H0z" fill="none"/>
                                    </svg>
                                </span>
                                <div class="cont_list_select_mate">
                                    <ul class="cont_select_int">  </ul>
                                </div>
                            </div>
                            <div class="outer">
                                <div class="inner"></div>
                            </div>
                            <input type="search" placeholder="Search for Categories" id="s">
                            <i class="fa fa-search" aria-hidden="true" (click)="filterArticle()"></i>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="news gray_bg ptb-115">
    <div class="container">
        <div class="top-blog">
            <div class="row">
                <div class="col-md-3">
                    <div class="side-category">
                        <div class="list-cat">
                            <h2>Category List</h2>
                            <div class="round-btn" id="show-search-box">
                                <i class="fa fa-search flip-icon"></i>
                            </div>
                            <form id="hidden-search-box" class="navbar-form hidden-search-box" role="search">
                                <div class="traingle"></div>
                                <div class="input-group add-on">
                                    <input class="form-control addon-text-box" placeholder="Search here..." name="s"  type="text">
                                    <button type="submit" class="input-group-btn addon-btn">
                                        <i class="fa fa-search flip-icon"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <ul>
                            <li *ngFor="let articleCategoryF of articleCategory;let i = index"  (click)="filterArticleCategory(articleCategoryF.id)"><a href="javascript:;">{{articleCategoryF.name}}</a><span>({{articleCategoryF.total_article}})</span></li>
                        </ul>
                    </div>
                    <!-- <div class="side-category">
                        <div class="list-cat">
                            <h2>Tags</h2>
                            <div class="round-btn" id="show-search-box1">
                                <i class="fa fa-search flip-icon"></i>
                            </div>
                            <form id="hidden-search-box1" class="navbar-form hidden-search-box" role="search">
                                <div class="traingle"></div>
                                <div class="input-group add-on">
                                    <input class="form-control addon-text-box" placeholder="Search here..." name="s"  type="text">
                                    <button type="submit" class="input-group-btn addon-btn">
                                        <i class="fa fa-search flip-icon"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <ul>
                            <li><a href="#">HealthCare</a><span>(3)</span></li>
                            <li><a href="#"> Wellenss</a><span>(5)</span></li>
                            <li><a href="#">Blood Donation</a><span>(2)</span></li>
                            <li><a href="#">Pathology Lab</a><span>(4)</span></li>
                            <li><a href="#"> Wellenss</a><span>(7)</span></li>
                        </ul>
                    </div> -->
                </div>
                <div class="col-md-9" *ngIf="articleList">
                    <div class="lset" *ngFor="let article of articleList;let i = index">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="latest-inne">
                                    <a href="article/{{article.slug}}"><img src="{{article.featured_image}}"></a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="latest-inne-txt">
                                    <a href="article/{{article.slug}}"><h1>{{article.title}}</h1></a>
                                    <p [innerHTML]="article.description | slice:0:180"></p>
                                    <a href="article/{{article.slug}}" class="read-more-red">Read More <i class="fa-solid fa-angles-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9" *ngIf="!articleList">
                    <div class="lset">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="latest-inne-txt">
                                    <h2>No data found!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="col-12 mt-5">
    <div class="page_pagination">
        <ul>
            <li (click)="paginationChange(i)" class="{{i == page ? 'active' : ''}}" *ngFor="let item of [].constructor(totalcounter); let i = index"><a href="javascript:;">{{i+1}}</a></li>
        </ul>
    </div>
</div>
<br><br>