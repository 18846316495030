<div class="breadcrumb_section overflow-hidden ptb-150">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12 text-center">
                <h2>FAQs</h2>
            </div>
        </div>
    </div>
</div>

<section id="industry-serv" data-aos="fade-up" data-aos-duration="2000">
    <div class="container">
        <div class="accordion">
            <div class="accordion-item">
                <input type="checkbox" id="accordion1">
                <label for="accordion1" class="accordion-item-title"><span class="icon"></span>What is DonorZilla?</label>
                <div class="accordion-item-desc">DonorZilla is an innovative online platform designed to connect individuals with healthcare services, facilitating blood donor searches and business listings for hospitals, blood banks, and wellness centers.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion2">
                <label for="accordion2" class="accordion-item-title"><span class="icon"></span>How can I find a blood donor on DonorZilla?</label>
                <div class="accordion-item-desc">To find a blood donor, enter your location and required blood type in the search bar. You’ll see a list of registered donors available in your area.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion3">
                <label for="accordion3" class="accordion-item-title"><span class="icon"></span>How do I register as a blood donor?</label>
                <div class="accordion-item-desc">To register, visit the Donor Registration page, fill out the required information, and submit your details. You’ll receive confirmation once your profile is approved.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion4">
                <label for="accordion4" class="accordion-item-title"><span class="icon"></span>What are the eligibility criteria for blood donors?</label>
                <div class="accordion-item-desc">Generally, donors must be at least 18 years old, weigh over 50 kg, and meet certain health guidelines. For specific criteria, please refer to our donor eligibility section.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion5">
                <label for="accordion5" class="accordion-item-title"><span class="icon"></span>How do I register my business on DonorZilla?</label>
                <div class="accordion-item-desc">Business registration is simple. Visit our Business Listing page, complete the registration form, and provide essential details about your healthcare or wellness business.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion6">
                <label for="accordion6" class="accordion-item-title"><span class="icon"></span>What benefits does listing my business on DonorZilla offer?</label>
                <div class="accordion-item-desc">Listing your business increases visibility to potential clients, enhances credibility, and connects you with individuals actively seeking healthcare services.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion7">
                <label for="accordion7" class="accordion-item-title"><span class="icon"></span>Is there a cost to list my business?</label>
                <div class="accordion-item-desc">Currently, DonorZilla offers free business listings for healthcare and wellness services. Check our website for any updates regarding fees.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion8">
                <label for="accordion8" class="accordion-item-title"><span class="icon"></span>How can I update my business listing?</label>
                <div class="accordion-item-desc">Once registered, log in to your account to edit your business information, including services offered, operating hours, and contact details.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion9">
                <label for="accordion9" class="accordion-item-title"><span class="icon"></span>What is the referral system on DonorZilla?</label>
                <div class="accordion-item-desc">Our referral system allows users to invite others to register as blood donors or businesses. Successful referrals can earn rewards or recognition through our badge system.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion10">
                <label for="accordion10" class="accordion-item-title"><span class="icon"></span>How do I subscribe to the DonorZilla newsletter?</label>
                <div class="accordion-item-desc">You can subscribe to our newsletter by entering your email in the subscription box on our homepage. Stay informed about healthcare news, updates, and promotions.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion11">
                <label for="accordion11" class="accordion-item-title"><span class="icon"></span>How frequently will I receive newsletters?</label>
                <div class="accordion-item-desc">Subscribers will receive newsletters monthly, featuring healthcare tips, donor stories, and updates about DonorZilla services.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion12">
                <label for="accordion12" class="accordion-item-title"><span class="icon"></span>How can I contact DonorZilla support?</label>
                <div class="accordion-item-desc">For assistance, visit our Contact Us page, where you’ll find our email and a contact form to submit your inquiries.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion13">
                <label for="accordion13" class="accordion-item-title"><span class="icon"></span>Is my personal information safe with DonorZilla?</label>
                <div class="accordion-item-desc">Absolutely. DonorZilla prioritizes data security, employing encryption and following strict privacy policies to protect your personal information.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion14">
                <label for="accordion14" class="accordion-item-title"><span class="icon"></span>What types of businesses can be listed on DonorZilla?</label>
                <div class="accordion-item-desc">We welcome all healthcare-related businesses, including hospitals, clinics, blood banks, pathology labs, and wellness centers to register and increase their visibility.</div>
            </div>
            <div class="accordion-item">
                <input type="checkbox" id="accordion15">
                <label for="accordion15" class="accordion-item-title"><span class="icon"></span>How can DonorZilla help improve community health?</label>
                <div class="accordion-item-desc">By connecting donors with those in need and enhancing the visibility of healthcare services, DonorZilla contributes to better health outcomes and fosters a supportive community.</div>
            </div>
        </div>
    </div>
</section>