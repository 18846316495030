import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BloodRequest } from "../models/bloodRequest";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class BloodRequestService {
    
    private getBloodRequestUrl = environment.baseURL + "bloodRequest/getBloodRequest";
    private getBloodRequestByUserIdUrl = environment.baseURL + "bloodRequest/getBloodRequestByUserId";
    private getBloodRequestByIdUrl = environment.baseURL + "bloodRequest/getBloodRequestById";
    private getHomeBloodRequestUrl = environment.baseURL + "bloodRequest/getHomeBloodRequest";
    private getAllBloodRequestUrl = environment.baseURL + "bloodRequest/getAllBloodRequest";
    private createBloodRequestUrl = environment.baseURL + "bloodRequest/createBloodRequest";
    private acceptBloodRequestUrl = environment.baseURL + "bloodRequest/acceptBloodRequest";
    private rejectBloodRequestUrl = environment.baseURL + "bloodRequest/rejectBloodRequest";
    private acceptDonorRequestUrl = environment.baseURL + "bloodRequest/acceptDonorRequest";
    private getBloodRequestTimelineUrl = environment.baseURL + "bloodRequest/getBloodRequestTimeline";
    private getBloodRequestDonorsByIdUrl = environment.baseURL + "bloodRequest/getBloodRequestDonorsById";
    private uploadBloodDocumentUrl = environment.baseURL + "bloodRequest/uploadBloodDocument";
    private updateBloodRequestUrl = environment.baseURL + "bloodRequest/updateBloodRequest";
    private deleteBloodDocumentUrl = environment.baseURL + "bloodRequest/deleteBloodDocument";
    private updateBloodRequestDetailsUrl = environment.baseURL + "bloodRequest/updateBloodRequestDetails";
    private reviewBloodRequestUrl = environment.baseURL + "bloodRequest/reviewBloodRequest";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) {}
    
    getBloodRequest(params: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.getBloodRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getBloodRequestByUserId(userId: any): Observable<BloodRequest> {
        let userIds = { userId: userId };
        return this.http
            .post<BloodRequest>(this.getBloodRequestByUserIdUrl, userIds, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getBloodRequestById(bloodRequestId: any): Observable<BloodRequest> {
        let bloodRequestIds = { bloodRequestId: bloodRequestId };
        return this.http
            .post<BloodRequest>(this.getBloodRequestByIdUrl, bloodRequestIds, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getHomeBloodRequest(userId: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.getHomeBloodRequestUrl, userId, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    getAllBloodRequest(userId: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.getAllBloodRequestUrl, userId, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }
    
    createBloodRequest(params:any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.createBloodRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }
    
    getBloodRequestTimeline(bloodRequestId:any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.getBloodRequestTimelineUrl, bloodRequestId, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }
    
    getBloodRequestDonorsById(bloodRequestId:any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.getBloodRequestDonorsByIdUrl, bloodRequestId, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }
    
    uploadBloodDocument(fileSource: any, bloodRequestId: any ): Observable<BloodRequest> {
        const params = {
            image: fileSource,
            request_id: bloodRequestId            
        };
    
        return this.http
            .post<BloodRequest>(this.uploadBloodDocumentUrl, params, this.getHttpOptions())
            .pipe(map((response) => response));
    }
    
    updateBloodRequestDetails(params:any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.updateBloodRequestDetailsUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    deleteBloodDocument(bloodRequestId: any): Observable<BloodRequest> {
        return this.http
            .post<BloodRequest>(this.deleteBloodDocumentUrl, bloodRequestId, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    acceptBloodRequest(bloodRequestId: any, donor_id: any, user_id: any, diseases: string[] ): Observable<BloodRequest> {
        const params = {
            request_id: bloodRequestId,
            donor_id: donor_id,
            userId: user_id,
            diseases
        };
        return this.http
            .post<BloodRequest>(this.acceptBloodRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    rejectBloodRequest(params: any): Observable<BloodRequest> {

        return this.http
            .post<BloodRequest>(this.rejectBloodRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    acceptDonorRequest(params: any): Observable<BloodRequest> {

        return this.http
            .post<BloodRequest>(this.acceptDonorRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    updateBloodRequest(params: any): Observable<BloodRequest> {

        return this.http
            .post<BloodRequest>(this.updateBloodRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }

    reviewBloodRequest(params: any): Observable<BloodRequest> {

        return this.http
            .post<BloodRequest>(this.reviewBloodRequestUrl, params, this.getHttpOptions())
            .pipe(map((bloodRequestList) => bloodRequestList));
    }
}
