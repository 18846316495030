import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BusinessImageService {

    private baseUrl = environment.baseURL + "businessImage/";
    
    private uploadImageUrl = environment.baseURL + 'businessImage/uploadBusinessImage';

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
            var userArray = JSON.parse(currentUser);
            token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ 'token': token ? token : '' })
        };
    }

    constructor(private http: HttpClient) { }

    uploadImage(image: any, b_id: any): Observable<any> {
        let businessImage = { 'images': image, 'b_id': b_id };
        return this.http.post(this.uploadImageUrl, businessImage, this.getHttpOptions());
    }
    
    deleteGallery(params: any): Observable<any> {
        return this.http.post<any>(this.baseUrl + "deleteGallery", params, this.getHttpOptions());
    }
}
