import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { ArticleService } from "../services/article.service";
import { ArticleCategoryService } from "../services/articleCategory.service";

import { Article } from "../models/article";

@Component({
	selector: 'app-article-list',
	templateUrl: './article-list.component.html',
	styleUrls: [
		'./article-list.component.css'
	],
	encapsulation: ViewEncapsulation.None,
})
export class ArticleListComponent implements OnInit {
	
	allArticleList: any;
	articleList: any;
	articleCategoryList: any;
	articleCategory: any;
	allArticleCategory: any;
	totalcounter: any;
	start: number = 0;
	limit: number = 10;
	page: number = 0;
	closeResult = "";

	constructor(
		private title: Title,
		private meta: Meta,
		private articleService: ArticleService,
		private articleCategoryService: ArticleCategoryService,
			private modalService: NgbModal,
		
	) { }

	ngOnInit(): void {
		this.title.setTitle('Articles | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:"DonorZilla all Articles & blog posts"
		});
		this.getAllArticle();
	}

	paginationChange(page: any): void{
		this.page = page;
		this.getArticlePagination();
	}

	getAllArticle(): void {
		this.articleService.getAllArticle()
			.subscribe(
				data => {
					this.articleList = data.data.list;
					this.allArticleList = data.data.total;
					this.totalcounter = Math.floor(this.allArticleList.total / 10 ) + 1;
				}
			);
		
		//this.getArticlePagination();

		this.articleCategoryService.getAllArticleCategory()
		.subscribe(
			data => {
				this.articleCategoryList = data.data;
			}
		);
		this.articleCategoryService.getArticleCategoryWithCount()
		.subscribe(
			data => {
				this.articleCategory = data.data;
			}
		);
		this.articleCategoryService.getAllArticleCategoryWithCount()
		.subscribe(
			data => {
				this.allArticleCategory = data.data;
			}
		);
	}

	showAllCategoryModal(content: any): void {
		
		this.modalService
		  .open(content, {
			ariaLabelledBy: "modal-basic-title",
			size: "lg",
		  })
		  .result.then(
			(result: any) => {
			  this.closeResult = `Closed with: ${result}`;
			},
			(reason: any) => {
			  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		  );
	  }

	getArticlePagination(): void{
		this.start = (this.page * this.limit);
		this.articleService.getAllArticlePaginate(this.start, this.limit)
			.subscribe(
				data => {
					this.articleList = data.data;
				}
			);
	}

	filterArticle(): void {
		let title = $('#myInput').val();
		let category_id = $('#c').val();
		
		this.articleService.getFilterArticle({ title, category_id } as Article)
			.subscribe(
				data => {
					if (data.status == 200) {
						this.page = 0;
						this.start = 0;
						this.articleList = data.data;
						this.totalcounter = Math.floor(this.articleList.length / 10 ) + 1;
					} else{
						this.articleList = '';
					}
				}
			);
	}

	filterArticleCategory(category_id: any): void {
		let title = '';
		this.articleService.getFilterArticle({ title, category_id } as Article)
			.subscribe(
				data => {
					if (data.status == 200) {
						this.page = 0;
						this.start = 0;
						this.articleList = data.data;
						this.totalcounter = Math.floor(this.articleList.length / 10 ) + 1;
					} else{
						this.articleList = '';
					}
				}
			);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		  return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		  return "by clicking on a backdrop";
		} else {
		  return `with: ${reason}`;
		}
	  }
}
