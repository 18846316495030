import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { AllBloodRequestsRoutingModule } from './all-blood-requests-routing.module';

import { AllBloodRequestsComponent } from './all-blood-requests.component';

@NgModule({
  imports: [
    CommonModule,
    AllBloodRequestsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [AllBloodRequestsComponent],
  providers: [AuthGuard]
})
export class AllBloodRequestsModule { }
