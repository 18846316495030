import { Component, OnInit } from '@angular/core';
import { UserService } from "../services/user.service";
import { User } from '../models/user';


@Component({
	selector: 'donor-profile',
	templateUrl: './donor-profile.component.html',
	styleUrls: ['./donor-profile.component.css']
})
export class DonorProfileComponent implements OnInit {

	activeTab: string = 'a';
	userId: any
	userDetails: any;
	image: any;
	errorMessage = "";
	successMessage = ""

	setActiveTab(tab: string) {
		this.activeTab = tab;
	  }

	constructor(
		private userService: UserService,
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem("currentUser");    
    if (currentUser) {
      var userArray = JSON.parse(currentUser);
      this.userId = userArray.data.id;      
      this.getUserById(this.userId);
    }
	}

	getUserById(userId: any): void {
		this.userService.getUserById(userId).subscribe(
			(data) => {	
				if (data.status == 200) {
					this.userDetails = data.data;
				} else {
					this.userDetails = [];
				}
			},
			(error) => {
			}
		);
	}

	uploadPicture(imageInput: any): void {
		const element = imageInput.currentTarget as HTMLInputElement;
		let fileList: FileList | null = element.files;
		if (fileList && fileList[0]) {
		  var reader = new FileReader();
		  reader.onload = (event: any) => {
			this.image = event.target.result;
			let userId = this.userId;
			this.userService
			  .uploadUserImage(this.image, this.userId)
			  .subscribe(
				(data) => {
				  if (data.status == 200) {
					const successMessage = document.getElementById(
					  "btnSuccess"
					) as HTMLInputElement | null;
					let dynamicMessage: any = document.getElementById(
					  "dynamic_success_msg"
					) as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					reader.onload = (event: any) => {
					  this.image = event.target.result;
					  setTimeout(() => {
						window.location.reload();
					  }, 4000);
					  if (this.image instanceof File) {
						reader.readAsDataURL(this.image);
					  } 
					};
				  } else {
					const errorMessage = document.getElementById(
					  "btnError"
					) as HTMLInputElement | null;
					let dynamicMessage: any = document.getElementById(
					  "dynamic_message"
					) as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
				  }
				},
				(error) => {
				  this.errorMessage = error;
				}
			  );
		  };
		  reader.readAsDataURL(fileList[0]);
		}
	  }

	
}
