<main>
	<section>
		<div class="rad-body-wrapper bg-chnage">
			<div class="container-fluid">
				<div class="white-bgg">
					<div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="card card-calendar">
                <div>
                  <h2>Appointment Schedule</h2>
                  <full-calendar [options]="calendarOptions"></full-calendar>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>