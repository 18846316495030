import { Component, OnInit, } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, FormControl, Validators, FormArray,} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

import { BusinessMaster } from "../models/businessMaster";
import { Amenity } from "../models/amenity";
import { Department } from "../models/department";

import { AmenityService } from "../services/amenity.service";
import { BusinessMasterService } from "../services/businessMaster.service";
import { DepartmentService } from "../services/department.service";
import { event } from "jquery";
import {AiBioService} from "../services/aiBio";
import { HttpClient, HttpClientModule } from '@angular/common/http';



class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {

  center: google.maps.LatLngLiteral = { lat: 20.5937, lng: 78.9629 };
  zoom = 10;
  apiKey: string = "YOUR_GOOGLE_MAPS_API_KEY";
  
  [x: string]: any;
  addBusinessForm = new FormGroup({
    email: new FormControl("", [Validators.required]),
    e_phone: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    address: new FormControl("", [Validators.required]),
  });

  addContactForm = new FormGroup({
    c_name: new FormControl("", [Validators.required]),
    c_email: new FormControl("", [Validators.required]),
    c_phone: new FormControl("", [Validators.required]),
  });

  addBioForm = new FormGroup({
    about: new FormControl("", [Validators.required]),
  });

  addPictureForm = new FormGroup({
    profile_picture: new FormControl("", [Validators.required]),
  });

  addAmenityMapForm = new FormGroup({
    checkArray: this.fb.array([]),
  });

  addDepartmentMapForm = new FormGroup({
    departmentCheckArray: this.fb.array([]),
  });

  addSocialMediaForm = new FormGroup({
    facebook_link: new FormControl("", [Validators.required]),
    twitter_link: new FormControl("", [Validators.required]),
    instagram_link: new FormControl("", [Validators.required]),
    youtube_link: new FormControl("", [Validators.required]),
    linkedin_link: new FormControl("", [Validators.required]),
    website_link: new FormControl("", [Validators.required]),
  });
  trustedMapUrl: any;
  loading!: boolean;

  get f() {
    return this.addBusinessForm.controls;
  }

  get g() {
    return this.addContactForm.controls;
  }

  get h() {
    return this.addBioForm.controls;
  }

  get j() {
    return this.addBioForm.controls;
  }

  dynamicMapUrl: any;
  currentImage: any;

  b_id: any;
  map_id: any;
  user_id = 0;
  role_type = 0;
  notificationCounter = 0;
  type: any;
  bio: any;

  detailedList: any;
  businessList: any;
  notificationList: any;
  amenityList: any;
  amenityMapList: any;
  departmentList: any;
  departmentMapList: any;
  image: any;

  errorMessage = "";
  successMessage = "";
  closeResult = "";
  checkArrayAmenity: any = [];
  checkdepartmentArray: any = [];
  
  constructor(
    private fb: FormBuilder,
    private businessMasterService: BusinessMasterService,
    private amenityService: AmenityService,
    private departmentService: DepartmentService,
    private aiBioService: AiBioService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    var currentUser = localStorage.getItem("currentUser");    
    if (currentUser) {
      var userArray = JSON.parse(currentUser);
      this.user_id = userArray.data.id;
			this.b_id = userArray.data.business_id;
      this.role_type = userArray.data.role_type;
      if (!this.role_type) {
        setTimeout(() => {
          this.router.navigate(["profile"]);
        }, 100);
        return;
      }
      
      this.getRoleDetailsById(this.b_id);
      this.getAmenityByBusinessId(this.b_id);
      this.getDepartmentByBusinessId(this.b_id);
    }
    
  }

  uploadPicture(imageInput: any): void {
    const element = imageInput.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList && fileList[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = event.target.result;
        let b_id = this.b_id;
        this.businessMasterService
          .updateProfilePicture(this.image, b_id)
          .subscribe(
            (data) => {
              if (data.status == 200) {
                const successMessage = document.getElementById(
                  "btnSuccess"
                ) as HTMLInputElement | null;
                let dynamicMessage: any = document.getElementById(
                  "dynamic_success_msg"
                ) as HTMLInputElement | null;
                dynamicMessage.value = data.message;
                successMessage?.click();
                reader.onload = (event: any) => {
                  this.image = event.target.result;
                  setTimeout(() => {
                    window.location.reload();
                  }, 4000);
                  if (this.image instanceof File) {
                    reader.readAsDataURL(this.image);
                  } 
                };
              } else {
                const errorMessage = document.getElementById(
                  "btnError"
                ) as HTMLInputElement | null;
                let dynamicMessage: any = document.getElementById(
                  "dynamic_message"
                ) as HTMLInputElement | null;
                dynamicMessage.value = data.message;
                errorMessage?.click();
              }
            },
            (error) => {
              this.errorMessage = error;
            }
          );
      };
      reader.readAsDataURL(fileList[0]);
    }
  }

  getAmenityByBusinessId(b_id: any): void {
    this.amenityService
      .getAmenityByBusinessId({ b_id } as Amenity)
      .subscribe((data) => {
        this.amenityMapList = data.data;
      });
  }

  someMethod(id: any) {
    return this.amenityMapList.some((item: any) => item.id == id);
  }

  getDepartmentByBusinessId(b_id: any): void {
    this.departmentService
      .getDepartmentByBusinessId({ b_id } as Department)
      .subscribe((data) => {
        this.departmentMapList = data.data;
      });
  }

  getAllDepartment(): void {
    this.departmentService.getAllDepartment().subscribe((data) => {
      this.amenityMapList = data.data;
    });
  }

  getAmenityByUserId(user_id: any): void {
    this.amenityService
      .getAmenityByUserId({ user_id } as Amenity)
      .subscribe((data) => {
        this.amenityMapList = data.data;
      });
  }

  getDepartmentByUserId(user_id: any): void {
    this.departmentService
      .getDepartmentByUserId({ user_id } as Amenity)
      .subscribe((data) => {
        this.departmentMapList = data.data;
      });
  }

  getRoleDetailsById(b_id: any): void {
    this.businessMasterService
      .getBusinessById({ b_id } as BusinessMaster)
      .subscribe((data) => {
        this.detailedList = data.data;
        this.type = data.data.type;
        this.map_id = data.data.map_id;
      });
  }

  getRoleDetails(user_id: any): void {
    this.businessMasterService
      .getBusniessMasterByUserId({ user_id } as BusinessMaster)
      .subscribe((data) => {
        this.detailedList = data.data;
        if (data.data.latitude && data.data.longitude) {          
          // Update the Google Maps center dynamically
          this.center = { lat: parseFloat(data.data.latitude), lng: parseFloat(data.data.longitude) };
        } else {
          console.error("Latitude and Longitude not available.");
        }

        this.type = data.data.type;
        this.b_id = data.data.b_id;
      });
  }

  openPictureAdd(content: any): void {
    let b_id = this.b_id;

    this.businessMasterService
      .getInfoByBusinessId({
        b_id,
      } as BusinessMaster)
      .subscribe((data) => {
        if (data.status == 200) {
          this.currentImage = data.data.profile_picture;
        }
      });

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openSocialMediaAdd(content: any): void {
    this.addSocialMediaForm.controls["facebook_link"].setValue(
      this.detailedList.details.facebook_link
    );
    this.addSocialMediaForm.controls["twitter_link"].setValue(
      this.detailedList.details.twitter_link
    );
    this.addSocialMediaForm.controls["instagram_link"].setValue(
      this.detailedList.details.instagram_link
    );
    this.addSocialMediaForm.controls["youtube_link"].setValue(
      this.detailedList.details.youtube_link
    );
    this.addSocialMediaForm.controls["linkedin_link"].setValue(
      this.detailedList.details.linkedin_link
    );
    this.addSocialMediaForm.controls["website_link"].setValue(
      this.detailedList.details.website_link
    );

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openBioAdd(content: any): void {
    let b_id = this.b_id;

    this.businessMasterService
      .getInfoByBusinessId({
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data) => {
          if (data.status == 200) {
            this.addBioForm.controls["about"].setValue(data.data.about);
          }
        },
        (error) => {}
      );

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  addAmenityMap($event: any): void {
    ($event.submitter as HTMLButtonElement).disabled = true;
    let amenity_id = this.checkArrayAmenity;
    let b_id = this.b_id;

    this.amenityService
      .updateAmenityMap({
        amenity_id,
        b_id,
      } as Amenity)
      .subscribe(
        (data) => {
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  addDepartmentMap($event: any): void {
    ($event.submitter as HTMLButtonElement).disabled = true;
    let department_id = this.checkdepartmentArray;

    let b_id = this.b_id;

    this.departmentService
      .updateDepartmentMap({
        department_id,
        b_id,
      } as Department)
      .subscribe(
        (data) => {
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
              setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  addSocialMedia($event: any): void {
    ($event.submitter as HTMLButtonElement).disabled = true;
    let facebook_link = this.addSocialMediaForm.value.facebook_link;
    let twitter_link = this.addSocialMediaForm.value.twitter_link;
    let instagram_link = this.addSocialMediaForm.value.instagram_link;
    let youtube_link = this.addSocialMediaForm.value.youtube_link;
    let linkedin_link = this.addSocialMediaForm.value.linkedin_link;
    let website_link = this.addSocialMediaForm.value.website_link;
    let b_id = this.b_id;

    this.businessMasterService
      .updateSocialMedia({
        facebook_link,
        twitter_link,
        instagram_link,
        youtube_link,
        linkedin_link,
        website_link,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data) => {
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  addBio($event: any): void {
    ($event.submitter as HTMLButtonElement).disabled = true;
    let about = this.addBioForm.value.about;
    let b_id = this.b_id;

    this.businessMasterService
      .updateBio({
        about,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data) => {
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  generateBio(name: any, type: any, address: any) {    
    if (!name || !type || !address) {
      alert('Please fill in all fields before generating bio.');
      return;
    }
    this.loading = true; 
    this.aiBioService.generateBio(name, type, address).subscribe(
      (response: any) => {
        this.addBioForm.patchValue({ about: response });
        this.loading = false;
      },
      (error: any) => {
        console.error('Error generating bio:', error);
        this.loading = false;
      }
    );
  }
  
  openBusinessAdd(content: any): void {
    let b_id = this.b_id;

    this.businessMasterService
      .getInfoByBusinessId({
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; data: { email: any; e_phone: any; phone: any; address: any; }; }) => {
          if (data.status == 200) {
            this.addBusinessForm.controls["email"].setValue(data.data.email);
            this.addBusinessForm.controls["e_phone"].setValue(
              data.data.e_phone
            );
            this.addBusinessForm.controls["phone"].setValue(
              data.data.phone
            );
            this.addBusinessForm.controls["address"].setValue(
              data.data.address
            );
          }
        },
        (error: any) => {}
      );

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result: any) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openAmenitiesAdd(content: any): void {
    let b_id = this.b_id;
    let role_id = this.role_type;

    this.amenityService
      .getAmenityByRoleId({
        role_id,
      } as Amenity)
      .subscribe(
        (data: { status: number; data: any; }) => {
          if (data.status == 200) {
            this.amenityList = data.data;
          }
        },
        (error: any) => {}
      );

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result: any) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onAmenityChange(amenityId: any) {
    this.checkArrayAmenity.push(amenityId);
  }

  openDepartmentAdd(content: any): void {
    this.departmentService.getAllDepartment().subscribe(
      (data: { status: number; data: any; }) => {
        if (data.status == 200) {
          this.departmentList = data.data;
        }
      },
      (error: any) => {}
    );

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result: any) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onDepartmentChange(departmentId: any) {
    
      this.checkdepartmentArray.push(departmentId);
  
  }

  addBusiness($event: any): void {
    ($event.submitter as HTMLButtonElement).disabled = true;
    let email = this.addBusinessForm.value.email;
    let e_phone = this.addBusinessForm.value.e_phone;
    let phone = this.addBusinessForm.value.phone;
    let address = this.addBusinessForm.value.address;
    let b_id = this.b_id

    this.businessMasterService
      .updateBusinessInfo({
        email,
        e_phone,
        address,
        phone,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; message: any; }) => {
          this.errorMessage = "";
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error: any) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  openContactAdd(content: any): void {
    let b_id = this.b_id;

    this.businessMasterService
      .getInfoByBusinessId({
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; data: { c_name: any; c_email: any; c_phone: any; }; }) => {
          if (data.status == 200) {
            this.addContactForm.controls["c_name"].setValue(data.data.c_name);
            this.addContactForm.controls["c_email"].setValue(data.data.c_email);
            this.addContactForm.controls["c_phone"].setValue(data.data.c_phone);
          }
        },
        (error: any) => {}
      );

    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result: any) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  addContact($event: any): void {
    ($event.submitter as HTMLButtonElement).disabled = true;
    let c_name = this.addContactForm.value.c_name;
    let c_phone = this.addContactForm.value.c_phone;
    let c_email = this.addContactForm.value.c_email;
    let b_id = this.b_id;

    this.businessMasterService
      .updateContactInfo({
        c_name,
        c_phone,
        c_email,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; message: any; }) => {
          this.errorMessage = "";
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error: any) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  makeYourself($event: any): void {
    let make_public = $event.currentTarget.checked;
    let b_id = this.b_id;

    this.businessMasterService
      .updateMakePublic({
        make_public,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; message: any; }) => {
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 4000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error: any) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  newsletter($event: any): void {
    let newsletter = $event.currentTarget.checked;
    let b_id = this.b_id;
  
    this.businessMasterService
      .updateNewsletter({
        newsletter,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; message: any; }) => {
          if (data.status == 200) {
            // Update the local model without reloading the page
            this.detailedList.details.newsletter = newsletter; // Dynamically update the flag in your model
  
            const successMessage = document.getElementById("btnSuccess") as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
          } else {
            const errorMessage = document.getElementById("btnError") as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById("dynamic_message") as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error: any) => {
          const errorMessage = document.getElementById("btnError") as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById("dynamic_message") as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }
  

  latestUpdate($event: any): void {
    let latest_update = $event.currentTarget.checked;
    let b_id = this.b_id;

    this.businessMasterService
      .updateLatestUpdate({
        latest_update,
        b_id,
      } as BusinessMaster)
      .subscribe(
        (data: { status: number; message: any; }) => {
          if (data.status == 200) {
            const successMessage = document.getElementById(
              "btnSuccess"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_success_msg"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            successMessage?.click();
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          } else {
            const errorMessage = document.getElementById(
              "btnError"
            ) as HTMLInputElement | null;
            let dynamicMessage: any = document.getElementById(
              "dynamic_message"
            ) as HTMLInputElement | null;
            dynamicMessage.value = data.message;
            errorMessage?.click();
            ($event.submitter as HTMLButtonElement).disabled = false;
          }
        },
        (error: any) => {
          const errorMessage = document.getElementById(
            "btnError"
          ) as HTMLInputElement | null;
          let dynamicMessage: any = document.getElementById(
            "dynamic_message"
          ) as HTMLInputElement | null;
          dynamicMessage.value = error;
          errorMessage?.click();
          ($event.submitter as HTMLButtonElement).disabled = false;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
