import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-terms-condition',
	templateUrl: './terms-condition.component.html',
	styleUrls: [
		'./terms-condition.component.css'
	]
})
export class TermsConditionComponent implements OnInit {

	constructor(private title: Title, private meta: Meta) {}

	ngOnInit()
	{ 
		this.title.setTitle('Terms & Conditions | DonorZilla'); 

		this.meta.updateTag(
		{
			name:'description',
			content:'Review the terms and conditions of DonorZilla, detailing the guidelines for using our platform to find blood donors, hospitals, doctors, clinics, and path labs. Stay informed about your rights and responsibilities.'
		}); 
	}

}