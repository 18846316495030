import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "./layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";

import { AuthGuard } from "./auth.guard";

import { HomeModule } from "./home/home.module";

import { DoctorModule } from "./doctor/doctor.module";

import { AboutUsModule } from "./about-us/about-us.module";

import { ContactUsModule } from "./contact-us/contact-us.module";

import { FaqModule } from "./faq/faq.module";

import { LoginModule } from "./login/login.module";

import { RegisterModule } from "./register/register.module";

import { LogoutModule } from "./logout/logout.module";

import { DashboardModule } from "./dashboard/dashboard.module";

import { DonorDashboardModule } from "./donor-dashboard/donor-dashboard.module";

import { AllBloodRequestsModule } from "./all-blood-requests/all-blood-requests.module";

import { AppointmentSettingsModule } from "./appointment-settings/appointment-settings.module";

import { AppointmentDetailsModule } from "./appointment-details/appointment-details.module";

import { GalleryModule } from "./gallery/gallery.module";

import { DoctorListModule } from "./doctor-list/doctor-list.module";

import { DonorRequestDetailsModule } from "./donor-request-details/donor-request-details.module";

import { ProfileModule } from "./profile/profile.module";

import { ProfileNewModule } from "./profile-new/profile-new.module";

import { ThankyouModule } from "./thankyou/thankyou.module";

import { DeleteAccountModule } from "./delete-account/delete-account.module";

import { DeleteAccountThankyouModule } from "./delete-account-thankyou/delete-account-thankyou.module";

import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";

import { TermsConditionModule } from "./terms-condition/terms-condition.module";

import { BusinessListingModule } from "./business-listing/business-listing.module";

import { DonorListingModule } from "./donor-listing/donor-listing.module";

import { ArticleDetailsModule } from "./article-details/article-details.module";

import { PublicProfileModule } from "./public-profile/public-profile.module";

import { ArticleListModule } from "./article-list/article-list.module";

import { EditBloodRequestModule } from "./edit-blood-request/edit-blood-request.module";

import { CreateBloodRequestModule } from "./create-blood-request/create-blood-request.module";

import { CommunityListModule } from "./community-list/community-list.module";

import { AiBioService } from "./services/aiBio";

import { GoogleMapsModule } from '@angular/google-maps';

import { CommonModule } from "@angular/common";

import { FullCalendarModule } from "@fullcalendar/angular";

import { DonorProfileModule } from "./donor-profile/donor-profile.module";

import { EditDonorProfileModule } from "./edit-donor-profile/edit-donor-profile.module";

// import { MyBloodRequestModule } from "./my-blood-request/my-blood-request.module";

import { BlogListModule } from "./blog-list/blog-list.module";

// import { DonorChatModule } from "./donor-chat/donor-chat.module";
// import { DonorCommunityModule } from "./donor-community/donor-community.module";
// import { DonorAppointmentModule } from "./donor-appointment/donor-appointment.module";
// import { DonorCommunityPostsModule } from "./donor-community-posts/donor-community-posts.module";
// import { DonorCommunityDetailsModule } from "./donor-community-details/donor-community-details.module";
// import { FindBloodBankModule } from "./find-blood-bank/find-blood-bank.module";
// import { FindHospitalModule } from "./find-hospital/find-hospital.module";
// import { FindPathLabsModule } from "./find-pathlabs/find-pathlabs.module";
// import { BloodBankDetailsModule } from "./blood-bank-details/blood-bank-details.module";
// import { DoctorListPageModule } from "./doctor-list-page/doctor-list-page.module";
// import { DoctorDetailsModule } from "./doctor-details/doctor-details.module";
// import { DoctorSlotDonorAppointmentModule } from "./doctor-slot-donor-appointment/doctor-slot-donor-appointment.module";
// import { DonorChatHistoryModule } from "./donor-chat-history/donor-chat-history.module";
// import { FindDonorMapModule } from "./find-donor-map/find-donor-map.module";




@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    LayoutModule,
    BlogListModule,
    HomeModule,
    AboutUsModule,
    AllBloodRequestsModule,
    ContactUsModule,
    CreateBloodRequestModule,
    FaqModule,
    LoginModule,
    RegisterModule,
    LogoutModule,
    DashboardModule,
    DonorDashboardModule,
    DonorRequestDetailsModule,
    GalleryModule,
    DoctorListModule,
    ArticleDetailsModule,
    AppointmentSettingsModule,
    AppointmentDetailsModule,
    ProfileModule,
    ProfileNewModule,
    DeleteAccountModule,
    DeleteAccountThankyouModule,
    ThankyouModule,
    PrivacyPolicyModule,
    PublicProfileModule,
    TermsConditionModule,
    BusinessListingModule,
    ArticleListModule,
    EditBloodRequestModule,
    DonorListingModule,
    CommunityListModule,
    DoctorModule,
    GoogleMapsModule,
    CommonModule, 
    FullCalendarModule,
    DonorProfileModule,
    EditDonorProfileModule,
   // MyBloodRequestModule,
    // DonorChatModule,
    // DonorCommunityModule,
    // DonorAppointmentModule,
    // DonorCommunityPostsModule,
    // DonorCommunityDetailsModule,
    // FindBloodBankModule,
    // FindHospitalModule,
    // FindPathLabsModule,
    // BloodBankDetailsModule,
    // DoctorListPageModule,
    // DoctorDetailsModule,
    // DoctorSlotDonorAppointmentModule,
    // DonorChatHistoryModule,
    // FindDonorMapModule,
  ],
  providers: [AuthGuard, AiBioService],
  bootstrap: [AppComponent],
})
export class AppModule {}
