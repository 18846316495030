import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { BusinessMaster } from '../models/businessMaster';
import { Amenity } from '../models/amenity';
import { Department } from "../models/department";

import { AmenityService } from "../services/amenity.service";
import { BusinessMasterService } from "../services/businessMaster.service";
import { DepartmentService } from "../services/department.service";

class ImageSnippet {
	constructor(public src: string, public file: File) { }
}

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: [
		'./dashboard.component.css'
	]
})
export class DashboardComponent implements OnInit {

	addBusinessForm = new FormGroup({
		email: new FormControl("", [Validators.required]),
		e_phone: new FormControl("", [Validators.required]),
		address: new FormControl("", [Validators.required]),
  	});

	addContactForm = new FormGroup({
		c_name: new FormControl("", [Validators.required]),
		c_email: new FormControl("", [Validators.required]),
		c_phone: new FormControl("", [Validators.required]),
  	});

	addBioForm = new FormGroup({
		about: new FormControl("", [Validators.required])
  	});

	addPictureForm = new FormGroup({
		profile_picture: new FormControl("", [Validators.required])
  	});

	addAmenityMapForm = new FormGroup({
		checkArray: this.fb.array([])
  	});

	addDepartmentMapForm = new FormGroup({
		departmentCheckArray: this.fb.array([])
  	});

  	get f() {
		return this.addBusinessForm.controls;
	}

	get g() {
		return this.addContactForm.controls;
	}

	get h() {
		return this.addBioForm.controls;
	}

	get j() {
		return this.addBioForm.controls;
	}

	dynamicMapUrl: any;
	currentImage: any;

	b_id: any;
	map_id: any;
	user_id = 0;
	role_type = 0;
	notificationCounter = 0;
	type: any;
	bio: any;

	detailedList: any;
	businessList: any;
	notificationList: any;
	amenityList: any;
	amenityMapList: any;
	departmentList: any;
	departmentMapList: any;
	images : string[] = [];
	
	errorMessage = "";
	successMessage = "";
	closeResult = "";
	formErrorMessages = {
		emailError : "",
		e_phoneError : "",
		addressError : "",
		c_nameError : "",
		c_emailError : "",
		c_phoneError : "",
		aboutError : "",
	};

	constructor(
		private fb: FormBuilder,
		private sanitizer: DomSanitizer,
		private businessMasterService: BusinessMasterService,
		private amenityService: AmenityService,
		private departmentService: DepartmentService,
		private modalService: NgbModal,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
				return;
			}
			this.b_id = this.route.snapshot.queryParams.b_id;
			if(this.b_id){
				this.getRoleDetailsById(this.b_id);
				this.getAmenityByBusinessId(this.b_id);
				this.getDepartmentByBusinessId(this.b_id);
			} else{
				this.getRoleDetails(this.user_id);
				this.getAmenityByUserId(this.user_id);
				this.getDepartmentByUserId(this.user_id);
			}
		}
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (imageInput.files && imageInput.files[0]) {
			var filesAmount = imageInput.files.length;
			for (let i = 0; i < filesAmount; i++) {
				var reader = new FileReader();

				reader.onload = (event:any) => {
					this.images.push(event.target.result); 
	
					this.addPictureForm.patchValue({
						profile_picture: this.images
					});
				}
				reader.readAsDataURL(imageInput.files[i]);
			}
		}
	}

	addPicture($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		
		let profile_picture = this.addPictureForm.value.profile_picture;
		let b_id = this.b_id;

		this.businessMasterService.updateProfilePicture(profile_picture, b_id).subscribe(
			data => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			error => {
				this.errorMessage = error;
			}
		)
	}

	transform() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(
		 	this.dynamicMapUrl
		);
	}
	
	getAmenityByBusinessId(b_id: any): void {
		this.amenityService.getAmenityByBusinessId({ b_id } as Amenity)
			.subscribe(
				data => {
					this.amenityMapList = data.data;
				}
			);
	}

	getDepartmentByBusinessId(b_id: any): void {
		this.departmentService.getDepartmentByBusinessId({ b_id } as Department)
			.subscribe(
				data => {
					this.departmentMapList = data.data;
				}
			);
	}

	getAllDepartment(): void {
		this.departmentService.getAllDepartment()
		.subscribe(
			data => {
				this.amenityMapList = data.data;
			}
		);
	}

	getAmenityByUserId(user_id: any): void {
		this.amenityService.getAmenityByUserId({ user_id } as Amenity)
		.subscribe(
			data => {
				this.amenityMapList = data.data;
			}
		);
	}

	getDepartmentByUserId(user_id: any): void {
		this.departmentService.getDepartmentByUserId({ user_id } as Amenity)
		.subscribe(
			data => {
				this.departmentMapList = data.data;
			}
		);
	}

	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.type = data.data.type;
					this.map_id = data.data.map_id;
					this.dynamicMapUrl = 'https://maps.google.com/maps?q='+data.data.latitude+', '+data.data.longitude+'&z=15&output=embed';
				}
			);
	}

	getRoleDetails(user_id: any): void {
		this.businessMasterService.getBusniessMasterByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.detailedList = data.data;
				this.type = data.data.type;
				this.b_id = data.data.b_id;
				this.map_id = data.data.map_id;
				this.dynamicMapUrl = 'https://maps.google.com/maps?q='+data.data.latitude+', '+data.data.longitude+'&z=15&output=embed';
			}
		);
	}

	openPictureAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
		} as BusinessMaster).subscribe(
		(data) => {
			if (data.status == 200) {
				this.currentImage = data.data.profile_picture;
			}
		},
		(error) => {
		});

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openBioAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
		} as BusinessMaster).subscribe(
		(data) => {
			if (data.status == 200) {
				this.addBioForm.controls["about"].setValue(data.data.about);
			}
		},
		(error) => {
		});

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addAmenityMap($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
    	let amenity_id = this.addAmenityMapForm.value;

		let b_id = this.b_id;

		this.amenityService.updateAmenityMap({
			amenity_id,
			b_id
		} as Amenity)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	addDepartmentMap($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
    	let department_id = this.addDepartmentMapForm.value;

		let b_id = this.b_id;

		this.departmentService.updateDepartmentMap({
			department_id,
			b_id
		} as Department)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	addBio($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let about = this.addBioForm.value.about.trim();
		let b_id = this.b_id;

		this.businessMasterService.updateBio({
			about,
			b_id
		} as BusinessMaster)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	openBusinessAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
			} as BusinessMaster).subscribe(
			(data) => {
				if (data.status == 200) {
					this.addBusinessForm.controls["email"].setValue(data.data.email);
					this.addBusinessForm.controls["e_phone"].setValue(data.data.e_phone);
					this.addBusinessForm.controls["address"].setValue(data.data.address);
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openAmenitiesAdd(content: any): void {
		let b_id = this.b_id;
		let role_id = this.role_type;
		
		this.amenityService.getAmenityByRoleId({
			role_id
			} as Amenity).subscribe(
			(data) => {
				if (data.status == 200) {
					this.amenityList = data.data;
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	onCheckboxChange(e: any) {
		const checkArray: FormArray = this.addAmenityMapForm.get('checkArray') as FormArray;
	
		if (e.target.checked) {
		checkArray.push(new FormControl(e.target.value));
		} else {
		let i: number = 0;
		checkArray.controls.forEach((item: any) => {
			if (item.value == e.target.value) {
			checkArray.removeAt(i);
			return;
			}
			i++;
		});
		}
		console.log(checkArray);
	}

	openDepartmentAdd(content: any): void {
		
		this.departmentService.getAllDepartment().subscribe(
			(data) => {
				if (data.status == 200) {
					this.departmentList = data.data;
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	onDepartmentChange(e: any) {
		const departmentCheckArray: FormArray = this.addDepartmentMapForm.get('departmentCheckArray') as FormArray;
	
		if (e.target.checked) {
			departmentCheckArray.push(new FormControl(e.target.value));
		} else {
			let i: number = 0;
			departmentCheckArray.controls.forEach((item: any) => {
				if (item.value == e.target.value) {
					departmentCheckArray.removeAt(i);
					return;
				}
				i++;
			});
		}
		console.log(departmentCheckArray);
	}

	addBusiness($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let email = this.addBioForm.value.email.trim();
		let e_phone = this.addBioForm.value.e_phone.trim();
		let address = this.addBioForm.value.address.trim();

		this.businessMasterService.updateBusinessInfo({
			email,
			e_phone,
			address
		} as BusinessMaster)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				setTimeout(() => {
				document.getElementById("addBannerModalClose")?.click();
					this.addBioForm.reset();
				}, 2000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	openContactAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
			} as BusinessMaster).subscribe(
			(data) => {
				if (data.status == 200) {
					this.addContactForm.controls["c_name"].setValue(data.data.c_name);
					this.addContactForm.controls["c_email"].setValue(data.data.c_email);
					this.addContactForm.controls["c_phone"].setValue(data.data.c_phone);
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addContact($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let c_name = this.addContactForm.value.c_name.trim();
		let c_phone = this.addContactForm.value.c_phone.trim();
		let c_email = this.addContactForm.value.c_email.trim();
		let b_id = this.b_id;

		this.businessMasterService.updateContactInfo({
			c_name,
			c_phone,
			c_email,
			b_id
		} as BusinessMaster)
		.subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
	
	makeYourself($event: any): void{
		let make_public = $event.currentTarget.checked;
		let b_id = this.b_id;

		this.businessMasterService
		.updateMakePublic({
			make_public,
			b_id
		} as BusinessMaster)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
	
	newsletter($event:any): void{
		let newsletter = $event.currentTarget.checked;
		let b_id = this.b_id;

		this.businessMasterService
		.updateNewsletter({
			newsletter,
			b_id
		} as BusinessMaster)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	latestUpdate ($event:any): void{
		let latest_update = $event.currentTarget.checked;
		let b_id = this.b_id;

		this.businessMasterService
		.updateLatestUpdate({
			latest_update,
			b_id
		} as BusinessMaster)
		.subscribe(
			(data) => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
	
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}
