import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from '../auth.guard';

import { ProfileNewRoutingModule } from './profile-new-routing.module';
import { ProfileNewComponent } from './profile-new.component';

@NgModule({
  imports: [
    CommonModule,
    ProfileNewRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ProfileNewComponent],
  providers: [AuthGuard]
})
export class ProfileNewModule { }
