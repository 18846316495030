<div class="breadcrumb_section brd-crum ptb-50">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="site-search">
          <div class="product_search">
            <form class="search_form_2" role="search">
              <div class="select_mate" data-mate-select="active">
                <select name="" onchange="" onclick="return false;" id="c">
                  <option value="">All Categories</option>
                  <option
                    *ngFor="
                      let articleCategory of articleCategoryList;
                      let i = index
                    "
                    value="{{ articleCategory.id }}"
                  >
                    {{ articleCategory.name }}
                  </option>
                </select>
                <p class="selection_option" onclick="open_select(this)"></p>
                <span onclick="open_select(this)" class="icon_select_mate">
                  <svg
                    fill="#000000"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                    <path d="M0-.75h24v24H0z" fill="none" />
                  </svg>
                </span>
                <div class="cont_list_select_mate">
                  <ul class="cont_select_int"></ul>
                </div>
              </div>
              <div class="outer">
                <div class="inner"></div>
              </div>
              <div
                class="autocomplete auto-compl"
                id="autocmp"
                style="width: 500px"
              >
                <input
                  id="myInput"
                  type="text"
                  name="myCountry"
                  placeholder="Search Here..."
                />
                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  (click)="filterArticle()"
                ></i>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="news gray_bg ptb-115">
  <div class="container">
    <div class="top-blog">
      <div class="row">
        <div class="col-md-3">
          <div class="side-category">
            <div class="list-cat">
              <h2>Category List</h2>
              <form
                id="hidden-search-box"
                class="navbar-form hidden-search-box"
                role="search"
              >
                <div class="traingle"></div>
                <div class="input-group add-on">
                  <input
                    class="form-control addon-text-box"
                    placeholder="Search here..."
                    name="s"
                    type="text"
                  />
                  <button type="submit" class="input-group-btn addon-btn">
                    <i class="fa fa-search flip-icon"></i>
                  </button>
                </div>
              </form>
            </div>
            <ul>
              <li
                *ngFor="let articleCategoryF of articleCategory; let i = index"
                (click)="filterArticleCategory(articleCategoryF.id)"
              >
                <a href="javascript:;">{{ articleCategoryF.name }}</a
                ><span>({{ articleCategoryF.total_article }})</span>
              </li>
            </ul>
            <div class="content">
              <div class="buttons">
                <div
                  (click)="showAllCategoryModal(showAllCategory)"
                  class="button"
                >
                  Show More <i class="fa-solid fa-angles-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9" *ngIf="articleList">
          <div class="lset" *ngFor="let article of articleList; let i = index">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/{{ article.slug }}"
                    ><img src="{{ article.featured_image }}"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      href="article/{{ article.slug }}"
                      class="article-ft-size"
                      >{{ article.title }}</a
                    >
                  </h2>
                  <p [innerHTML]="article.description | slice : 0 : 150"></p>
                  <a href="article/{{ article.slug }}" class="article-read-more"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9" *ngIf="!articleList">
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-tick-fever-in-dogs"
                    ><img
                      src="https://resources.donorzilla.com/article/7651736333503symptoms%20of%20tick%20fever%20in%20dogs.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-tick-fever-in-dogs"
                      >Symptoms of Tick Fever in Dogs: Causes, Treatment, and
                      Prevention</a
                    >
                  </h2>
                  <p>
                    Tick fever, or canine ehrlichiosis, is a serious disease
                    transmitted by ticks. If left untreated, it can lead to
                    severe complications, making early
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-tick-fever-in-dogs"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-period-coming"
                    ><img
                      src="https://resources.donorzilla.com/article/141736333040symptoms%20of%20period%20coming.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-period-coming"
                      >Symptoms of Period Coming: What to Expect and How to
                      Manage</a
                    >
                  </h2>
                  <p>
                    Periods are a natural part of a woman’s life, but the
                    symptoms leading up to menstruation can vary greatly.
                    Recognizing the <strong>symptoms of per</strong>
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-period-coming"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-parvo-in-dogs"
                    ><img
                      src="https://resources.donorzilla.com/article/4721735638948symptoms%20of%20parvo%20in%20dogs.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-parvo-in-dogs"
                      >Symptoms of Parvo in Dogs: Early Signs, Causes, and
                      Treatment</a
                    >
                  </h2>
                  <p>
                    Canine parvovirus (parvo) is a highly contagious and
                    potentially life-threatening disease affecting dogs,
                    especially puppies. Recognizing the
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-parvo-in-dogs"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-mumps-in-adults-female"
                    ><img
                      src="https://resources.donorzilla.com/article/1701735638515symptoms%20of%20mumps%20in%20adults%20female.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-mumps-in-adults-female"
                      >Symptoms of Mumps in Adults Female: Early Signs, Causes,
                      and Treatment</a
                    >
                  </h2>
                  <p>
                    Mumps, a contagious viral infection, was once common among
                    children but can affect adults too. Adult females who
                    contract mumps may experience uniq
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-mumps-in-adults-female"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-loo"
                    ><img
                      src="https://resources.donorzilla.com/article/9491735638205symptoms%20of%20loo.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a class="article-ft-size" href="article/symptoms-of-loo"
                      >Symptoms of Loo: Causes, Prevention, and Treatment</a
                    >
                  </h2>
                  <p>
                    Heatstroke, commonly known as loo in many parts of South
                    Asia, is a serious condition caused by prolonged exposure to
                    high temperatures and dehydra
                  </p>
                  <a class="article-read-more" href="article/symptoms-of-loo"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-colon-cancer"
                    ><img
                      src="https://resources.donorzilla.com/article/9221735637947symptoms%20of%20colon%20cancer.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-colon-cancer"
                      >Symptoms of Colon Cancer: Early Warning Signs, Causes,
                      and Diagnosis</a
                    >
                  </h2>
                  <p>
                    Colon cancer, also known as colorectal cancer, affects the
                    colon (large intestine) or rectum. Early detection is
                    crucial as the disease is treatabl
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-colon-cancer"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-blocked-tubes"
                    ><img
                      src="https://resources.donorzilla.com/article/1111735288021symptoms%20of%20blocked%20tubes.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-blocked-tubes"
                      >Symptoms of Blocked Tubes: Causes, Diagnosis, and
                      Treatment</a
                    >
                  </h2>
                  <p>
                    Blocked fallopian tubes, also known as tubal obstruction,
                    are one of the most common causes of infertility in women.
                    Fallopian tubes play a crucial
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-blocked-tubes"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-aids-in-females"
                    ><img
                      src="https://resources.donorzilla.com/article/4491735287551symptoms%20of%20aids%20in%20female.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-aids-in-females"
                      >Symptoms of AIDS in Females: Early Signs, Diagnosis, and
                      Treatment</a
                    >
                  </h2>
                  <p>
                    Acquired Immunodeficiency Syndrome (AIDS) is a severe
                    condition caused by the Human Immunodeficiency Virus (HIV),
                    which weakens the immune system,
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-aids-in-females"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-hypertension"
                    ><img
                      src="https://resources.donorzilla.com/article/6081735287315symptoms%20of%20hypertension.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-hypertension"
                      >Symptoms of Hypertension: Early Signs, Diagnosis, and
                      Treatment</a
                    >
                  </h2>
                  <p>
                    Hypertension, commonly known as high blood pressure, is a
                    chronic condition that affects millions worldwide. It occurs
                    when the force of blood agai
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-hypertension"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="lset">
            <div class="row">
              <div class="col-md-5">
                <div class="latest-inne">
                  <a href="article/symptoms-of-stomach-cancer"
                    ><img
                      src="https://resources.donorzilla.com/article/5901735286920symptoms%20of%20stomach%20cancer.png"
                  /></a>
                </div>
              </div>
              <div class="col-md-7">
                <div class="latest-inne-txt">
                  <h2>
                    <a
                      class="article-ft-size"
                      href="article/symptoms-of-stomach-cancer"
                      >Symptoms of Stomach Cancer: Early Signs, Diagnosis, and
                      Treatment</a
                    >
                  </h2>
                  <p>
                    Stomach cancer, also known as gastric cancer, develops in
                    the lining of the stomach. It is a condition that often
                    progresses silently, making early
                  </p>
                  <a
                    class="article-read-more"
                    href="article/symptoms-of-stomach-cancer"
                    >Read More <i class="fa-solid fa-angles-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <!---->
        </div>
      </div>
    </div>
  </div>
</section>
<div class="col-12 mt-5">
  <div class="page_pagination">
    <ul>
      <li
        (click)="paginationChange(i)"
        class="{{ i == page ? 'active' : '' }}"
        *ngFor="let item of [].constructor(totalcounter); let i = index"
      >
        <a href="javascript:;">{{ i + 1 }}</a>
      </li>
    </ul>
  </div>
</div>
<br /><br />
<ng-template #showAllCategory let-modal>
  <div class="mdl-middle">
    <h2 class="text-center">All Categories</h2>
    <div class="row">
      <div
        class="col-md-4"
        *ngFor="let allArticle of allArticleCategory; let i = index"
        (click)="filterArticleCategory(allArticle.id)"
      >
        <p>
          {{ allArticle.name }}<span> ({{ allArticle.total_article }})</span>
        </p>
      </div>
    </div>
  </div>
</ng-template>
