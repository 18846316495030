import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';

import { RoleService } from "../services/role.service";

import { BusinessMaster } from '../models/businessMaster';

import { BusinessMasterService } from "../services/businessMaster.service";

@Component({
	selector: 'app-profile-new',
	templateUrl: './profile-new.component.html',
	styleUrls: [
		'./profile-new.component.css'
	]
})
export class ProfileNewComponent implements OnInit {
	currentYear: number = new Date().getFullYear();
	
	validPattern = "^[a-zA-Z0-9]{30}$"; // alphanumeric exact 10 letters

	profileForm = new FormGroup({
		role_type: new FormControl('', [Validators.required]),
		name: new FormControl('', [Validators.required, Validators.pattern(this.validPattern)]),
		myaddress: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
		e_phone: new FormControl('', [Validators.required]),
		c_name: new FormControl('', [Validators.required]),
		c_email: new FormControl('', [Validators.required]),
		c_phone: new FormControl('', [Validators.required]),
		about: new FormControl('', [Validators.required]),
	});

	get f() {
		return this.profileForm.controls;
	}
	
	user_id = 0;
	role_type = 0;

	roleList: any;
	pId: any;
	title: any;
	
	constructor(
		private businessMasterService: BusinessMasterService,
		private roleService: RoleService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.pId = this.route.snapshot.paramMap.get('id');
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
			}
			this.getRoleType();
		}
	}

	getRoleType(): void {
		this.roleList = [];

		this.roleService.getRole().subscribe(
		(data) => {
			this.roleList = data.data;
		});
	}

	onChangeSelect($event: any){
		let text = $event.target.options[$event.target.options.selectedIndex].text;
		this.title = text;
	}

	profileNew($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let role_type = this.profileForm.value.role_type;
		let name = this.profileForm.value.name;
		let email = this.profileForm.value.email;
		let phone = this.profileForm.value.phone;
		let e_phone = this.profileForm.value.e_phone;
		let c_name = this.profileForm.value.c_name;
		let c_email = this.profileForm.value.c_email;
		let c_phone = this.profileForm.value.c_phone;
		let about = this.profileForm.value.about;
		let address = $('#address').val();
		let latitude = $('#latitude').val();
		let longitude = $('#longitude').val();
		let city = $('#city').val();
		let state = $('#state').val();
		let country = $('#country').val();
		
		let user_id = 0;
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			user_id = userArray.data.id;
		}

		this.businessMasterService.addChildBusinessMaster({ 
			name,
			address,
			email,
			phone,
			e_phone,
			c_name,
			c_email,
			c_phone,
			about,
			latitude,
			longitude,
			user_id,
			role_type,
			city,
			state,
			country
			} as BusinessMaster)
			.subscribe(
				data => {
					if (data.status == 200) {
						const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						successMessage?.click();
						setTimeout(() => {
							this.router.navigate(['thankyou']);
						}, 2000);
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}
}
