import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Article } from "../models/article";
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: "root",
})
export class ArticleService {
    
    private getArticleBySlugUrl = environment.baseURL + "article/getArticleBySlug";
    private getFilterArticleUrl = environment.baseURL + "article/getFilterArticle";
    private getAllArticlePaginateUrl = environment.baseURL + "article/getAllArticlePaginate";
    private getAllArticleUrl = environment.baseURL + "article/getAllArticle";

    private getHttpOptions() {
        var currentUser = localStorage.getItem("currentUser");    
        var token = '';
        if (currentUser) {
          var userArray = JSON.parse(currentUser);
          token = userArray.data.json_token;
        }
        return {
            headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : '' }),
        };
    }

    constructor(private http: HttpClient) {}
    
    getArticleBySlug(slug: any): Observable<Article> {
        return this.http
            .post<Article>(this.getArticleBySlugUrl, slug, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }
    
    getFilterArticle(params: any): Observable<Article> {
        return this.http
            .post<Article>(this.getFilterArticleUrl, params, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }
    
    getAllArticlePaginate(start: any, limit: any): Observable<Article> {
        let params = {'start': start, 'limit': limit};
        return this.http
            .post<Article>(this.getAllArticlePaginateUrl, params, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }

    getAllArticle(): Observable<Article> {
        return this.http
            .get<Article>(this.getAllArticleUrl, this.getHttpOptions())
            .pipe(map((articleList) => articleList));
    }
}
