import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Amenity } from "../models/amenity";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class AmenityService {
	
	private getAmenityUrl = environment.baseURL + "amenity/getAmenity";

	private getAmenityByRoleIdUrl = environment.baseURL + "amenity/getAmenityByRoleId";

	private updateAmenityMapUrl = environment.baseURL + "amenity/updateAmenityMap";

	private getAmenityByBusinessIdUrl = environment.baseURL + "amenity/getAmenityByBusinessId";

	private getAmenityByUserIdUrl = environment.baseURL + "amenity/getAmenityByUserId";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	  
	getAmenity(params: any): Observable<Amenity> {
		return this.http
		.post<Amenity>(this.getAmenityUrl, params, this.httpOptions)
		.pipe(
			map((amenityList) => {
			return amenityList;
			})
		);
	}
	
	getAmenityByRoleId(role_id: any): Observable<Amenity> {
		return this.http
		.post<Amenity>(this.getAmenityByRoleIdUrl, role_id, this.httpOptions)
		.pipe(
			map((amenityList) => {
			return amenityList;
			})
		);
	}
	
	updateAmenityMap(params: any): Observable<Amenity> {
		return this.http
		.post<Amenity>(this.updateAmenityMapUrl, params, this.httpOptions)
		.pipe(
			map((amenityList) => {
			return amenityList;
			})
		);
	}
	
	getAmenityByBusinessId(role_id: any): Observable<Amenity> {
		return this.http
		.post<Amenity>(this.getAmenityByBusinessIdUrl, role_id, this.httpOptions)
		.pipe(
			map((amenityList) => {
			return amenityList;
			})
		);
	}

	getAmenityByUserId(role_id: any): Observable<Amenity> {
		return this.http
		.post<Amenity>(this.getAmenityByUserIdUrl, role_id, this.httpOptions)
		.pipe(
			map((amenityList) => {
			return amenityList;
			})
		);
	}
}