import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ArticleCategory } from "../models/articleCategory";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class ArticleCategoryService {
	
	private getAllArticleCategoryUrl = environment.baseURL + "articleCategory/getAllArticleCategory";

	private getArticleCategoryWithCountUrl = environment.baseURL + "articleCategory/getArticleCategoryWithCount";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}
	
	getAllArticleCategory(): Observable<ArticleCategory> {
		return this.http
		.get<ArticleCategory>(this.getAllArticleCategoryUrl, this.httpOptions)
		.pipe(
			map((articleList) => {
			return articleList;
			})
		);
	}

	getArticleCategoryWithCount(): Observable<ArticleCategory> {
		return this.http
		.get<ArticleCategory>(this.getArticleCategoryWithCountUrl, this.httpOptions)
		.pipe(
			map((articleList) => {
			return articleList;
			})
		);
	}
}