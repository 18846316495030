import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BloodType } from "../models/bloodType";

import { BloodRequest } from "../models/bloodRequest";

import { FormGroup, FormControl, Validators } from "@angular/forms";

import { BloodTypeService } from "../services/bloodType.service";

import { BloodRequestService } from "../services/bloodRequest.service";

@Component({
	selector: 'app-edit-blood-request',
	templateUrl: './edit-blood-request.component.html',
	styleUrls: [
		'./edit-blood-request.component.css'
	]
})
export class EditBloodRequestComponent implements OnInit {

	updateBloodRequestForm = new FormGroup({
		blood_type: new FormControl("", [Validators.required]),
		patient_name: new FormControl("", [Validators.required]),
		blood_group: new FormControl("", [Validators.required]),
		phone_number: new FormControl("", [Validators.required]),
		required_date: new FormControl("", [Validators.required]),
		units: new FormControl("", [Validators.required]),
		location: new FormControl("", [Validators.required]),
		notes: new FormControl("", [Validators.required]),
		is_critical: new FormControl("", [Validators.required]),
		request_id: new FormControl("", [Validators.required]),
	});
	
	user_id:any;
	bloodTypeList: any;
	bloodRequestId: any;

	errorMessage = "";
	successMessage = "";
		
	constructor(
		private bloodTypeService: BloodTypeService,
		private bloodRequestService: BloodRequestService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
		    this.bloodRequestId = this.route.snapshot.paramMap.get('id');
		    this.getAllBloodType();
		    this.getBloodRequestById(this.bloodRequestId);
		}
	}
	
	getAllBloodType(): void {
		this.bloodTypeService.getAllBloodType()
		.subscribe(
			data => {
				this.bloodTypeList = data.data;
			}
		);
	}

	getBloodRequestById(bloodRequestId: any): void {
		this.bloodRequestService.getBloodRequestById(bloodRequestId).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateBloodRequestForm.controls["patient_name"].setValue(data.data.patient_name);
					this.updateBloodRequestForm.controls["blood_type"].setValue(data.data.blood_type);
					this.updateBloodRequestForm.controls["phone_number"].setValue(data.data.phone_number);
					this.updateBloodRequestForm.controls["location"].setValue(data.data.location);
					this.updateBloodRequestForm.controls["required_date"].setValue(data.data.required_date);
					this.updateBloodRequestForm.controls["units"].setValue(data.data.units);
					this.updateBloodRequestForm.controls["notes"].setValue(data.data.notes);
					this.updateBloodRequestForm.controls["is_critical"].setValue(data.data.is_critical);
					this.updateBloodRequestForm.controls["request_id"].setValue(data.data.id);
				}
			}
		);
	}

	updateBloodRequestDetails($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let patient_name = this.updateBloodRequestForm.value.patient_name;
		let blood_type = this.updateBloodRequestForm.value.blood_type;
		let phone_number = this.updateBloodRequestForm.value.phone_number;
		let units = this.updateBloodRequestForm.value.units;
		let is_critical = this.updateBloodRequestForm.value.is_critical;
		let required_date = this.updateBloodRequestForm.value.required_date;
		let location = this.updateBloodRequestForm.value.location;
		let notes = this.updateBloodRequestForm.value.notes;
        let request_id = this.updateBloodRequestForm.value.request_id;

		this.bloodRequestService.updateBloodRequestDetails({
			blood_type,
			patient_name,
			phone_number,
			units,
			is_critical,
			required_date,
			location,
			notes,
			request_id,
		} as BloodRequest)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
}
