import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LogoutComponent } from './logout.component';

const routes: Routes = [
	{
		path: 'logout',
		component: LogoutComponent,
		data: { 
			showHeader: false,
			showSidebar: false,
			showFooter: false,
			showBusinessHeader: false,
			showBusinessSidebar: false,
			showBusinessFooter: false,
		   }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LogoutRoutingModule { }