import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { NotificationService } from "../services/notification.service";

import { BusinessMasterService } from "../services/businessMaster.service";
import { BusinessImageService } from "../services/businessImage.service";

import { BusinessMaster } from '../models/businessMaster';
import { BusinessImage } from '../models/businessImage';

class ImageSnippet {
	constructor(public src: string, public file: File) { }
}

@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: [
		'./gallery.component.css'
	]
})
export class GalleryComponent implements OnInit {

	addBusinessForm = new FormGroup({
		fileSource: new FormControl("", [Validators.required]),
  	});

	deleteGalleryForm = new FormGroup({
		imageId: new FormControl("", [Validators.required]),
		
  	});

  	get f() {
		return this.addBusinessForm.controls;
	}

	b_id: any;
	user_id = 0;
	role_type = 0;
	notificationCounter = 0;
	type: any;
	selectedFile: any = ImageSnippet;

	imageList: any;
	detailedList: any;
	businessList: any;
	notificationList: any;
	images : any = [];
 
	errorMessage = "";
	successMessage = "";
	closeResult = "";
	formErrorMessages = {
		emailError : "",
		e_phoneError : "",
		addressError : "",
		c_nameError : "",
		c_emailError : "",
		c_phoneError : "",
		aboutError : "",
	};

	constructor(
		private sanitizer: DomSanitizer,
		private businessMasterService: BusinessMasterService,
		private notificationService: NotificationService,
		private businessImageService: BusinessImageService,
		private route: ActivatedRoute,
				private modalService: NgbModal,
	
		private router: Router
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.b_id = userArray.data.business_id;
			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
				return;
			}
			this.getRoleDetailsById(this.b_id);
			this.getGalleryImagesById(this.b_id);
		}
	}

	openGalleryDelete(content: any, imageId: any): void {
		this.deleteGalleryForm.controls["imageId"].setValue(imageId);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);	
	}

	getGalleryImagesById(b_id: any): void {
		this.businessMasterService.getGalleryImagesById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.imageList = data.data;
				}
			);
	}
	
	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.type = data.data.type;
				}
			);
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (imageInput.files && imageInput.files[0]) {
			var filesAmount = imageInput.files.length;
			for (let i = 0; i < filesAmount; i++) {
				var reader = new FileReader();

				reader.onload = (event:any) => {
					this.images.push(event.target.result); 
	
					this.addBusinessForm.patchValue({
						fileSource: this.images
					});
				}
				reader.readAsDataURL(imageInput.files[i]);
			}
		}
	}
	
	getRoleDetails(user_id: any): void {
		this.businessMasterService.getBusniessMasterByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.detailedList = data.data;
				this.type = data.data.type;
				this.b_id = data.data.b_id;
			}
		);
	}

	getGalleryImages(user_id: any): void {
		this.businessMasterService.getGalleryImagesByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.imageList = data.data;
			}
		);
	}

	addBusinessImages($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		
		let fileSource = this.addBusinessForm.value.fileSource;
		let b_id = this.b_id;

		if (!fileSource || fileSource.length === 0) {
			const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
			let dynamicMessage: any = document.getElementById("dynamic_message") as HTMLInputElement | null;
			dynamicMessage.value = "Please select an image before uploading.";
			errorMessage?.click();
			($event.submitter as HTMLButtonElement).disabled = false;
			return; 
		}

		this.businessImageService.uploadImage(fileSource, b_id).subscribe(
			data => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			error => {
				this.errorMessage = error;
			}
		)
	}
	
	deleteGallery($event: any): void {
	
			($event.submitter as HTMLButtonElement).disabled = true;
			let imageId = this.deleteGalleryForm.value.imageId;
	
			this.businessImageService.deleteGallery({
				imageId,
			} as BusinessImage)
			.subscribe(
				(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 4000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
	
				}
				},
				(error) => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
		}
	

	
		private getDismissReason(reason: any): string {
			if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
			} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
			} else {
			return `with: ${reason}`;
			}
		}
}
