import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { Article } from '../models/article';

import { ArticleService } from "../services/article.service";

@Component({
	selector: 'app-article-details',
	templateUrl: './article-details.component.html',
	styleUrls: [
		'./article-details.component.css'
	],
	encapsulation: ViewEncapsulation.None,
})
export class ArticleDetailsComponent implements OnInit {

	slug: any;
	articleDetails: any;

	constructor(
		private route: ActivatedRoute,
		private articleService: ArticleService,
		private title: Title,
		private meta: Meta
	) { }

	ngOnInit(): void {
		this.slug = this.route.snapshot.paramMap.get('id');
		this.getArticleBySlug(this.slug);
	}

	getArticleBySlug(slug: any): void {
		this.articleService.getArticleBySlug({ 
			slug
			} as Article)
			.subscribe(
				data => {
					this.articleDetails = data.data;

					this.title.setTitle(data.data.meta_title); 

					this.meta.updateTag(
					{
						name:'keywords',
						content: data.data.meta_keywords
					}); 

					this.meta.updateTag(
					{
						name:'description',
						content: data.data.meta_description
					}); 
				}
			);
	}

}