import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Department } from "../models/department";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class DepartmentService {
	
	private getAllDepartmentUrl = environment.baseURL + "department/getAllDepartment";

	private updateDepartmentMapUrl = environment.baseURL + "department/updateDepartmentMap";

	private getDepartmentByBusinessIdUrl = environment.baseURL + "department/getDepartmentByBusinessId";

	private getDepartmentByUserIdUrl = environment.baseURL + "department/getDepartmentByUserId";

	private getHttpOptions () {
		var currentUser = localStorage.getItem("currentUser");    
		var token = '';
		if (currentUser) {
		  var userArray = JSON.parse(currentUser);
		  token = userArray.data.json_token;
		}
		return {
			headers: new HttpHeaders({ "Content-Type": "application/json", 'token': token ? token : ''  }),
		};
	};

  	constructor(private http: HttpClient) {}
	
	  getAllDepartment(): Observable<Department> {
		return this.http
			.get<Department>(this.getAllDepartmentUrl, this.getHttpOptions())
			.pipe(map((departmentList) => departmentList));
	}
	
	updateDepartmentMap(params: any): Observable<Department> {
		return this.http
			.post<Department>(this.updateDepartmentMapUrl, params, this.getHttpOptions())
			.pipe(map((departmentList) => departmentList));
	}

	getDepartmentByUserId(params: any): Observable<Department> {
		return this.http
			.post<Department>(this.getDepartmentByUserIdUrl, params, this.getHttpOptions())
			.pipe(map((departmentList) => departmentList));
	}

	getDepartmentByBusinessId(b_id: any): Observable<Department> {
		return this.http
			.post<Department>(this.getDepartmentByBusinessIdUrl, b_id, this.getHttpOptions())
			.pipe(map((departmentList) => departmentList));
	}

	// getAmenity(params: any): Observable<Department> {
	// 	return this.http
	// 	.post<Department>(this.getAmenityUrl, params, this.httpOptions)
	// 	.pipe(
	// 		map((departmentList) => {
	// 		return departmentList;
	// 		})
	// 	);
	// }
	
	// getAmenityByRoleId(role_id: any): Observable<Department> {
	// 	return this.http
	// 	.post<Department>(this.getAmenityByRoleIdUrl, role_id, this.httpOptions)
	// 	.pipe(
	// 		map((departmentList) => {
	// 		return departmentList;
	// 		})
	// 	);
	// }
	
	
	
	// getAmenityByBId(role_id: any): Observable<Department> {
	// 	return this.http
	// 	.post<Department>(this.getAmenityByBIdUrl, role_id, this.httpOptions)
	// 	.pipe(
	// 		map((departmentList) => {
	// 		return departmentList;
	// 		})
	// 	);
	// }

}
