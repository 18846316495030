<div class="breadcrumb_section brd-crum ptb-50">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <div class="site-search">
                    <div class="product_search">
                        <form class="search_form_2" role="search">
                            <div class="select_mate" data-mate-select="active">
                                <select name="" onchange="" onclick="return false;" id="c">
                                    <option value="">All Categories </option>
                                    <option *ngFor="let articleCategory of articleCategoryList;let i = index" value="{{articleCategory.id}}">{{articleCategory.name}}</option>
                                </select>
                                <p class="selection_option" onclick="open_select(this)"></p>
                                <span onclick="open_select(this)" class="icon_select_mate">
                                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                                        <path d="M0-.75h24v24H0z" fill="none" />
                                    </svg>
                                </span>
                                <div class="cont_list_select_mate">
                                    <ul class="cont_select_int"></ul>
                                </div>
                            </div>
                            <div class="outer">
                                <div class="inner"></div>
                            </div>
                            <div class="autocomplete auto-compl" id="autocmp" style="width: 500px;">
                                <input id="myInput" type="text" name="myCountry" placeholder="Search Here..." />
                                <i class="fa fa-search" aria-hidden="true" (click)="filterArticle()"></i>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="news gray_bg ptb-115">
    <div class="container">
        <div class="top-blog">
            <div class="row">
                <div class="col-md-3">
                    <div class="side-category">
                        <div class="list-cat">
                            <h2>Category List</h2>
                            <form id="hidden-search-box" class="navbar-form hidden-search-box" role="search">
                                <div class="traingle"></div>
                                <div class="input-group add-on">
                                    <input class="form-control addon-text-box" placeholder="Search here..." name="s"  type="text">
                                    <button type="submit" class="input-group-btn addon-btn">
                                        <i class="fa fa-search flip-icon"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <ul>
                            <li *ngFor="let articleCategoryF of articleCategory;let i = index"  (click)="filterArticleCategory(articleCategoryF.id)"><a href="javascript:;">{{articleCategoryF.name}}</a><span>({{articleCategoryF.total_article}})</span></li>
                        </ul>
                        <div id="modal-container">
                            <div class="modal-background">
                                <div class="modal" style="transform: none;">
                                    <h2 class="text-center">All Categories</h2>
                                    <div class="row">
                                        <div class="col-md-4" *ngFor="let allArticle of allArticleCategory;let i = index"  (click)="filterArticleCategory(allArticle.id)">
                                            <p>{{allArticle.name}}<span> ({{allArticle.total_article}})</span></p>
                                        </div>
                                    </div>
                                    <div class="closebtn" id="btncls"><i class="fa fa-times" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="buttons">
                                <div id="one" class="button">Show More <i class="fa-solid fa-angles-right"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9" *ngIf="articleList">
                    <div class="lset" *ngFor="let article of articleList;let i = index">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="latest-inne">
                                    <a href="article/{{article.slug}}"><img src="{{article.featured_image}}"></a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="latest-inne-txt">
                                    <h2><a href="article/{{article.slug}}" class="article-ft-size">{{article.title}}</a></h2>
                                    <p [innerHTML]="article.description | slice:0:150"></p>
                                    <a href="article/{{article.slug}}" class="article-read-more">Read More <i class="fa-solid fa-angles-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9" *ngIf="!articleList">
                    <div class="lset">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="latest-inne-txt">
                                    <h2>No data found!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="col-12 mt-5">
    <div class="page_pagination">
        <ul>
            <li (click)="paginationChange(i)" class="{{i == page ? 'active' : ''}}" *ngFor="let item of [].constructor(totalcounter); let i = index"><a href="javascript:;">{{i+1}}</a></li>
        </ul>
    </div>
</div>
<br><br>