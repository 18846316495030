<aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-whi" id="sidenav-main">
    <div class="sidenav-header">
          <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
          <a class="navbar-brand m-0" href="dashboard" target="_blank">
            <img src="https://resources.donorzilla.com/statics/images/logo.png" class="navbar-brand-img h-100" alt="main_logo">
          </a>
    </div>
    <hr class="horizontal light mt-0 mb-2">
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
          <ul class="navbar-nav">
             <li class="nav-item">
                  <a class="nav-link active  text-white bg-gradient-primary" *ngIf="!map_id" href="dashboard">
                    <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                        <i class="material-icons opacity-10">home</i>
                    </div>
                    <span class="nav-link-text ms-1">Home</span>
                  </a>
                <a class="nav-link active  text-white bg-gradient-primary" *ngIf="map_id" href="dashboard?b_id={{map_id}}">
                    <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                        <i class="material-icons opacity-10">home</i>
                    </div>
                    <span class="nav-link-text ms-1">Home</span>
                  </a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-darkk" *ngIf="!map_id" href="gallery">
                      <div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
                        <i class="material-icons opacity-10">image</i>
                      </div>
                      <span class="nav-link-text ms-1">Add Gallery</span>
                </a>
                <a class="nav-link text-darkk" *ngIf="map_id" href="gallery?b_id={{map_id}}">
                    <div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
                      <i class="material-icons opacity-10">image</i>
                    </div>
                    <span class="nav-link-text ms-1">Add Gallery</span>
                  </a>
              </li>
            <!-- <li class="nav-item">
                <a class="nav-link text-darkk" *ngIf="!map_id" href="appointment-settings">
                      <div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
                        <i class="material-icons opacity-10">calendar_month</i>
                      </div>
                      <span class="nav-link-text ms-1">Appointment Settings</span>
                </a>
                <a class="nav-link text-darkk" *ngIf="map_id" href="appointment-settings?b_id={{map_id}}">
                    <div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
                      <i class="material-icons opacity-10">calendar_month</i>
                    </div>
                    <span class="nav-link-text ms-1">Appointment Settings</span>
                  </a>
              </li>
            <li class="nav-item">
                <a class="nav-link text-darkk" *ngIf="!map_id" href="doctor-list">
                      <div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
                        <i class="material-icons opacity-10">health_and_safety</i>
                      </div>
                      <span class="nav-link-text ms-1">Doctor List</span>
                </a>
                <a class="nav-link text-darkk" *ngIf="map_id" href="doctor-list?b_id={{map_id}}">
                    <div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
                      <i class="material-icons opacity-10">health_and_safety</i>
                    </div>
                    <span class="nav-link-text ms-1">Doctor List</span>
                  </a>
              </li> -->
          </ul>
    </div>
    <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
        <a href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
            <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
            </div>
        </a>
    </li>
    <div class="sidenav-footer position-absolute w-100 bottom-0 " *ngIf="detailedList">
          <div class="mx-3">
            <a class="btn bg-gradient-primary w-100" href="profile_new/{{detailedList.details.id}}" type="button">Add new Business</a>
          </div>
    </div>
</aside>