import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessHeaderComponent } from './business-header/business-header.component';
import { BusinessSidebarComponent } from './business-sidebar/business-sidebar.component';
import { BusinessFooterComponent } from './business-footer/business-footer.component';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    BusinessHeaderComponent,
    BusinessSidebarComponent,
    BusinessFooterComponent
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    BusinessHeaderComponent,
    BusinessSidebarComponent,
    BusinessFooterComponent
  ]
})
export class LayoutModule { }
