import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-about-us',
	templateUrl: './about-us.component.html',
	styleUrls: [
		'./about-us.component.css'
	]
})
export class AboutUsComponent implements OnInit {

	constructor(private title: Title, private meta: Meta) {}

	ngOnInit()
	{ 
		this.title.setTitle('About DonorZilla | What we do'); 

		this.meta.updateTag(
		{
			name:'keywords',
			content:'about donorzilla'
		}); 

		this.meta.updateTag(
		{
			name:'description',
			content:'DonorZilla connects blood donors with those in need, while helping you find hospitals, doctors, clinics, and path labs with ease. Learn more about our mission to make healthcare accessible through our innovative app and website.'
		}); 
	}

}