
<section id="donor-profile">
    <div class="container">
        <div class="prof-top-img">
            <img src="https://resources.donorzilla.com/statics/images/prof.png" />
            <div class="profile-imaghe">
                <img src="https://resources.donorzilla.com/statics/images/profile-img.png" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-9">
                <div class="left-side-inner">
                    <h2>{{detailedList.details.name}}</h2>
                    <span>{{detailedList.type}}</span>

                    <span>4/5 <img src="https://resources.donorzilla.com/statics/images/star.png" /></span>

                    <br />
                    <p class="text-prof">{{detailedList.details.about}}</p>

                    <div class="prof-inr-btn">
                        <a href="tel:{{detailedList.details.phone}}" class="first-call-us">Call Us</a>
                        <a href="https://maps.google.com/?q={{detailedList.details.latitude}},{{detailedList.details.longitude}}&z=8" target="_blank" class="second-direction">Get Direction</a>
                    </div>
    
                    <br />

                    <div class="tabs-container">
                        <div class="tabs tb-tab">
                            <div class="tab-links">
                                <button class="tab-link active" data-tab="tab-1">Details</button>
                                <button class="tab-link" data-tab="tab-2">Services</button>
                                <button class="tab-link" data-tab="tab-3">Gallery</button>
                                <button class="tab-link" data-tab="tab-4">Reviews</button>
                            </div>

                            <div class="tab-content active" id="tab-1">
                                <div class="tab-inner-tab">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h2>Email</h2>
                                            <p>{{detailedList.details.email}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Phone</h2>
                                            <p>{{detailedList.details.phone}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Emergency Phone</h2>
                                            <p>{{detailedList.details.e_phone}}</p>
                                        </div>
                                    </div>

                                    <br />
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h2>Contact Person Name</h2>
                                            <p>{{detailedList.details.c_name}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Contact Person Email</h2>
                                            <p>{{detailedList.details.c_email}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Contact Person Phone</h2>
                                            <p>{{detailedList.details.c_phone}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-content" id="tab-2">
                                <div class="tab-inner-tab">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h2>Field Name</h2>
                                            <p>Lorem Ipsum Dolor si</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Field Name</h2>
                                            <p>Lorem Ipsum Dolor si</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Field Name</h2>
                                            <p>Lorem Ipsum Dolor si</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-content" id="tab-3">
                                <div class="tab-inner-tab">
                                    <div class="row">
                                        <div class="col-md-4" *ngFor='let image of imageList'>
                                            <img [src]="image.image">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-content" id="tab-4">
                                <div class="tab-inner-tab">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h2>Field Name</h2>
                                            <p>Lorem Ipsum Dolor si</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Field Name</h2>
                                            <p>Lorem Ipsum Dolor si</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h2>Field Name</h2>
                                            <p>Lorem Ipsum Dolor si</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="right-side-text">
                    <h2>
                        Why List Your Business<br />
                        on <strong>DonorZilla</strong>
                    </h2>

                    <div class="icon-with-text">
                        <img src="https://resources.donorzilla.com/statics/images/pro-1.png" />
                        <p>Increased visibility in the healthcare industry</p>

                        <img src="https://resources.donorzilla.com/statics/images/pro-2.png" />
                        <p>Increased visibility in the healthcare industry</p>

                        <img src="https://resources.donorzilla.com/statics/images/pro-3.png" />
                        <p>Increased visibility in the healthcare industry</p>
                    </div>

                    <div class="register-btnn">
                        <a href="#">Register Now</a>
                    </div>

                    <div class="links-imp">
                        <h2>Important Links</h2>

                        <ul>
                            <li><a href="#">Facebook Link</a></li>
                            <li><a href="#">Website Link</a></li>
                            <li><a href="#">Offers Link</a></li>
                            <li><a href="#">Mail ID</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
