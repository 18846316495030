import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { NotificationService } from "../services/notification.service";

import { BusinessMasterService } from "../services/businessMaster.service";
import { BusinessImageService } from "../services/businessImage.service";
import { DoctorService } from "../services/doctor.service";

import { BusinessMaster } from '../models/businessMaster';
import { Notification } from '../models/notification';
import { Doctor } from '../models/doctor';

class ImageSnippet {
	constructor(public src: string, public file: File) { }
}

@Component({
	selector: 'app-doctor-list',
	templateUrl: './doctor-list.component.html',
	styleUrls: [
		'./doctor-list.component.css',
	]
})
export class DoctorListComponent implements OnInit {

	addDoctorForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		department_id: new FormControl("", [Validators.required]),
		bio: new FormControl("", [Validators.required]),
  	});

	updateDoctorForm = new FormGroup({
		name: new FormControl("", [Validators.required]),
		image: new FormControl("", [Validators.required]),
		department_id: new FormControl("", [Validators.required]),
		bio: new FormControl("", [Validators.required]),
		doctorId: new FormControl("", [Validators.required]),
  	});

  	get f() {
		return this.addDoctorForm.controls;
	}

	dynamicMapUrl: any;

	map_id: any;
	b_id: any;
	user_id = 0;
	role_type = 0;
	notificationCounter = 0;
	type: any;
	selectedFile: any = ImageSnippet;

	doctorList: any;
	detailedList: any;
	businessList: any;
	notificationList: any;
	images : string[] = [];

	errorMessage = "";
	successMessage = "";
	closeResult = "";
	formErrorMessages = {
		nameError : "",
		imageError : "",
		department_idError : "",
		bioError : ""
	};

	constructor(
		private sanitizer: DomSanitizer,
		private businessMasterService: BusinessMasterService,
		private notificationService: NotificationService,
		private businessImageService: BusinessImageService,
		private doctorService: DoctorService,
		private modalService: NgbModal,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
				return;
			}
			this.b_id = this.route.snapshot.queryParams.b_id;
			if(this.b_id){
				this.getRoleDetailsById(this.b_id);
				this.getDoctorByBusinessId(this.b_id);
			} else{
				this.getRoleDetails(this.user_id);
				this.getDoctorByUserId(this.user_id);
			}
		}
	}

	openDoctorAdd(content: any): void {
		let b_id = this.b_id;

		// this.businessMasterService.getInfoByBusinessId({
		// 	b_id,
		// 	} as BusinessMaster).subscribe(
		// 	(data) => {
		// 		if (data.status == 200) {
		// 			this.addDoctorForm.controls["email"].setValue(data.data.email);
		// 			this.addDoctorForm.controls["e_phone"].setValue(data.data.e_phone);
		// 			this.addDoctorForm.controls["address"].setValue(data.data.address);
		// 		}
		// 	},
		// 	(error) => {
		// 	}
		// );

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	openDoctorEdit(content: any, doctorId: any): void {

		this.doctorService.getDoctorById({
			doctorId,
			} as Doctor).subscribe(
			(data) => {
				if (data.status == 200) {
					this.updateDoctorForm.controls["name"].setValue(data.data.name);
					this.updateDoctorForm.controls["department_id"].setValue(data.data.department_id);
					this.updateDoctorForm.controls["bio"].setValue(data.data.bio);
					this.updateDoctorForm.controls["doctorId"].setValue(data.data.id);
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addDoctor($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let name = this.addDoctorForm.value.name.trim();
		let image = this.addDoctorForm.value.image;
		let department_id = this.addDoctorForm.value.department_id.trim();
		let bio = this.addDoctorForm.value.bio.trim();
		let b_id = this.b_id;

		this.doctorService.createDoctor({
			b_id,
			name,
			image,
			bio,
			department_id
		} as Doctor)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	updateDoctor($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let name = this.updateDoctorForm.value.name.trim();
		let image = this.updateDoctorForm.value.image;
		let department_id = this.updateDoctorForm.value.department_id;
		let bio = this.updateDoctorForm.value.bio.trim();
		let doctorId = this.updateDoctorForm.value.doctorId;
		let b_id = this.b_id;

		this.doctorService.updateDoctor({
			b_id,
			name,
			image,
			doctorId,
			bio,
			department_id
		} as Doctor)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				successMessage?.click();
				setTimeout(() => {
					window.location.reload();
				}, 4000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	getDoctorByBusinessId(b_id: any): void {
		this.doctorService.getDoctorByBusinessId({ b_id } as Doctor)
			.subscribe(
				data => {
					this.doctorList = data.data;
				}
			);
	}
	
	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.type = data.data.type;
					this.dynamicMapUrl = 'https://maps.google.com/maps?q='+data.data.latitude+', '+data.data.longitude+'&z=15&output=embed';
				}
			);
	}

	processFileEdit(imageInput: any): void {
		const file: File = imageInput.files;

		if (imageInput.files && imageInput.files[0]) {
			var filesAmount = imageInput.files.length;
			for (let i = 0; i < filesAmount; i++) {
				var reader = new FileReader();

				reader.onload = (event:any) => {
					this.images.push(event.target.result); 
	
					this.updateDoctorForm.patchValue({
						image: this.images
					});
				}
				reader.readAsDataURL(imageInput.files[i]);
			}
		}
	}

	processFile(imageInput: any): void {
		const file: File = imageInput.files;

		if (imageInput.files && imageInput.files[0]) {
			var filesAmount = imageInput.files.length;
			for (let i = 0; i < filesAmount; i++) {
				var reader = new FileReader();

				reader.onload = (event:any) => {
					this.images.push(event.target.result); 
	
					this.addDoctorForm.patchValue({
						image: this.images
					});
				}
				reader.readAsDataURL(imageInput.files[i]);
			}
		}
	}

	transform() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(
		  this.dynamicMapUrl
		);
	}
	
	getRoleDetails(user_id: any): void {
		this.businessMasterService.getBusniessMasterByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.detailedList = data.data;
				this.type = data.data.type;
				this.b_id = data.data.b_id;
				this.map_id = data.data.map_id;
				this.dynamicMapUrl = 'https://maps.google.com/maps?q='+data.data.latitude+', '+data.data.longitude+'&z=15&output=embed';
			}
		);
	}

	getDoctorByUserId(user_id: any): void {
		this.doctorService.getDoctorByUserId({ user_id } as Doctor)
		.subscribe(
			data => {
				this.doctorList = data.data;
			}
		);
	}

	addBusinessImages($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		
		let fileSource = this.addDoctorForm.value.fileSource;
		let b_id = this.b_id;

		this.businessImageService.uploadImage(fileSource, b_id).subscribe(
			data => {
				if (data.status == 200) {
					const successMessage = document.getElementById('btnSuccess') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_success_msg") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					successMessage?.click();
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			error => {
				this.errorMessage = error;
			}
		)
	}
	
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}