import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateBloodRequestComponent } from './create-blood-request.component';

import { AuthGuard } from '../auth.guard';
import { AuthAdminGuard } from '../authadmin.guard';

const routes: Routes = [
  {
    path: 'create-blood-request',
    canActivate: [AuthGuard, AuthAdminGuard],
    component: CreateBloodRequestComponent,
    data: { 
      showHeader: false,
      showSidebar: false,
      showFooter: false,

      showBusinessHeader: true,
      showBusinessSidebar: true,
      showBusinessFooter: true,
     }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateBloodRequestRoutingModule { }
