<section class="km__blog__details ptb-120 pt-30">
    <div class="container">
        <div class="blog__img mb-30">
            <img src="{{articleDetails.featured_image}}" alt="images not founds" class="img-fluid">
        </div>
        <ul class="fc-list">
            <li>
                <i class="fa-regular fa-clock"></i>
                <span>{{articleDetails.created | date}}</span>
            </li>
            <li>
                <i class="fa-solid fa-user"></i>
                <span>Admin</span>
            </li>
        </ul>
        <div class="km__blog_content">
            <h1 class="mb-30">{{articleDetails.title}}</h1>
            <div class="article-description" [innerHTML]="articleDetails.description"></div>
        </div>
    </div>
</section>