<div class="breadcrumb_section overflow-hidden ptb-150">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12 text-center">
                <h2>Contact Us</h2>
            </div>
        </div>
    </div>
</div>
<section class="km__message__box ptb-120">
    <div class="container">
        <div class="km__contact__form">
            <div class="row g-5">
                <div class="col-xl-7">
                    <div class="km__box__form">
                        <h4>Get In Touch</h4>
                        <form [formGroup]="contactForm" (ngSubmit)="addContact($event)" class="km__main__form contact-responsive">
                            <div *ngIf="errorMessage">
                                <div class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div *ngIf="successMessage">
                                <div class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <input type="text" placeholder="Frist Name" onkeydown="return /[a-zA-Z ]/i.test(event.key)" formControlName="first_name" maxlength="30">
                                </div>
                                <div class="col-sm">
                                    <input type="text" placeholder="Last Name" onkeydown="return /[a-zA-Z ]/i.test(event.key)" formControlName="last_name" maxlength="30">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <input type="email" placeholder="Email" formControlName="email">
                                </div>
                                <div class="col">
                                    <input type="text" placeholder="Subject" onkeydown="return /[a-zA-Z0-9 ]/i.test(event.key)"  formControlName="subject" maxlength="60">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <textarea maxlength="300" placeholder="Message" rows="3" formControlName="message"></textarea>
                                </div>
                            </div>
                            <button type="submit" class="contact__btn">Submit Request</button>
                        </form>
                    </div>
                </div>
                <div class="col-xl-5">
                    <div class="km__form__content">
                        <h4 class="km__form__title">Expanded Blood Donate Services Here</h4>
                        <p class="form_des">Have questions or need help? Get in touch, and our team will assist you promptly!</p>
                        <ul class="km__address">
                            <li>
                                <i class="fa fa-envelope"></i>
                                <span>contact&#64;donorzilla.com</span>
                            </li>
                            <li>
                                <i class="fa fa-map-marker"></i>
                                <span>Location: H-143, Suite - 101, H Block, <br>  Sector 63, Noida 201301</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
